import React, {useState} from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Cheval = ({data}) => {
    const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    return (
        <div>
       
            <br />
            <hr />
            <br />
            {/*<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ color: 'text.secondary' }}>Consanguinité</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography style={{color: 'black'}}>
        La consanguinité est le résultat de l'accouplement de reproducteurs apparentés. <br />
    
        <span style={{color:'red'}}>NB:</span> Il se peut que la consanguinité ne soit pas encore calculée.<br />
        Le chevale {data.nom} est de race {data.race} et a comme consanguinité :<br />
          Anglais: {data.poabgl}{data.poabgl != 'Null' && '%'} <br />
          Arabe: {data.poarab}{data.poarab != 'Null' && '%'} <br />
          Barbe: {data.pobarb}{data.pobarb != 'Null' && '%'} <br />
          </Typography>
        </AccordionDetails>
    </Accordion>*/}
      {/*<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ color: 'text.secondary' }}>
            Ancetres Majeurs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Race</th>
                    <th>Sex</th>
                    <th>Date Naissance</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                </tr>
                <tr>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                </tr>
                <tr>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                    <td>jj</td>
                </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          
          <Typography sx={{ color: 'text.secondary' }}>
            Composition raciale
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{color: 'black'}}>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
       */}
       <h1>En cours de construction</h1>
        </div>
    );
}

export default Cheval;
