import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {  Link } from 'react-router-dom';




const Production = ({ id, sex, dataFull}) => {
    const [annee, setAnnee]=useState('')
    const [rows, setRows]=useState([]);
    const [data , setData] = useState([])
    const [bil, setBil] = useState([]);
    const [career, setCareer]= useState([]);
    const today = new Date();
    const [status, setStatus]= useState('r')
    const [sexx, setSex]= useState(sex)
    const [num, setNum]=useState(15);
    const [loading , setLoading]=useState(true)
    useEffect(()=>{
        axios.get(`https://api.fnarc.tn/saillie/search/${id}`).then(res=>{
            setData([...res.data.reproduction])
            setRows([...res.data.reproduction])
            rows?.[0]?.[4] === id && setSex('Mâle') 
            rows?.[0]?.[2] === id && setSex('Femelle') 
            setBil([...res.data.bilan])
            setCareer({...res.data.total})
            setLoading(false)
        })
        sexx === 'Mâle' ? setNum(15) : setNum(16)
        window.scrollTo({
            top:350,
            behavior : 'smooth' ,
        })
    },[])
    useEffect(() => {
        sexx === 'Mâle' ? setNum(15) : setNum(16)
    }, [sexx]);
    useEffect(()=>{
        setLoading(true)
        axios.get(`https://api.fnarc.tn/saillie/search/${id}`).then(res=>{
            setRows([...res.data.reproduction])
            setData([...res.data.reproduction])
            rows?.[0]?.[4] === id && setSex('Mâle') 
            rows?.[0]?.[2] === id && setSex('Femelle') 
            setBil([...res.data.bilan])
            setCareer({...res.data.total})
            setLoading(false)
        })
        sexx === 'Mâle' ? setNum(15) : setNum(16)
    },[id])
    const searchAnneeHandler = async (e)=>{
        e.preventDefault();
        setLoading(true)
        if(annee==''){
            setRows([...data])
            rows?.[0]?.[4] === id && setSex('Mâle') 
            rows?.[0]?.[2] === id && setSex('Femelle') 
            setLoading(false)
            
        }
        else{
           const rowsToSHow= data.filter(Element=> Element[1] == annee || Element[num]?.[4].toLowerCase()?.includes( annee.toLowerCase()) || Element[14]?.[4].toLowerCase()?.includes(annee.toLowerCase()))
           setRows([...rowsToSHow]) 
           setLoading(false)
        }
}
    const searchHandler = async(e)=>{
        e.preventDefault();
        setAnnee(e.target.value)
    }
    return(<>
        {(rows?.length > 0) && !loading && 
        <>
            <br />
            <br />
            <br />
            <p>Nombre de saillies trouvées: {rows.length}</p>
            <br />
            <br />
            <br />
            <form onSubmit={searchAnneeHandler}>
                <input type="text" placeholder={`Recherche par Année, nom ${sex === 'Mâle' ? 'Jument' : 'Etalon'} ou produit`} style={{color:'black', fontSize:'2rem'}} onChange={searchHandler} />
            </form>
            <br />
            <br />
            <br />
            <p style={{textAlign:'left'}}>Données à la date du : {`${today.getDate() - 1 }/ ${today.getMonth() + 1} /${today.getFullYear()}`}</p>
            <br />
            <br />
            <br />
        {sexx=== "Mâle" && sexx!=='Femelle' && (status==='r') && <div>
        <div style={{display:'flex', width:'100vw', justifyContent:'center', gap:'1rem'}}>
            <button className='button-34' onClick={()=>{setStatus('c')}}>Carnet</button>
            <button className='button-34 active' onClick={()=>{setStatus('r')}}>Reproduction</button>
            <button className='button-34' onClick={()=>{setStatus('b')}}>Bilan</button>
        </div>
        <p>Nombre de saillies trouvées: {rows.length}</p>
         <div >

         {rows.map(e=>{
            return(
                <>
                <div key={e[3]} style={{textAlign:'start'}}>
                    <p><Link to={`/cheveaux/${e[num][3]}`}>{e[num][4]}</Link>, {e[num][14] !== 'TN' && `(${e[num][14]}),`} {e[num][9]}, {e[num][11]}, {e[num][8] !== 'Null' ? e[num]?.[8]?.split('T')[0].split('-').reverse().join('/') : e[num][10]} <br /></p>
                <p>par: <Link to={`/cheveaux/${e[num][0]}`}>{e[num][26]?.[4]}</Link>{e[num][26]?.[14] !== 'TN' && `(${e[num][26]?.[14]})`}, {e[num]?.[26]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"} et <Link to={`/cheveaux/${e[num][2]}`}>{e[num][27]?.[4]}</Link>{e[num][27]?.[14] !== 'TN' && `(${e[num][27]?.[14]})`} ,{e[num]?.[27]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"} par <Link to ={`/${e[num]?.[27]?.[0]}`}>{e[num]?.[27]?.[26]?.[4]} </Link>{e[num][27]?.[26][14] !== 'TN' && `(${e[num][27]?.[26][14]})`}, {e[num]?.[27]?.[26]?.[9]}  </p>
                <p><span style={{fontWeight:'bold'}}>saillie : {`${e[7] === 'Null' ? 'le' : 'du'}`} </span> {`${e[3] !== 'Null' ? e[3].split('T')[0].split('-').reverse().join('/') : e[1]}`} {`${e[7] === 'Null' ? '' : 'au '}`} {e[7] !== 'Null' && e[7].split('T')[0].split('-').reverse().join('/')}, monte :{e[6]} <br />
                   
                    <span style={{fontWeight:'bold'}}>Résultat : </span>{e[12]}<br />
                    { e[14] && <>Poulain: <Link to={`/cheveaux/${e[14][3]}`}>{e[14][4]}</Link> {e[num][14] !== 'TN' && `(${e[num][14]})`},  {e[num][9]}{( e[num][7] !== 100 )&& `(${e[num][7]}%)`}, {e[num][6]}, {e[14]?.[11]}, né(e) le {e[14]?.[8] !== 'Null' ? e[14]?.[8].split('T')[0].split('-').reverse().join('/') : e[14]?.[10]} <br /> 
                    par : {dataFull.cheval?.nom}{dataFull?.cheval?.pays !== "TN" ? `(${dataFull?.cheval?.pays})` : ""}, {dataFull.cheval?.race}, <br />
                    Naisseur : {e[14] && `${e[14]?.[31].naisseur[0]?.[4] !== 'Null' ? e[14]?.[31].naisseur[0]?.[4] :''} ${e[14][31]?.naisseur[0]?.[2]} ${e[14][31]?.naisseur[0]?.[3] === "Null" ? "" : e[14][31]?.naisseur[0]?.[3] }` || ''}
                     </>}
                    </p>
                </div>
                <hr />
                </>
            )
        })}
        
         </div>
        </div>}
        {sexx=== "Mâle" &&sexx!=='Femelle' &&(status==='b') && <div>
        <div style={{display:'flex', width:'100vw', justifyContent:'center', gap:'1rem'}}>
            <button className='button-34' onClick={()=>{setStatus('c')}}>Carnet</button>
            <button className='button-34' onClick={()=>{setStatus('r')}}>Reproduction</button>
            <button className='button-34 active' onClick={()=>{setStatus('b')}}>Bilan</button>
        </div>
        <p>Bilan de Reproduction</p>
        <table style={{maxWidth:'max-content', marginLeft:'auto', marginRight:'auto'}}>
        <thead>
            <tr>
                <th>Année</th>
                <th>Saillies</th>
                <th>Avortées</th>
                <th>Mis Bas</th>
                <th>Vides</th>
                <th>S R</th>
            </tr>

        </thead>
        <tbody>

         {bil.map(e=>{
            return(
                <tr key={e.annee + Math.floor(Math.random() * 10000)}>
                    <td>{e.annee}</td>
                    <td>{e.total}</td>
                    <td>{e.AV}</td>
                    <td>{e.MB}</td>
                    <td>{e.VIDE}</td>
                    <td>{e.SR}</td>
                </tr>
            )
        })}
        </tbody>
        </table>
        </div>}
        {sexx=== "Mâle" &&sexx!=='Femelle' &&(status==='c') && <div>
        <div style={{display:'flex', width:'100vw', justifyContent:'center', gap:'1rem'}}>
            <button className='button-34 active' onClick={()=>{setStatus('c')}}>Carnet</button>
            <button className='button-34' onClick={()=>{setStatus('r')}}>Reproduction</button>
            <button className='button-34' onClick={()=>{setStatus('b')}}>Bilan</button>
        </div>
        <p>Carnet</p>
        <table style={{maxWidth:'max-content', marginLeft:'auto', marginRight:'auto'}}>
        <thead>
            <tr>
                <th>Année</th>
                <th>Nombre de cartes utilisées</th>
                <th>Lieu</th>
            </tr>

        </thead>
        <tbody>

         {dataFull?.cheval?.carnet?.map(e=>{
            return(
                <tr key={e[3]}>
                    <td>{e[2]}</td>
                    <td>{e[1]}</td>
                </tr>
            )
        })}
        </tbody>
        </table>
        </div>}
        {sexx=== "Femelle" && <div>
        <p>Nombre de saillies trouvées: {rows.length}</p>
        <div>

        {rows.map(e=>{
            return(
                <>
                {/*<div key={e[3]} style={{textAlign:'start'}}>
                    <p>saillie le : {`${e[3] !== 'Null' ? e[3].split('T')[0].split('-').reverse().join('/') : e[1]}`}, monte :{e[6]} <br />
                    </p>
                    <p><Link to={`/cheveaux/${e[num][3]}`}>{e[num][4]}</Link>, {e[num][14] !== 'TN' && `(${e[num][14]}),`} {e[num][9]}, {e[num][11]}, {e[num][8] !== 'Null' ? e[num]?.[8]?.split('T')[0].split('-').reverse().join('/') : e[num][10]} <br /></p>
                <p>par: <Link to={`/cheveaux/${e[num][0]}`}>{e[num][26]?.[4]}</Link> {e[num]?.[26]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"} et <Link to={`/cheveaux/${e[num][2]}`}>{e[num][27]?.[4]}</Link> {e[num]?.[27]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"}, par <Link to ={`/${e[num]?.[27]?.[0]}`}>{e[num]?.[27]?.[26]?.[4]} </Link>{e[num]?.[27]?.[26]?.[14] !== 'TN' && `(${e[num]?.[27]?.[26]?.[14]}),`} {e[num]?.[27]?.[26]?.[9]}   </p><br />
                    Résultat : {e[12]}<br />
                    { e[14] && <>Poulain: <Link to={`/cheveaux/${e[14][3]}`}>{e[14][4]}</Link>, {e[num][14] !== 'TN' && `(${e[num][14]}),`}  {e[num][9]}{( e[num][7] !== 100 )&& `(${e[num][7]}%)`}, {e[num][6]}, {e[14]?.[11]}, {e[14]?.[8] !== 'Null' ? e[14]?.[8].split('T')[0].split('-').reverse().join('/') : e[14]?.[10]} <br />
                    par : {e[num][4]}, {e[num][9]}, <br />
                    Naisseur : {e[14] && `${e[14]?.[31].naisseur[0]?.[4] !== 'Null' ? e[14]?.[31].naisseur[0]?.[4] :''} ${e[14][31]?.naisseur[0]?.[2]} ${e[14][31]?.naisseur[0]?.[3] === "Null" ? "" : e[14][31]?.naisseur[0]?.[3] }` || ''}
                     </>}
            </div>*/}
            <div key={e[3]} style={{textAlign:'start'}}>
                    <p><Link to={`/cheveaux/${e[num][3]}`}>{e[num][4]}</Link>, {e[num][14] !== 'TN' && `(${e[num][14]}),`} {e[num][9]}, {e[num][11]}, {e[num][8] !== 'Null' ? e[num]?.[8]?.split('T')[0].split('-').reverse().join('/') : e[num][10]} <br /></p>
                <p>par: <Link to={`/cheveaux/${e[num][0]}`}>{e[num][26]?.[4]}</Link>{e[num][26]?.[14] !== 'TN' && `(${e[num][26]?.[14]})`}, {e[num]?.[26]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"} et <Link to={`/cheveaux/${e[num][2]}`}>{e[num][27]?.[4]}</Link>{e[num][27]?.[14] !== 'TN' && `(${e[num][27]?.[14]})`} ,{e[num]?.[27]?.[9]} {e[num]?.[7] !== "Null" ? e[num]?.[7] !== 100 && `(${e[num][7]}%)` :  "Null"} par <Link to ={`/${e[num]?.[27]?.[0]}`}>{e[num]?.[27]?.[26]?.[4]} </Link>{e[num][27]?.[26][14] !== 'TN' && `(${e[num][27]?.[26][14]})`}, {e[num]?.[27]?.[26]?.[9]}  </p>
                <p><span style={{fontWeight:'bold'}}>saillie : {`${e[7] === 'Null' ? 'le' : 'du'}`} </span> {`${e[3] !== 'Null' ? e[3].split('T')[0].split('-').reverse().join('/') : e[1]}`} {`${e[7] === 'Null' ? '' : 'au '}`} {e[7] !== 'Null' && e[7].split('T')[0].split('-').reverse().join('/')}, monte :{e[6]} <br />
                   
                    <span style={{fontWeight:'bold'}}>Résultat : </span>{e[12]}<br />
                    { e[14] && <>Poulain: <Link to={`/cheveaux/${e[14][3]}`}>{e[14][4]}</Link> {e[num][14] !== 'TN' && `(${e[num][14]})`},  {e[num][9]}{( e[num][7] !== 100 )&& `(${e[num][7]}%)`}, {e[num][6]}, {e[14]?.[11]}, né(e) le {e[14]?.[8] !== 'Null' ? e[14]?.[8].split('T')[0].split('-').reverse().join('/') : e[14]?.[10]} <br /> 
                    par : {dataFull.cheval?.nom}{dataFull?.cheval?.pays !== "TN" ? `(${dataFull?.cheval?.pays})` : ""}, {dataFull.cheval?.race}, <br />
                    Naisseur : {e[14] && `${e[14]?.[31].naisseur[0]?.[4] !== 'Null' ? e[14]?.[31].naisseur[0]?.[4] :''} ${e[14][31]?.naisseur[0]?.[2]} ${e[14][31]?.naisseur[0]?.[3] === "Null" ? "" : e[14][31]?.naisseur[0]?.[3] }` || ''}
                     </>}
                    </p>
                </div>
                <hr />
                </>
            )
        })}
        </div> 
        </div>}
        </> }
        {rows?.length === 0 && !loading && <> 
            <form onSubmit={searchAnneeHandler}>
                <input type="text" placeholder='Recherche par Année' style={{color:'black'}} onChange={searchHandler} />
            </form>
            <br />
            <br />
            <br />
        <p style={{color:'red'}}>Il n'ya pas de Reproduction</p>
        </>}
        {
            loading && <p>loading..........</p>
        }
    </>)
}

export default Production;
