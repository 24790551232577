import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';

const Contact = ({language, setLanguage}) => {
    return (
      <>
      <Navbar language={language} setLanguage={setLanguage} active='contact'/>
      <section>
          
      <div className="row">
      <div className="col-md-9 col-sm-12">
      <h3>Contact</h3>
      <br />
      <br />
      <br />
        <div className="row">
          <form name="form2" id="form2" style={{fontSize:"1.5rem"}}>
            <table className="tableau_formulaire" width="90%">
              <tbody><tr className="form_name">
                  <td style={{fontWeight:"bold"}}>Name :</td>
                  <td><input type="text" name="name" id="name" style={{width: '100%', color:'black'}}  /></td>
                </tr>
                <tr>
                  <td style={{fontWeight:"bold"}}>Nom :</td>
                  <td><input type="text" name="nom" id="nom" style={{width: '100%', color:'black'}}  required /></td>
                </tr>
                <tr>
                  <td style={{fontWeight:"bold"}}>Prénom :</td>
                  <td><input type="text" name="prenom" id="prenom" style={{width: '100%', color:'black'}}  required /></td>
                </tr>
                <tr>
                  <td style={{fontWeight:"bold"}}>Téléphone :</td>
                  <td><input type="tel" name="telephone" id="telephone" style={{width: '100%', color:'black'}}  required /></td>
                </tr>
                <tr>
                  <td style={{fontWeight:"bold"}}>E-mail :</td>
                  <td><input type="email" name="email" id="email" style={{width: '100%', color:'black'}}  required /></td>
                </tr>
                <tr>
                  <td style={{fontWeight:"bold"}} colSpan={2}>Votre message :<br />
                    <textarea name="message" id="message" rows={8} style={{width: '100%', color:'black'}} required  /></td>
                </tr>
                <tr>
                  <td colSpan={2}><div id="form-buttons">
                      <button type="submit" className="btn oe-btn oe-btn-reflect-icon" style={{fontSize:"1.5rem"}}>Envoyer</button>
                      <input type="hidden" name="moment" defaultValue={1680253158} />
                    </div>
                    <div id="form-sending" className="alert alert-success" style={{display: 'none'}}>Envoi en cours...</div></td>
                </tr>
              </tbody></table>
          </form>
          <div className="" style={{fontSize:'1.2rem'}}>Tous les champs sont obligatoires</div>
          <iframe src="https://www.google.com/maps/d/embed?mid=1f6cDmNn1xKAQ_JAHOfn89ugIKfdy_38&ehbc=2E312F" width="640" height="480"></iframe>
        </div>
      </div>
    <SideBar language={language} />
    </div>
      </section>
      <Footer language={language} />
      </>
    );
}

export default Contact;
