import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import { Carousel } from "react-carousel-minimal";
import { useEffect, useState } from "react";
const Gallery = ({ language, setLanguage }) => {
  const [itemData, setItemData] = useState([
    {
      image: "../images/IMG_1664.JPG",
      caption: "image",
    },
  ]);
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  const fireCall = async () => {
    await getDocs(collection(db, "Mediatheque")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => {
        return {
          image:
            doc.data().img ||
            "../images/transfert photos canon mai 2017 544.JPG",
          caption:
            language === "fr"
              ? doc.data().titreFr
              : doc.data().titreAr || "test",
          id: doc.id,
        };
      });
      setItemData(newData);
    });
  };
  useEffect(() => {
    fireCall();
  }, []);
  return (
    <>
      <Navbar active="gallery" language={language} setLanguage={setLanguage} />
      <div className="row">
        <div className="col-md-9 col-sm-12">
          <Carousel
            data={itemData}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
        <SideBar language={language} />
      </div>
      <Footer language={language} />
    </>
  );
};

export default Gallery;

/*<ImageList sx={{ width:'100%', height:()=>`${itemData.length * 85}px`, padding:'2rem'}} cols={3} rowHeight={110}>
            {itemData.map((item) => (
                <ImageListItem key={item.img} style={{overflow:'hidden', height:'100%'}}>
                <img
                    src={`${item.img}`}
                    alt={item.title}
                    loading="lazy"
                    style={{
                        width:'40rem',
                        height:'40rem'
                    }}
                />
                </ImageListItem>
            ))}
    </ImageList>*/
