import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EspaceMembreCard from './EspaceMembreCard';

const SideBar = ({language}) => {
  const [side , setSide]=useState(
    <>
      <div className="sidebar">
                <EspaceMembreCard language={language}/>
                
                <div className="liasse-titre margin-top" style={{fontSize:'2rem', textAlign:"center"}}>ESPACE ÉLEVEURS</div>
                <div className="liasse-corps" style={{textAlign:'center', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                  <img src="../images/32318401_7922058.jpg" style={{borderRadius:'50%', height:'15rem', width:'15rem'}} className="droite" />
                  <p>
                    <br />
                    <span className="grand center" style={{fontSize:'3rem', fontWeight:'bold'}} >ESPACE ÉLEVEURS</span>
                  </p>
                    <span style={{fontSize:'2rem'}}>Services En Ligne</span>
                    <span style={{fontSize:'1.5rem'}}>Projet en cours</span>
                  <br />
                  <p className="liasse-btn">
                    <Link to="/login">
                      Accès à l'espace Éleveurs
                    </Link>
                  </p>
                </div>
                <div className="partenaires margin-top">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/L5wsy3RFdMk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h2 className="head_title orange margin-top" style={{fontSize:'2rem', textAlign:"center"}}>
                Agenda
                </h2>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href="#"
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                      <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Janvier:</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                     réunions d'approbation des étalons pour la monte publique
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Février</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                        : Démarrage de la saison de monte
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Avril :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Concours d'élevage - Race Pur Sang Arabe
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Mai :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Concours d'élevage - Race Barbe et Arabe Barbe
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Juin :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Vente de yearlings de races pur sang Arabe et pur sang Anglais
                      </div>
                    </div>
                  </a>{" "}
                </div>
                
              </div>
    </>
  )
  useEffect(()=>{
    if(language=== 'fr'){
      setSide(
        <>
          <div className="sidebar">
                <EspaceMembreCard language={language}/>
                
                <div className="liasse-titre margin-top" style={{fontSize:'2rem', textAlign:"center"}}>ESPACE ÉLEVEURS</div>
                <div className="liasse-corps" style={{textAlign:'center', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                  <img src="../images/32318401_7922058.jpg" style={{borderRadius:'50%', height:'15rem', width:'15rem'}} className="droite" />
                  <p>
                    <br />
                    <span className="grand center" style={{fontSize:'3rem', fontWeight:'bold'}} >ESPACE ÉLEVEURS</span>
                  </p>
                    <span style={{fontSize:'2rem'}}>Services En Ligne</span>
                    <span style={{fontSize:'1.5rem'}}>Projet en cours</span>
                  <br />
                  <p className="liasse-btn">
                    <Link to="/login">
                      Accès à l'espace Éleveurs
                    </Link>
                  </p>
                </div>
                <div className="partenaires margin-top">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/L5wsy3RFdMk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h2 className="head_title orange margin-top" style={{fontSize:'2rem', textAlign:"center"}}>
                Agenda
                </h2>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href="#"
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                      <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Janvier:</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                     réunions d'approbation des étalons pour la monte publique
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Février</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                        : Démarrage de la saison de monte
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Avril :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Concours d'élevage - Race Pur Sang Arabe
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Mai :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Concours d'élevage - Race Barbe et Arabe Barbe
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid">
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold'}}>Juin :</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title">
                      Vente de yearlings de races pur sang Arabe et pur sang Anglais
                      </div>
                    </div>
                  </a>{" "}
                </div>
                
              </div>
        </>
      )
    }else{
      //arabic
      setSide(
        <>
          <div className="sidebar">
                <EspaceMembreCard language={language}/>
                
                <div className="liasse-titre margin-top" style={{fontSize:'2rem', textAlign:"center"}}>فضاء المربي</div>
                <div className="liasse-corps" style={{textAlign:'center', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                  <img src="../images/32318401_7922058.jpg" style={{borderRadius:'50%', height:'15rem', width:'15rem'}} className="droite" />
                  <p>
                    <br />
                    <span className="grand center" style={{fontSize:'3rem', fontWeight:'bold'}} >فضاء المربي</span>
                  </p>
                    <span style={{fontSize:'2rem'}}>خدمات عبر الانترنت</span>
                    <span style={{fontSize:'1.5rem'}}>المشروع قيد التطوير</span>
                  <br />
                  <p className="liasse-btn">
                    <Link to="/login">
                    الولوج إلى فضاء المربي
                    </Link>
                  </p>
                </div>
                <div className="partenaires margin-top">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/L5wsy3RFdMk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h2 className="head_title orange margin-top" style={{fontSize:'2rem', textAlign:'right'}}>
                مذكرة
                </h2>
                <div className="col-sid" >
                  {" "}
                  <a
                    className="link-brd"
                    href="#"
                    style={{display:'flex' , flexDirection:'row-reverse' , gap:'10px'}}
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                      <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold', textAlign:'right'}}>جانفي</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title" style={{textAlign:'right'}}>
                      اجتماعات اللجنة الوطنية للمصادقة على الفحول
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid" >
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                    style={{display:'flex' , flexDirection:'row-reverse' , gap:'10px'}}
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center', gap:"5px"}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold', textAlign:'right'}}>فيفري</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title" style={{textAlign:'right'}}>
                       انطلاق موسم التجويد 
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid" >
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                    style={{display:'flex' , flexDirection:'row-reverse' , gap:'10px'}}
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold', textAlign:'right'}}>أفريل</span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title" style={{textAlign:'right'}}>
                       تنظيم مناظرات تربية وإنتاج الخيول العربية الأصيلة 
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid" >
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                    style={{display:'flex' , flexDirection:'row-reverse' , gap:'10px'}}
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold', textAlign:'right'}}>ماي </span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title" style={{textAlign:'right'}}>
                       تنظيم مناظرات تربية وإنتاج الخيول البربرية والعربية بربرية 
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="col-sid" >
                  {" "}
                  <a
                    className="link-brd"
                    href=""
                    style={{display:'flex' , flexDirection:'row-reverse' , gap:'10px'}}
                  >
                    <div className="col-md-3 col-sm-12 nopadding">
                    <div className="image" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <span style={{fontSize:'1.4rem', fontWeight:'bold', textAlign:'right'}}>جوان  </span>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 nopadding">
                      <div className="title" style={{textAlign:'right'}}>
                       تنظيم سوق بيع الأمهار من السلالات الأصيلة 
                      </div>
                    </div>
                  </a>{" "}
                </div>
                
              </div>
        </>
      )
    }

  }, [language])
    return (
        <div className="col-md-3 col-sm-12 col-xs-12">
              {side}
            </div>
    );
}

export default SideBar;
