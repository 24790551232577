import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import "video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';
import SideBar from '../components/SideBar';


const New = ({actualit , language, setLanguage}) => {
    const {id} = useParams()
    const [news , setNews] = useState() ;
    const [data , setData] = useState([
      {
        id:'11',
        img: '../images/transfert.png',
        title: 'Actualité',
        descFr:"transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
        descAr:'الفريق البيطري بالمؤسسة الوطنية لتحسين و تجويد الخيل  ينجز بنجاح أول عملية نقل أجنة عند الخيول بتونس'  
      },
      {
        id:'1',
        img: '../images/emb.jpg',
        title: 'Actualité',
        descFr:"Collecte et transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
        descAr:'جمع ونقل أجنة الخيول: الأول من نوعه في مزرعة الخيول الوطنية بسيدي ثابت'
      },
      {
        id:'2',
        img: '../images/semence.jpg',
        title: 'Actualité',
        descFr:"Liste des étalons disponibles en semence congelée",
        descAr:'قائمة الفحول المتوفرة في السائل المنوي المجمد'
      },
      {
        id:'3',
        img: '../images/Image_7.jpeg',
        title: 'Actualité',
        descFr:"Réception du centre des biothechnlogies de la reproduction chez les équidés et démarrage de son activité",
        descAr:'انطلاق نشاط مركز تقنيات التناسل الحديث عند الخيول بسيدي ثابت'
      },
      ]) ;
    const apiCall= async ()=>{
        await axios.get('http://localhost:2000/news').then((res)=>{
             setNews(res.data.find(e=> e.id === id))
        })
        }
        useEffect(() => {
             //apiCall()
             setNews(actualit.find(e=> e.id == id))
           }, []);
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='actualite'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <Link to='/news' style={{width:"10rem"}}><h1 className="sectionTitle" style={{textAlign:`${language === 'fr' ? 'left' : 'right' }`, width:"0rem", border:"0px solid black", textDecoration:"none"}}>{language ===  'fr' ? "Actualités" : "المستجدات"}</h1></Link>
         {news?.img?.includes('.mp4')  ? <Player
            playsInline
            poster={`${news?.img}`}
            src={`${news?.img}`}
            muted='true'
            autoPlay='true'
            /> :
            <img style={{width:'80vw', aspectRatio:'16/9'}} src={`${news?.img}`} alt={news?.descriptionFr} />
            }
            <br />
            <br />
            <br />
            <div style={{backgroundColor:'#eee', padding:'2rem', borderRadius:'2rem'}}>
                <h1 style={{backgroundColor:'#004B99', color:'white', padding:'8px', width:'100%', margin:'0', borderRadius:'1rem'}}>{language ==='fr' ? news?.titreFr: news?.titreAr}</h1>
                <br />
                <br />
                <br />
                <p style={{color:'#004B99 !important'}}>{language === 'fr' ? news?.descriptionFr : news?.descriptionAr }</p>
                {news?.img2 !== '' && <a href={news?.img2} target='_blank'> {language === 'fr' ? 'voir le fichier' : 'شاهد الملف'} </a>}
                {/*news?.Type === 'Event' && <p style={{textAlign:'left'}}>
                        <span style={{fontWeight:'bold'}}>Date :</span> {news?.CreateAt.split('T')[0].split('-').reverse().join('/')}
                    </p>*/}
            </div>
            </div>
            <SideBar language={language} />
            </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;