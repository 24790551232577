import * as React from 'react';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';

import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CakeIcon from '@mui/icons-material/Cake';
export default function HorizontalDividedList({setSection}) {
  return (
    <>
      <List
      row
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        flexGrow: 0,
        mx: 'auto',
        '--List-decorator-size': '48px',
        '--List-item-paddingY': '1rem',
      }}
      style={{backgroundColor:'#004b99', color:'white', display:"flex", flexWrap:"wrap"}}
    >
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <InfoIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'Inf',})}}>Information Générales </Link>
      </ListItem>
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <AccountTreeIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'pedigree',})}}>Pedigree </Link>
      </ListItem>
      <ListDivider inset="gutter" />
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <InfoIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'Reproduction',})}}>Reproduction </Link>
      </ListItem>
      <ListDivider inset="gutter" />
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <CakeIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'Production',})}}>Production </Link>
      </ListItem>
      {/*<ListDivider inset="gutter" />
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <InfoIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'Aptitude',})}}>Aptitude </Link>
      </ListItem>*/}
      <ListDivider inset="gutter" />
      <ListItem>
        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
          <BloodtypeIcon />
        </ListItemDecorator>
        <Link className='a' style={{ textDecoration: "none" , color:'white'}} onClick={()=>{setSection({name:'diversiteGen',})}}>Diversité Génitique </Link>
      </ListItem>
    </List>
    </>
  );
}