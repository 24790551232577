import React, {useState, useEffect} from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import SideBar from '../components/SideBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { getDocs, collection } from "firebase/firestore";
import {db} from '../firebase';
const New = ({language, setLanguage}) => {
  const [itemData, setItemData] = useState([])
  const fireCall = async ()=>{
    await getDocs(collection(db, "LienUtils"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                    setItemData(newData);                
            })
  }
  useEffect(()=>{
    fireCall()
    
  }, [])
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='liens'/>
        <section>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
        <ImageList sx={{ width:'100%' , padding:'2rem'}} cols={3} rowHeight={110}>
            {itemData.map((item) => (
                <ImageListItem key={item.img} style={{ height:'25rem', marginBottom:'4rem',width:'25rem',objectFit:'contain'}}>
                <a target='_blank' href={item.descriptionFr} style={{fontSize:'2rem', marginBottom:'1rem'}}>{item.titreFr}</a>
                <img
                    src={`${item.img}`}
                    alt={item.titreFr}
                    loading="lazy"
                    style={{
                        width:'40rem',
                        height:'40rem',
                        
                    }}
                    />
                </ImageListItem>
            ))}
    </ImageList>
        </div>
        </div>
        <SideBar language={language} />
        </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;


