import React , {useEffect, useState} from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '../components/NewsCard'
import axios from 'axios';
import SideBar from '../components/SideBar';
const News = ({actualit,language , setLanguage}) => {
    const [news , setNews] = useState([
      {
        id:'11',
        img: '../images/transfert.png',
        title: 'Actualité',
        descFr:"transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
        descAr:'الفريق البيطري بالمؤسسة الوطنية لتحسين و تجويد الخيل  ينجز بنجاح أول عملية نقل أجنة عند الخيول بتونس'  
      },
      {
        id:'1',
        img: '../images/emb.jpg',
        title: 'Actualité',
        descFr:"Collecte et transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
        descAr:'جمع ونقل أجنة الخيول: الأول من نوعه في مزرعة الخيول الوطنية بسيدي ثابت'
      },
      {
        id:'2',
        img: '../images/semence.jpg',
        title: 'Actualité',
        descFr:"Liste des étalons disponibles en semence congelée",
        descAr:'قائمة الفحول المتوفرة في السائل المنوي المجمد'
      },
      {
        id:'3',
        img: '../images/Image_7.jpeg',
        title: 'Actualité',
        descFr:"Réception du centre des biothechnlogies de la reproduction chez les équidés et démarrage de son activité",
        descAr:'انطلاق نشاط مركز تقنيات التناسل الحديث عند الخيول بسيدي ثابت'
      },
      ]) ;
    const apiCall= async ()=>{
        await axios.get('http://localhost:2000/news').then((res)=>{
             setNews(res.data)
        
        })
        }
        useEffect(() => {
             //apiCall()
           }, []);
        
    return (
        <>
           <Navbar language={language} setLanguage={setLanguage} active='actualite'/>
           <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
            <Container>
                <Row style={{display:'flex' , flexWrap:'wrap'}}>
                {actualit?.map(e=>{
                    return(
                            <Col key={e.id}>
                                <Card language={language} setLanguage={setLanguage} data={e} />
                            </Col>
                            )
                        })}
                </Row>
                
            </Container>
            </div>
            <SideBar language={language} />
            </div>
           </section>
           <Footer language={language} />
        </>
    );
}

export default News;
