/*import React from 'react';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FaxIcon from '@mui/icons-material/Fax';
const Fouter = () => {
    return (
        <div className='footer'>
            <div className="contactFooter">
                <h2>Contact</h2>
                <li><LocationOnIcon /> 2020 Sidi Thabet Tunis</li>
                <li><PhoneIcon /> 71552220 /71552110</li>
                <li><MailOutlineIcon /> dg-fnarc@topnet.tn</li>
                <li><FaxIcon /> Fax :71552391</li>
            </div>
            <div className="servicesFooter">
            <h2>Nos Services</h2>
                <ul>
                    <li><LabelImportantIcon /> Elevage Cheveaux</li>
                    <li><LabelImportantIcon /> Identification Cheveaux</li>
                    <li><LabelImportantIcon /> Organisation de la monte publique</li>
                    <li><LabelImportantIcon /> Amélioration Génitique</li>
                </ul>
            </div>
        </div>
    );
}

export default Fouter;
*/
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "../css/colorbox-colorbox.css";
import "../css/css-lush-mx.css";
import "../css/css-styles-15.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';


const Footer = ({language}) => {
    const d = new Date()
    const time = language === 'fr'? `/${d.getMonth() + 1}/${d.getFullYear()}` : `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate() - 1}`
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
  }
  const [foot , setFoot] = useState(
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="row">
              <div className="col-sid margin-bottom" style={{backgroundColor:'transparent', color:'white', marginTop:'2rem'}}>
                  <div style={{fontSize:'1.5rem'}}>
                    <i className="fa fa-calendar" /> Horaires d'ouverture
                  </div>
                  <div className="" style={{color:'white', fontSize:'1.3rem'}}>
                    <span>
                      Du lundi au Jeudi
                      <br />
                      De 08h à 12h 30 et De 13h 30 à 17h 30
                      <br />
                      Vendredi de 08h 00 à 14h 00
                    </span>
                      <br />
                    <span>
                      <strong>Horaires d’été</strong>
                      <br />
                      Du lundi au Jeudi
                      <br />
                      De 07h 30 à 14h 00
                      <br />
                      Vendredi de 07h 30 à 13h 00
                    </span>
                  </div>
                  
                </div>
                <div className="date-miseajour">
                  {" "}
                  Date de la dernière mise a jour : {time}
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="row">
                <div className="contact col-md-4 col-sm-12">
                  <h4>Contact</h4>
                  <ul>
                    <li>
                      2020 SIDI THABET TUNIS
                    </li>
                    <li>Tél&nbsp;&nbsp;: 71 552 220&nbsp;&nbsp;/&nbsp;&nbsp;71 552 110</li>
                    <li>Mail&nbsp;&nbsp;: DG-FNARC@TOPNET.TN</li>
                    <li>Fax : 71 552 391</li>
                  </ul>
                  <div className="reseaux-sociaux">
                    <a
                      href="https://www.facebook.com/profile.php?id=100064650881469"
                      target="_blank"
                    >
                      <i className="FacebookIcon" />
                    </a>{" "}
                    <a href="/#" target="_blank">
                      <i className="fa fa-twitter" />
                    </a>{" "}
                    <a
                      href="https://www.youtube.com/channel/UCJQW-d8YL9iSVrgM0r0Isng"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </div>
                </div>
                <div className="iframe col-md-8 col-sm-12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.9944802245755!2d10.034826315559538!3d36.91439636896229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2d2fff00dfb9f%3A0xe5129d781660dd5e!2sFNARC!5e0!3m2!1sfr!2stn!4v1672407083205!5m2!1sfr!2stn"
                    width={400}
                    height={250}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                    title="map"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
  useEffect(()=>{
    console.log(language);
    if(language === 'fr'){
      setFoot(<>
        <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="row">
              <div className="col-sid margin-bottom" style={{backgroundColor:'transparent', color:'white', marginTop:'2rem'}}>
                  <div style={{fontSize:'1.5rem'}}>
                    <i className="fa fa-calendar" /> Horaires d'ouverture
                  </div>
                  <div className="" style={{color:'white', fontSize:'1.3rem'}}>
                    <span>
                      Du lundi au Jeudi
                      <br />
                      De 08h à 12h 30 et De 13h 30 à 17h 30
                      <br />
                      Vendredi de 08h 00 à 14h 00
                    </span>
                      <br />
                    <span>
                      <strong>Horaires d’été</strong>
                      <br />
                      Du lundi au Jeudi
                      <br />
                      De 07h 30 à 14h 00
                      <br />
                      Vendredi de 07h 30 à 13h 00
                    </span>
                  </div>
                  
                </div>
                <div className="date-miseajour">
                  {" "}
                  Date de la dernière mise a jour : {time}
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="row">
                <div className="contact col-md-4 col-sm-12">
                  <h4>Contact</h4>
                  <ul>
                    <li>
                      2020 SIDI THABET TUNIS
                    </li>
                    <li>Tél&nbsp;&nbsp;: 71 552 220&nbsp;&nbsp;/&nbsp;&nbsp;71 552 110</li>
                    <li>Mail&nbsp;&nbsp;: DG-FNARC@TOPNET.TN</li>
                    <li>Fax : 71 552 391</li>
                  </ul>
                  <div className="reseaux-sociaux">
                    <a
                      href="https://www.facebook.com/profile.php?id=100064650881469"
                      target="_blank"
                    >
                      <FacebookIcon fontSize="large"/>
                    </a>{" "}
                    <a
                      href="https://www.youtube.com/channel/UCJQW-d8YL9iSVrgM0r0Isng"
                      target="_blank"
                    >
                      
                      <YouTubeIcon fontSize="large"/>
                    </a>
                  </div>
                </div>
                <div className="iframe col-md-8 col-sm-12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.9944802245755!2d10.034826315559538!3d36.91439636896229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2d2fff00dfb9f%3A0xe5129d781660dd5e!2sFNARC!5e0!3m2!1sfr!2stn!4v1672407083205!5m2!1sfr!2stn"
                    width={400}
                    height={250}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                    title="map"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
        
      </>)
    }else{
      //arabic
      setFoot(<>
        <footer>
        <div className="container">
          <div className="row">

            
            <div className="col-md-7 col-sm-12">
              <div className="row">
              <div className="iframe col-md-8 col-sm-12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.9944802245755!2d10.034826315559538!3d36.91439636896229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2d2fff00dfb9f%3A0xe5129d781660dd5e!2sFNARC!5e0!3m2!1sfr!2stn!4v1672407083205!5m2!1sfr!2stn"
                    width={400}
                    height={250}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                    title="map"
                  />
                </div>
                <div className="contact col-md-4 col-sm-12" style={{textAlign:'right'}} >
                  <h4> الإتصال بنا</h4>
                  <ul>
                    <li>
                      2020 سيدي ثابت تونس
                    </li>
                    <li>هاتف&nbsp;&nbsp;:220 552 71  &nbsp;&nbsp;/&nbsp;&nbsp;110 552 71</li>
                    <li>   DG-FNARC@TOPNET.TN&nbsp;&nbsp;: بريد إلكتروني</li>
                    <li>فاكس :   391 552 71</li>
                  </ul>
                  <div className="reseaux-sociaux">
                    <a
                      href="https://www.facebook.com/profile.php?id=100064650881469"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>{" "}
                    <a href="/#" target="_blank">
                      <i className="fa fa-twitter" />
                    </a>{" "}
                    <a
                      href="https://www.youtube.com/channel/UCJQW-d8YL9iSVrgM0r0Isng"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </div>
                </div>
                
              </div>
            </div>
          <div className="col-md-5 col-sm-12">
              <div className="row">
              <div className="col-sid margin-bottom" style={{backgroundColor:'transparent', color:'white', marginTop:'2rem', textAlign:'right'}}>
                  <div style={{fontSize:'1.5rem'}}>
                    <i className="fa fa-calendar" /> أوقات العمل
                  </div>
                  <div className="" style={{color:'white', fontSize:'1.3rem'}}>
                    <span>
                    من الاثنين إلى الخميس
                      <br />
                      من الساعة 8 صباحًا حتى الساعة 12:30 ظهرًا ومن 1:30 مساءً حتى 5:30 مساءً
                      <br />
                      الجمعة من 08:00 إلى 14:00
                    </span>
                      <br />
                    <span>
                      <strong>أوقات الصيف</strong>
                      <br />
                      من الاثنين إلى الخميس
                      <br />
                      من الساعة 07:30 صباحاً حتى 2:00 ظهراً.
                      <br />
                      الجمعة من الساعة 07:30 صباحاً حتى 1:00 مساءً
                    </span>
                  </div>
                  
                </div>
                <div className="date-miseajour" style={{textAlign:'right'}}>
                  {" "}
                  تاريخ آخر تحديث: {time}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </>)
    }
  },[language])
  return (
    <div>
      {foot}
      <div className="footer-bottom">
        <div className="container text-center"><strong>Site by <a href="http://idhafa.net/" target="_blank" rel="nofollow">Al Idhafa</a></strong> </div>
      </div>
    </div>
  );
};

export default Footer;
