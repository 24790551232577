import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Login from "./Login";
import UserProfile from "./UserProfile"

const Membre = ({currentUser,setCurrentUser ,language, setLanguage}) => {
    return (
        <div>
            <Navbar language={language} setLanguage={setLanguage} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
            <section style={{display:'grid', placeItems:"center"}} >
                {currentUser ? <UserProfile user={currentUser} /> : <Login setCurrentUser={setCurrentUser}/>}
            </section>
            <Footer language={language} />
        </div>
    );
}

export default Membre;
