import React, {useState} from 'react';
import Info from '../components/Info';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';




const Aptitude = ({toTop , toTopHandler}) => {
    
    return (
        <div>
        
            <br />
            <br />
            <br />
            <Info name={'Sport Cheveaux'} details={"En saut d'obstacles (CSO), concours complet (CCE) et dressage, les indices sont calculés de façon similaire et permettent de comparer un cheval par rapport à une population définie"}/>
            <br />
            <br />
            <br />
            <div className="tableContainer">

            <table>
                <thead>
                    <tr>
                        <th>Discipline</th>
                        <th>Discipline</th>
                        <th>Discipline</th>
                        <th>Discipline</th>
                        <th>Discipline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>true</td>
                        <td>true</td>
                        <td>true</td>
                        <td>true</td>
                        <td>true</td>
                    </tr>
                </tbody>
            </table>
            </div>
        
        </div>
    );
}

export default Aptitude;
