import React, {useState, useEffect} from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import tome1 from "../pdf/circulaire25-2012 ar.pdf";
import tome2 from "../pdf/Circulaire-num-19-annee-2018.pdf";
import tome3 from "../pdf/dcret-loi2011_54 ar.pdf";
import tome4 from "../pdf/Decret-loi 2011-41 du 26 mai 2011.pdf";
import tome5 from "../pdf/demande-acces.pdf";
import tome6 from "../pdf/demande-recours.pdf";
import tome7 from "../pdf/loi-22-2016.pdf";
import tome8 from "../pdf/OPEN DATA.pdf";
import tome9 from "../pdf/التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2019.pdf";
import tome10 from "../pdf/التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2020.pdf";
import tome11 from "../pdf/المكلف بالنفاذ إلى المعلومة ونائبه.pdf";
import tome12 from "../pdf/دليل إجراءات.pdf";
import SideBar from '../components/SideBar';
const New = ({language, setLanguage}) => {
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const [docs , setDocs] = useState([
        {
            link:tome1,
            nom:'circulaire25-2012 ar',
            id:getRandomInt(10000000000),
            number:'01'
        },
        {
            link:tome2,
            nom:'Circulaire-num-19-annee-2018',
            id:getRandomInt(10000000000),
            number:'02'
        },
        {
            link:tome3,
            nom:'dcret-loi2011_54 ar',
            id:getRandomInt(10000000000),
            number:'03'
        },
        {
            link:tome4,
            nom:'Decret-loi 2011-41 du 26 mai 2011',
            id:getRandomInt(10000000000),
            number:'04'
        },
        {
            link:tome5,
            nom:'demande-acces',
            id:getRandomInt(10000000000),
            number:'05'
        },
        {
            link:tome6,
            nom:'demande-recours',
            id:getRandomInt(10000000000),
            number:'06'
        },
        {
            link:tome7,
            nom:'loi-22-2016-ar',
            id:getRandomInt(10000000000),
            number:'07'
        },
        {
            link:tome8,
            nom:'OPEN DATA',
            id:getRandomInt(10000000000),
            number:'08'
        },{
            link:tome9,
            nom:'التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2019',
            id:getRandomInt(10000000000),
            number:'09'
        },
        {
            link:tome10,
            nom:'التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2020',
            id:getRandomInt(10000000000),
            number:'10'
        },
        {
            link:tome11,
            nom:'المكلف بالنفاذ إلى المعلومة ونائبه',
            id:getRandomInt(10000000000),
            number:'11'
        },
        {
            link:tome12,
            nom:'دليل إجراءات',
            id:getRandomInt(10000000000),
            number:'12'
        },
    ])
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='open'/>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
            <br />
            <br />
            <br />  
            <br />  
            <br />  
            <h3 style={{textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>{language === 'fr' ?'Politique d’accès à l’information ' : 'سياسة النفاذ إلى المعلومة'}</h3>
            {
               language === 'fr' && <p>Conformément aux dispositions de<a href={tome7} target='_blank'> la loi organique n° 22 2016 du 24 Mars 2016 relative au droit d'accès à l'information </a>, la FNARC  met à la disposition du public les informations et les documents précisés par la loi.</p>}
            {language !== 'fr' && <p style={{textAlign:"right"}}>تطبيقا لمقتضيات <a href={tome7} target='_blank'>القانون الأساسي عدد 22 المِؤرخ في 24 مارس 2016 المتعلق بالحق

في النفاذ إلى المعلومة للهياكل العمومية</a> يضع المؤسسة الوطنية لتحسين و تجويد الخيول على ذمة كل

المواطنين المعلومات والوثائق المنصوص عليها بالمرسوم المذكور </p>}
      </div>
      </div>
      <SideBar />
      </div>
        <Footer />
        </>
    );
}

export default New;
