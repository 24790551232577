import React, {useState, useEffect} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddHomeIcon from '@mui/icons-material/AddHome';
import CreateIcon from '@mui/icons-material/Create';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Typography from '@mui/material/Typography';



const TimeLine = ({language}) => {
    const [time, setTime] = useState(
      <Timeline
      sx={{ m: 'auto 0' }}
    >
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1866
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création du Haras Sidi Thabet</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1884
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création de la société des courses Hyppiques de Tunis</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1896
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Inscription des cheveaux Tunisiens au STUD Book</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1913
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création de l'établissement public des Haras Nationeaux</TimelineContent>
      </TimelineItem>
    </Timeline>
    );
    useEffect(()=>{
    if(language === 'fr'){
      <Timeline
      sx={{ m: 'auto 0' }}
    >
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1866
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création du Haras Sidi Thabet</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1884
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création de la société des courses Hyppiques de Tunis</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1896
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Inscription des cheveaux Tunisiens au STUD Book</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          1913
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent style={{color:'black'}}>Création de l'établissement public des Haras Nationeaux</TimelineContent>
      </TimelineItem>
    </Timeline>
    }else{setTime(
        <>
          <Timeline
      sx={{ m: 'auto 0' }}
    >
      <TimelineItem>
        <TimelineContent style={{color:'black' , textAlign:'right' , fontSize:'2rem'}}>انشاء اسطبل سيدي ثابت</TimelineContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineOppositeContent color="textSecondary" style={{textAlign:'left', fontSize:'1.5rem'}}>
          1866
        </TimelineOppositeContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineContent style={{color:'black', textAlign:'right', fontSize:'2rem'}}>انشاء شركة سباق الخيل</TimelineContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineOppositeContent color="textSecondary" style={{textAlign:'left', fontSize:'1.5rem'}}>
          1884
        </TimelineOppositeContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineContent style={{color:'black', textAlign:'right', fontSize:'2rem'}}>تسجيل الأحصنة التونسية في سجلات الأنساب</TimelineContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector /> 
        </TimelineSeparator>
        <TimelineOppositeContent color="textSecondary" style={{textAlign:'left', fontSize:'1.5rem'}}>
          1896
        </TimelineOppositeContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineContent style={{color:'black', textAlign:'right', fontSize:'2rem'}}>إنشاء المؤسسة العامة الإسطبلات الوطنية </TimelineContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineOppositeContent color="textSecondary" style={{textAlign:'left', fontSize:'1.5rem'}}>
          1913
        </TimelineOppositeContent>
      </TimelineItem>
    </Timeline>
        </>
      )}
    }, [language])
    return (
      <>
      {time}
      </>
    );
}

export default TimeLine;
/* 
<Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         1866
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <AddHomeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography className="h6" variant="h6" component="span">
            Création du Haras Sidi Thabet
          </Typography>
          
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          1884
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <CreateIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography className="h6" variant="h6" component="span">
            Création de la société des courses Hyppiques de Tunis
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          1896
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <BookmarkIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography className="h6" variant="h6" component="span">
            Inscription des cheveaux Tunisiens au STUD Book
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         1913
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <AddHomeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography className="h6" variant="h6" component="span">
            Création de l'établissement public des Haras Nationeaux
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>


*/