import React,{useState} from 'react';
import axios from 'axios';
import Info from "../components/Info";
//reactTable
import Table from "../components/Table"
import { Link } from 'react-router-dom';

const UserProfile = ({user}) => {


    const cols = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Nom', width: 130 },
        { field: 'sex', headerName: 'Sexe', width: 130 },
        {field: 'date',headerName: 'date de naissance',type: 'number',width: 90,},
        {field: 'race',headerName: 'Race',type: 'number',width: 90,},
        {field: 'robe',headerName: 'Robe',type: 'number',width: 90,},
        {field: 'pere',headerName: 'Pere',type: 'number',width: 90,},
        {field: 'mere',headerName: 'Mere',type: 'number',width: 90,},
        ];
        const dataToShow=[[
            'father1', 'father2', 'mere', 'id', 'mouna', 'PoAngl', 'sex', 'arabe', 'barbe', 'race', "annais", "robe",'communais','tunisie', 
        ]]
    return (
        <>
         {/* <Info className='width' name={user.firstName + ' ' + user.lastName} details={`${user.firstName + ' ' + user.lastName} , née le ${user.date_naissance} , habitant dans ${user.address}`}/> 
          <br/> 
          <br/> 
          <br/>
          <section>
            <Link to='/ajouter_cheval'><button className='button'>Ajouter</button></Link>
            {/*<Table cols={cols} dataToShow={dataToShow} />}
          </section>*/} 
          <h1>en cours de construction</h1>
        </>
    );
}

export default UserProfile;
