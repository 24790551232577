import React from 'react';
import { Link } from 'react-router-dom';

const EspaceMembreCard = ({language}) => {
    return (
        <>
            <div className="liasse-titre margin-top" style={{fontSize:'2rem', textAlign:"center"}}>{language === 'fr' ? 'Info Chevaux' : 'معلومات حول الخيول'}</div>
                <div className="liasse-corps" style={{textAlign:'center', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                  <img src="../images/ibn.PNG" style={{borderRadius:'50%', height:'15rem', width:'15rem'}} className="droite" />
                  <p>
                    <br />
                    <span className="grand center" style={{fontSize:'3rem', fontWeight:'bold'}} >{language === 'fr' ? 'Info Chevaux' : 'معلومات حول الخيول'}</span>
                  </p>
                  <br />
                  <p className="liasse-btn">
                    <Link to="/cheveaux">
                      {language === 'fr' ? "Accès à l'Info Chevaux" : 'الولوج إلى معلومات الحصان'}
                    </Link>
                  </p>
                </div>
        </>
    );
}

export default EspaceMembreCard;
