import React, {useState, useEffect} from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useNavigate } from 'react-router';
const Treeex = ({data, pere, mere, grandPere_p, grandPere_m, grandeMere_p, grandeMere_m, grandeMere_pere_p, grande_pere_pere_p, grande_pere_pere_m , grande_mere_pere_m, grande_mere_mere_p, grande_pere_mere_p, grande_pere_mere_m,  grande_mere_mere_m}) => {
        let navigate = useNavigate();
        const [pedigree, setPedigree] = useState({
            
            nom:data.nom,
            dateNaissance:data.dateNaissance,
            
            gen2:{
                fils1:{
                    nom:'airmaxJr',
                    dateNaissance:'07/06/2002',

                    gen3:{
                        fils1:{
                            nom:'airmaxJrJr',
                            dateNaissance:'07/06/2004',
                            gen4:{
                                fils1:{
                                    nom:'airmaxJrJrJr',
                                    dateNaissance:'07/06/2004',
                                },
                                fils2:{
                                    nom:'airmaxJrJrJr',
                                    dateNaissance:'07/06/2004',
                                },
                            }
                        },
                        fils2:{
                            nom:'airmaxJrJr2',
                            dateNaissance:'07/06/2000',
                            gen4:{
                                fils1:{
                                    nom:'airmaxJrJr2Jr',
                                    dateNaissance:'07/06/2004',
                                },
                                fils2:{
                                    nom:'airmaxJrJr2Jr',
                                    dateNaissance:'07/06/2004',
                                },
                            }
                        },
                    }
                },
                fils2:{
                    nom:mere.nom,
                    dateNaissance:mere.date_N,
                    gen3:{
                        fils1:{
                            nom:'airmaxJr2Jr',
                            dateNaissance:'07/06/2004',
                            gen4:{
                                fils1:{
                                    nom:'airmaxJr2JrJr',
                                    dateNaissance:'07/06/2004',
                                },
                                fils2:{
                                    nom:'airmaxJr2JrJr2',
                                    dateNaissance:'07/06/2004',
                                },
                            }
                        },
                        fils2:{
                            nom:'airmaxJr2Jr2',
                            dateNaissance:'07/06/2000',
                            gen4:{
                                fils1:{
                                    nom:'airmaxJr2Jr2Jr',
                                    dateNaissance:'07/06/2004',
                                },
                                fils2:{
                                    nom:'airmaxJr2Jr2Jr2',
                                    dateNaissance:'07/06/2004',
                                },
                            }
                        },
                    }
                },
            }
        });
        useEffect(() => {
            setPedigree({
            
                nom:data.nom,
                dateNaissance:data.date_N,
                
                gen2:{
                    fils1:{
                        nom:pere.nom,
                        dateNaissance:pere.date_N,
    
                        gen3:{
                            fils1:{
                                nom:grandPere_p.nom,
                                dateNaissance:grandPere_p.date_N,
                                gen4:{
                                    fils1:{
                                        nom:grande_pere_pere_p.nom,
                                        dateNaissance:grande_pere_pere_p.date_N,
                                    },
                                    fils2:{
                                        nom:grandeMere_pere_p.nom,
                                        dateNaissance:grandeMere_pere_p.date_N,
                                    },
                                }
                            },
                            fils2:{
                                nom:grandeMere_p.nom,
                                dateNaissance:grandeMere_p.date_N,
                                gen4:{
                                    fils1:{
                                        nom:grande_pere_pere_m.nom,
                                        dateNaissance:grande_pere_pere_m.date_N,
                                    },
                                    fils2:{
                                        nom:grande_mere_pere_m.nom,
                                        dateNaissance:grande_mere_pere_m.date_N,
                                    },
                                }
                            },
                        }
                    },
                    fils2:{
                        nom:mere.nom,
                        dateNaissance:mere.date_N,
                        gen3:{
                            fils1:{
                                nom:grandPere_m.nom,
                                dateNaissance:grandPere_m.date_N,
                                gen4:{
                                    fils1:{
                                        nom:grande_pere_mere_p.nom,
                                        dateNaissance:grande_pere_mere_p.date_N,
                                    },
                                    fils2:{
                                        nom:grande_mere_mere_p.nom,
                                        dateNaissance:grande_mere_mere_p.date_N,
                                    },
                                }
                            },
                            fils2:{
                                nom:grandeMere_m.nom,
                                dateNaissance:grandeMere_m.date_N,
                                gen4:{
                                    fils1:{
                                        nom:grande_pere_mere_m.nom,
                                        dateNaissance:grande_pere_mere_m.date_N,
                                    },
                                    fils2:{
                                        nom:grande_mere_mere_m.nom,
                                        dateNaissance:grande_mere_mere_m.date_N,
                                    },
                                }
                            },
                        }
                    },
                }
            })
           
        }, [data]);
        const navHandler = async (x)=>{
            navigate(`/cheveaux/${x}`)
            console.log(x)
        }
    return (
        <div >

              <Tree   label={<div style={{marginTop:'2rem'}} className='tree'>{pedigree.nom} Née le: {pedigree.dateNaissance}</div>} >
                <TreeNode className="tree" label={<div onClick={()=>{navHandler(pere.id)}}>{pedigree.gen2.fils1.nom} Née le: {pedigree.gen2.fils1.dateNaissance}</div>} onClick={()=>{navHandler(grandPere_p.id)}}>
                    <TreeNode className="tree" label={<div onClick={()=>{navHandler(grandPere_p.id)}}>{pedigree.gen2.fils1.gen3.fils1.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.dateNaissance}</div>} >
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils1.gen3.fils1.gen4.fils1.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.gen4.fils1.dateNaissance}</div>} />
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils1.gen3.fils1.gen4.fils2.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.gen4.fils2.dateNaissance}</div>} />
                    </TreeNode>
                    <TreeNode className="tree" label={<div onClick={()=>{navHandler(grandeMere_p.id)}}>{pedigree.gen2.fils1.gen3.fils2.nom} Née le: {pedigree.gen2.fils1.gen3.fils2.dateNaissance}</div>} >
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils1.gen3.fils2.gen4.fils1.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.gen4.fils1.dateNaissance}</div>} />
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils1.gen3.fils2.gen4.fils2.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.gen4.fils2.dateNaissance}</div>} />
                    </TreeNode>
                </TreeNode>
                <TreeNode className="tree" label={<div onClick={()=>{navHandler(mere.id)}}>{pedigree.gen2.fils2.nom} Née le: {pedigree.gen2.fils2.dateNaissance}</div>}>
                    <TreeNode className="tree" label={<div onClick={()=>{navHandler(grandPere_m.id)}}>{pedigree.gen2.fils2.gen3.fils1.nom} Née le: {pedigree.gen2.fils1.gen3.fils1.dateNaissance}</div>} >
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils2.gen3.fils1.gen4.fils1.nom} Née le: {pedigree.gen2.fils2.gen3.fils1.gen4.fils1.dateNaissance}</div>} />
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils2.gen3.fils1.gen4.fils2.nom} Née le: {pedigree.gen2.fils2.gen3.fils1.gen4.fils2.dateNaissance}</div>} /> 
                    </TreeNode>
                    <TreeNode className="tree" label={<div onClick={()=>{navHandler(grandeMere_m.id)}}>{pedigree.gen2.fils2.gen3.fils2.nom} Née le: {pedigree.gen2.fils1.gen3.fils2.dateNaissance}</div>} >
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils2.gen3.fils2.gen4.fils1.nom} Née le: {pedigree.gen2.fils2.gen3.fils2.gen4.fils1.dateNaissance}</div>} />
                        <TreeNode className="tree" label={<div>{pedigree.gen2.fils2.gen3.fils2.gen4.fils2.nom} Née le: {pedigree.gen2.fils2.gen3.fils2.gen4.fils2.dateNaissance}</div>} />
                    </TreeNode>
                </TreeNode>
              </Tree>
        </div>
    );
}

export default Treeex;
