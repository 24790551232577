import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import SideBar from '../components/SideBar';

const Bureau = ({language, setLanguage}) => {
    return (
        <div>
        <Navbar language={language} setLanguage={setLanguage} active='bureau'/>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="content margin-top" style={{padding:'2rem'}}>
          <ul className="breadcrumb">
            <li><Link to='/'>Accueil</Link></li>
          </ul>
          <h1>Bureau des relations avec le citoyen</h1>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h2>Cadre juridique</h2>
              <p>
                Par décret n° 93-1549 du 26 juillet 1993, il est
                créé, dans chaque ministère et chaque
                gouvernorat, un bureau relevant directement du ministre au
                niveau central et du gouverneur au niveau régional
                dénommé « bureau des relations avec le
                citoyen » chargé d’aider le citoyen
                à surmonter les difficultés qu’il
                pourrait rencontrer dans ses relations avec
                l’administration et de lui faciliter l’obtention
                des prestations administratives dans le cadre de la
                législation et de la réglementation en
                vigueur.
              </p>
              <p>
                En outre, il est créé un Bureau Central des
                Relations avec le Citoyen au Premier ministère (la
                Présidence du Gouvernement actuellement).
              </p>
              <p>
                D'autres bureaux ont été également
                implantés dans une dizaine d'entreprises&nbsp;et
                d'établissements publics,citant l’agence de
                promotion des investissement agricoles.
              </p>
              <p>
                <a href="https://www.mtcen.gov.tn/fileadmin/BRC/decret_n_93_1549_brc.pdf" target='_blank'>Décret n° 93-1549 du 26 juillet 1993 portant
                  création des bureaux des relations avec le citoyen
                  tel que modifié et complété par le
                  décret n° 93-2398 du 29 novembre 1993 et le
                  décret n° 98-1152 du 25 mai 1998.</a>
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <h2>A qui devrais-je m’adresser ?</h2>
              <p>
                <strong>Responsable des relations avec le citoyen</strong><br />
                <strong></strong><br />
                <strong>Adresse</strong> :2020 SIDI THABET TUNIS<br />
                <strong>Tél&nbsp;</strong> : 71 552 220 &nbsp;&nbsp;/&nbsp;&nbsp;71 552 110&nbsp;&nbsp;<br />
                <strong>Fax</strong> : 71 552 391&nbsp;&nbsp; &nbsp;<br />
                <strong>Email</strong> :
                <a href="mailto:dg-fnarc@topnet.tn">dg-fnarc@topnet.tn</a>
              </p>
              <h2>Vos réclamations en ligne</h2>
              <form action="/brc" method="post" name="form2" id="form2">
                <table className="tableau_formulaire" width="90%">
                  <tbody><tr className="form_name">
                      <td>Name :</td>
                      <td>
                        <input type="text" name="name" id="name" style={{width: '100%', color:'black'}} defaultValue />
                      </td>
                    </tr>
                    <tr>
                      <td>Nom :</td>
                      <td>
                        <input type="text" name="nom" id="nom" style={{width: '100%', color:'black'}} defaultValue required />
                      </td>
                    </tr>
                    <tr>
                      <td>Prénom :</td>
                      <td>
                        <input type="text" name="prenom" id="prenom" style={{width: '100%', color:'black'}} defaultValue required />
                      </td>
                    </tr>
                    <tr>
                      <td>Téléphone :</td>
                      <td>
                        <input type="tel" name="telephone" id="telephone" style={{width: '100%', color:'black'}} defaultValue required />
                      </td>
                    </tr>
                    <tr>
                      <td>E-mail :</td>
                      <td>
                        <input type="email" name="email" id="email" style={{width: '100%', color:'black'}} defaultValue required />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Votre message :<br />
                        <textarea name="message" id="message" rows={8} style={{width: '100%', color:'black'}} required defaultValue={""} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <input type="hidden" name="moment" defaultValue={1677941909} />
                        <a type="submit" className="btn oe-btn oe-btn-reflect-icon" href='mailto:koussayfridhiaziz@gmail.com'>
                          Envoyer
                        </a>
                      </td>
                    </tr>
                  </tbody></table>
              </form>
              <div className="small">Tous les champs sont obligatoires</div>
            </div>
          </div>
        </div>
        </div>
        <SideBar language={language} />
        </div>
            <Footer language={language} />
        </div>
    );
}

export default Bureau;
