import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function BasicExample({data}) {
  return (
    <Card style={{ width: '25rem' , minHeight:'574px', padding:'20px 0px', textAlign:"center"}}>
        <div className='iconHolder'>
        {data.icon}
        </div>
        <Card.Body style={{ display:'flex' , justifyContent:'space-arround', alignItems:'center', flexDirection:'column'}}>
            <Card.Title style={{height:'100px', fontSize:'40px'}}>{data.title}</Card.Title>
            { <img src={data?.Img} style={{objectFit:'cover', height:'200px', width:'200px', borderRadius:'50%'}} alt='img'/>}
            <Card.Text style={{color:'black', textAlign:'center'}}>
            {data.description}
            </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default BasicExample;