import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import "./style.css";
const VerticalTree = ({ data }) => {
  const [level, setLevel] = useState(3);
  const today = new Date();
  const changeHandler = (e) => {
    e.preventDefault();
    level === 3 ? setLevel(5) : setLevel(3);
  };
  const componentRef = useRef();
  useEffect(() => {
    window.scrollTo({
      top: 350,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          gap: "20px",
        }}
      >
        <ReactToPrint
          trigger={() => (
            <button className="button-34">Télechargement PDF</button>
          )}
          content={() => componentRef.current}
        />
        <button onClick={changeHandler} style={{ fontSize: "2rem" }}>{`${
          level === 3 ? "5" : "3"
        } génération`}</button>
      </div>
      <br />
      <br />
      <br />
      <p style={{ textAlign: "left" }}>
        Données à la date de :{" "}
        {`${today.getDate() - 1}/ ${
          today.getMonth() + 1
        } /${today?.getFullYear()}`}
      </p>
      <br />
      <br />
      <br />
      <div id="wrapper" ref={componentRef} style={{ overflow: "scroll" }}>
        <span style={{ color: "black", fontSize: "1.5rem" }} className="label">
          <Link style={{ color: "blue" }} to={`/cheveaux/${data?.cheval?.id}`}>
            {data?.cheval?.nom}
          </Link>
          {data?.cheval?.pays &&
            data?.cheval?.pays !== "TN" &&
            `(${data?.cheval?.pays})`}{" "}
          , {data?.cheval?.race} <br />
          {data?.cheval?.sex}, {data?.cheval?.robe},{data?.cheval?.date_N}
        </span>
        <div className="branch lv1">
          <div className="entry">
            <span
              style={{ color: "black", fontSize: "1.5rem" }}
              className="label"
            >
              <Link
                style={{ color: "blue" }}
                to={`/cheveaux/${data?.pere?.id}`}
              >
                {data?.pere?.nom}
              </Link>
              {data?.pere?.pays !== "TN" && `(${data?.pere?.pays})`},{" "}
              {data?.pere?.race}, {data?.pere?.date_N}
            </span>
            <div className="branch lv2">
              <div className="entry">
                <span
                  style={{ color: "black", fontSize: "1.5rem" }}
                  className="label"
                >
                  <Link
                    style={{ color: "blue" }}
                    to={`/cheveaux/${data?.grand_pere_p?.id}`}
                  >
                    {data?.grand_pere_p?.nom}
                  </Link>
                  {data?.grand_pere_p?.pays !== "TN" &&
                    `(${data?.grand_pere_p?.pays})`}
                  , {data?.grand_pere_p?.race}, <br />{" "}
                  {data?.grand_pere_p?.date_N}
                </span>

                <div className="branch lv2">
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_pere_pere_p?.id}`}
                      >
                        {data?.grande_pere_pere_p?.nom}
                      </Link>
                      {data?.grande_pere_pere_p?.pays !== "TN" &&
                        `(${data?.grande_pere_pere_p?.pays})`}
                      , {data?.grande_pere_pere_p?.race}, <br />{" "}
                      {data?.grande_pere_pere_p?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandPere_P_p?.id}`}
                            >
                              {data?.GrandPere_GrandPere_P_p?.nom}
                            </Link>
                            {data?.GrandPere_GrandPere_P_p?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandPere_P_p?.pays})`}
                            , {data?.GrandPere_GrandPere_P_p?.race}, <br />{" "}
                            {data?.GrandPere_GrandPere_P_p?.date_N}
                          </span>
                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandePere_P_p_p?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandePere_P_p_p?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandePere_P_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandePere_P_p_p?.pays})`}
                                , {data?.GrandPere_Pere_GrandePere_P_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandePere_P_p_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Pere_GrandPere_P_p_p?.id}`}
                                >
                                  {data?.GrandeMere_Pere_GrandPere_P_p_p?.nom}
                                </Link>
                                {data?.GrandeMere_Pere_GrandPere_P_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Pere_GrandPere_P_p_p?.pays})`}
                                , {data?.GrandeMere_Pere_GrandPere_P_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Pere_GrandPere_P_p_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandPere_P_p?.id}`}
                            >
                              {data?.GrandeMere_GrandPere_P_p?.nom}
                            </Link>
                            {data?.GrandeMere_GrandPere_P_p?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandPere_P_p?.pays})`}
                            , {data?.GrandeMere_GrandPere_P_p?.race}, <br />{" "}
                            {data?.GrandeMere_GrandPere_P_p?.date_N}
                          </span>
                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandePere_P_p_m?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandePere_P_p_m?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandePere_P_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandePere_P_p_m?.pays})`}
                                , {data?.GrandPere_Pere_GrandePere_P_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandePere_P_p_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Pere_GrandPere_P_p_m?.id}`}
                                >
                                  {data?.GrandeMere_Pere_GrandPere_P_p_m?.nom}
                                </Link>
                                {data?.GrandeMere_Pere_GrandPere_P_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Pere_GrandPere_P_p_m?.pays})`}
                                , {data?.GrandeMere_Pere_GrandPere_P_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Pere_GrandPere_P_p_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_mere_pere_p?.id}`}
                      >
                        {data?.grande_mere_pere_p?.nom}
                      </Link>
                      {data?.grande_mere_pere_p?.pays !== "TN" &&
                        `(${data?.grande_mere_pere_p?.pays})`}
                      , {data?.grande_mere_pere_p?.race}, <br />{" "}
                      {data?.grande_mere_pere_p?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandPere_P_m?.id}`}
                            >
                              {data?.GrandPere_GrandPere_P_m?.nom}
                            </Link>
                            {data?.GrandPere_GrandPere_P_m?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandPere_P_m?.pays})`}
                            , {data?.GrandPere_GrandPere_P_m?.race}, <br />{" "}
                            {data?.GrandPere_GrandPere_P_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandePere_P_p_p?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandePere_P_p_p?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandePere_P_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandePere_P_p_p?.pays})`}
                                , {data?.GrandPere_Mere_GrandePere_P_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandePere_P_p_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandPere_P_p_p?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandPere_P_p_p?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandPere_P_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandPere_P_p_p?.pays})`}
                                , {data?.GrandeMere_Mere_GrandPere_P_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Mere_GrandPere_P_p_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandPere_P_m?.id}`}
                            >
                              {data?.GrandeMere_GrandPere_P_m?.nom}
                            </Link>
                            {data?.GrandeMere_GrandPere_P_m?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandPere_P_m?.pays})`}
                            , {data?.GrandeMere_GrandPere_P_m?.race}, <br />{" "}
                            {data?.GrandeMere_GrandPere_P_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandeMere_M_m_p?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandeMere_M_m_p?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandeMere_M_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandeMere_M_m_p?.pays})`}
                                , {data?.GrandPere_Pere_GrandeMere_M_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandeMere_M_m_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandPere_P_p_m?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandPere_P_p_m?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandPere_P_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandPere_P_p_m?.pays})`}
                                , {data?.GrandeMere_Mere_GrandPere_P_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Mere_GrandPere_P_p_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="entry">
                <span
                  style={{ color: "black", fontSize: "1.5rem" }}
                  className="label"
                >
                  <Link
                    style={{ color: "blue" }}
                    to={`/cheveaux/${data?.grande_mere_p?.id}`}
                  >
                    {data?.grande_mere_p?.nom}
                  </Link>
                  {data?.grande_mere_p?.pays !== "TN" &&
                    `(${data?.grande_mere_p?.pays})`}
                  , {data?.grande_mere_p?.race}, <br />{" "}
                  {data?.grande_mere_p?.date_N}
                </span>

                <div className="branch lv2">
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_pere_pere_m?.id}`}
                      >
                        {data?.grande_pere_pere_m?.nom}
                      </Link>
                      {data?.grande_pere_pere_m?.pays !== "TN" &&
                        `(${data?.grande_pere_pere_m?.pays})`}
                      , {data?.grande_pere_pere_m?.race}, <br />{" "}
                      {data?.grande_pere_pere_m?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandeMere_P_p?.id}`}
                            >
                              {data?.GrandPere_GrandeMere_P_p?.nom}
                            </Link>
                            {data?.GrandPere_GrandeMere_P_p?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandeMere_P_p?.pays})`}
                            , {data?.GrandPere_GrandeMere_P_p?.race}, <br />{" "}
                            {data?.GrandPere_GrandeMere_P_p?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandeMere_P_m_p?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandeMere_P_m_p?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandeMere_P_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandeMere_P_m_p?.pays})`}
                                , {data?.GrandPere_Pere_GrandeMere_P_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandeMere_P_m_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Pere_GrandeMere_P_m_p?.id}`}
                                >
                                  {data?.GrandeMere_Pere_GrandeMere_P_m_p?.nom}
                                </Link>
                                {data?.GrandeMere_Pere_GrandeMere_P_m_p
                                  ?.pays !== "TN" &&
                                  `(${data?.GrandeMere_Pere_GrandeMere_P_m_p?.pays})`}
                                , {data?.GrandeMere_Pere_GrandeMere_P_m_p?.race}
                                , <br />{" "}
                                {data?.GrandeMere_Pere_GrandeMere_P_m_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandeMere_P_p?.id}`}
                            >
                              {data?.GrandeMere_GrandeMere_P_p?.nom}
                            </Link>
                            {data?.GrandeMere_GrandeMere_P_p?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandeMere_P_p?.pays})`}
                            , {data?.GrandeMere_GrandeMere_P_p?.race}, <br />{" "}
                            {data?.GrandeMere_GrandeMere_P_p?.date_N}
                          </span>
                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandeMere_P_m_m?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandeMere_P_m_m?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandeMere_P_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandeMere_P_m_m?.pays})`}
                                , {data?.GrandPere_Pere_GrandeMere_P_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandeMere_P_m_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Pere_GrandeMere_P_m_m?.id}`}
                                >
                                  {data?.GrandeMere_Pere_GrandeMere_P_m_m?.nom}
                                </Link>
                                {data?.GrandeMere_Pere_GrandeMere_P_m_m
                                  ?.pays !== "TN" &&
                                  `(${data?.GrandeMere_Pere_GrandeMere_P_m_m?.pays})`}
                                , {data?.GrandeMere_Pere_GrandeMere_P_m_m?.race}
                                , <br />{" "}
                                {data?.GrandeMere_Pere_GrandeMere_P_m_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_mere_pere_m?.id}`}
                      >
                        {data?.grande_mere_pere_m?.nom}
                      </Link>
                      {data?.grande_mere_pere_m?.pays !== "TN" &&
                        `(${data?.grande_mere_pere_m?.pays})`}
                      , {data?.grande_mere_pere_m?.race}, <br />{" "}
                      {data?.grande_mere_pere_m?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandeMere_P_m?.id}`}
                            >
                              {data?.GrandPere_GrandeMere_P_m?.nom}
                            </Link>
                            {data?.GrandPere_GrandeMere_P_m?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandeMere_P_m?.pays})`}
                            , {data?.GrandPere_GrandeMere_P_m?.race}, <br />{" "}
                            {data?.GrandPere_GrandeMere_P_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandeMere_P_m_p?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandeMere_P_m_p?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandeMere_P_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandeMere_P_m_p?.pays})`}
                                , {data?.GrandPere_Mere_GrandeMere_P_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandeMere_P_m_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandeMere_P_m_p?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandeMere_P_m_p?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandeMere_P_m_p
                                  ?.pays !== "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandeMere_P_m_p?.pays})`}
                                , {data?.GrandeMere_Mere_GrandeMere_P_m_p?.race}
                                , <br />{" "}
                                {data?.GrandeMere_Mere_GrandeMere_P_m_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandeMere_P_m?.id}`}
                            >
                              {data?.GrandeMere_GrandeMere_P_m?.nom}
                            </Link>
                            {data?.GrandeMere_GrandeMere_P_m?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandeMere_P_m?.pays})`}
                            , {data?.GrandeMere_GrandeMere_P_m?.race}, <br />{" "}
                            {data?.GrandeMere_GrandeMere_P_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandeMere_P_m_m?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandeMere_P_m_m?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandeMere_P_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandeMere_P_m_m?.pays})`}
                                , {data?.GrandPere_Mere_GrandeMere_P_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandeMere_P_m_m?.date_N}
                              </span>
                            </div>

                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandeMere_P_m_m?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandeMere_P_m_m?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandeMere_P_m_m
                                  ?.pays !== "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandeMere_P_m_m?.pays})`}
                                , {data?.GrandeMere_Mere_GrandeMere_P_m_m?.race}
                                , <br />{" "}
                                {data?.GrandeMere_Mere_GrandeMere_P_m_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="entry">
            <span
              style={{ color: "black", fontSize: "1.5rem" }}
              className="label"
            >
              <Link
                style={{ color: "blue" }}
                to={`/cheveaux/${data?.mere?.id}`}
              >
                {data?.mere?.nom}
              </Link>
              {data?.mere?.pays !== "TN" && `(${data?.mere?.pays})`},{" "}
              {data?.mere?.race},<br /> {data?.mere?.date_N}
            </span>
            <div className="branch lv2">
              <div className="entry">
                <span
                  style={{ color: "black", fontSize: "1.5rem" }}
                  className="label"
                >
                  <Link
                    style={{ color: "blue" }}
                    to={`/cheveaux/${data?.grand_pere_m?.id}`}
                  >
                    {data?.grand_pere_m?.nom}
                  </Link>
                  {data?.grand_pere_m?.pays !== "TN" &&
                    `(${data?.grand_pere_m?.pays})`}
                  , {data?.grand_pere_m?.race}, <br />{" "}
                  {data?.grand_pere_m?.date_N}
                </span>

                <div className="branch lv2">
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_pere_mere_p?.id}`}
                      >
                        {data?.grande_pere_mere_p?.nom}
                      </Link>
                      {data?.grande_pere_mere_p?.pays !== "TN" &&
                        `(${data?.grande_pere_mere_p?.pays})`}
                      , {data?.grande_pere_mere_p?.race}, <br />{" "}
                      {data?.grande_pere_mere_p?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandPere_M_p?.id}`}
                            >
                              {data?.GrandPere_GrandPere_M_p?.nom}
                            </Link>
                            {data?.GrandPere_GrandPere_M_p?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandPere_M_p?.pays})`}
                            , {data?.GrandPere_GrandPere_M_p?.race}, <br />{" "}
                            {data?.GrandPere_GrandPere_M_p?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandPere_M_p_p?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandPere_M_p_p?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandPere_M_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandPere_M_p_p?.pays})`}
                                , {data?.GrandPere_Mere_GrandPere_M_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandPere_M_p_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandPere_M_p_p?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandPere_M_p_p?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandPere_M_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandPere_M_p_p?.pays})`}
                                , {data?.GrandeMere_Mere_GrandPere_M_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Mere_GrandPere_M_p_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandPere_M_p?.id}`}
                            >
                              {data?.GrandeMere_GrandPere_M_p?.nom}
                            </Link>
                            {data?.GrandeMere_GrandPere_M_p?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandPere_M_p?.pays})`}
                            , {data?.GrandeMere_GrandPere_M_p?.race}, <br />{" "}
                            {data?.GrandeMere_GrandPere_M_p?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandePere_M_p_m?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandePere_M_p_m?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandePere_M_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandePere_M_p_m?.pays})`}
                                , {data?.GrandPere_Pere_GrandePere_M_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandePere_M_p_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandMere_Pere_GrandePere_M_p_m?.id}`}
                                >
                                  {data?.GrandMere_Pere_GrandePere_M_p_m?.nom}
                                </Link>
                                {data?.GrandMere_Pere_GrandePere_M_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandMere_Pere_GrandePere_M_p_m?.pays})`}
                                , {data?.GrandMere_Pere_GrandePere_M_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandMere_Pere_GrandePere_M_p_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_mere_mere_p?.id}`}
                      >
                        {data?.grande_mere_mere_p?.nom}
                      </Link>
                      {data?.grande_mere_mere_p?.pays !== "TN" &&
                        `(${data?.grande_mere_mere_p?.pays})`}
                      , {data?.grande_mere_mere_p?.race}, <br />{" "}
                      {data?.grande_mere_mere_p?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandPere_M_m?.id}`}
                            >
                              {data?.GrandPere_GrandPere_M_m?.nom}
                            </Link>
                            {data?.GrandPere_GrandPere_M_m?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandPere_M_m?.pays})`}
                            , {data?.GrandPere_GrandPere_M_m?.race}, <br />{" "}
                            {data?.GrandPere_GrandPere_M_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandePere_M_p_p?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandePere_M_p_p?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandePere_M_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandePere_M_p_p?.pays})`}
                                , {data?.GrandPere_Pere_GrandePere_M_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandePere_M_p_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Pere_GrandPere_M_p_p?.id}`}
                                >
                                  {data?.GrandeMere_Pere_GrandPere_M_p_p?.nom}
                                </Link>
                                {data?.GrandeMere_Pere_GrandPere_M_p_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Pere_GrandPere_M_p_p?.pays})`}
                                , {data?.GrandeMere_Pere_GrandPere_M_p_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Pere_GrandPere_M_p_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandPere_M_m?.id}`}
                            >
                              {data?.GrandeMere_GrandPere_M_m?.nom}
                            </Link>
                            {data?.GrandeMere_GrandPere_M_m?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandPere_M_m?.pays})`}
                            , {data?.GrandeMere_GrandPere_M_m?.race}, <br />{" "}
                            {data?.GrandeMere_GrandPere_M_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandPere_M_p_m?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandPere_M_p_m?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandPere_M_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandPere_M_p_m?.pays})`}
                                , {data?.GrandPere_Mere_GrandPere_M_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandPere_M_p_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandeMere_Mere_GrandPere_M_p_m?.id}`}
                                >
                                  {data?.GrandeMere_Mere_GrandPere_M_p_m?.nom}
                                </Link>
                                {data?.GrandeMere_Mere_GrandPere_M_p_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandeMere_Mere_GrandPere_M_p_m?.pays})`}
                                , {data?.GrandeMere_Mere_GrandPere_M_p_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandeMere_Mere_GrandPere_M_p_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="entry">
                <span
                  style={{ color: "black", fontSize: "1.5rem" }}
                  className="label"
                >
                  <Link
                    style={{ color: "blue" }}
                    to={`/cheveaux/${data?.grande_mere_m?.id}`}
                  >
                    {data?.grande_mere_m?.nom}
                  </Link>
                  {data?.grande_mere_m?.pays !== "TN" &&
                    `(${data?.grande_mere_m?.pays})`}
                  , {data?.grande_mere_m?.race}, <br />{" "}
                  {data?.grande_mere_m?.date_N}
                </span>

                <div className="branch lv2">
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_pere_mere_m?.id}`}
                      >
                        {data?.grande_pere_mere_m?.nom}
                      </Link>
                      {data?.grande_pere_mere_m?.pays !== "TN" &&
                        `(${data?.grande_pere_mere_m?.pays})`}
                      , {data?.grande_pere_mere_m?.race}, <br />{" "}
                      {data?.grande_pere_mere_m?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandeMere_M_p?.id}`}
                            >
                              {data?.GrandPere_GrandeMere_M_p?.nom}
                            </Link>
                            {data?.GrandPere_GrandeMere_M_p?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandeMere_M_p?.pays})`}
                            , {data?.GrandPere_GrandeMere_M_p?.race}, <br />{" "}
                            {data?.GrandPere_GrandeMere_M_p?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandeMere_M_m_p?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandeMere_M_m_p?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandeMere_M_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandeMere_M_m_p?.pays})`}
                                , {data?.GrandPere_Pere_GrandeMere_M_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandeMere_M_m_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandMere_Pere_GrandeMere_M_m_p?.id}`}
                                >
                                  {data?.GrandMere_Pere_GrandeMere_M_m_p?.nom}
                                </Link>
                                {data?.GrandMere_Pere_GrandeMere_M_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandMere_Pere_GrandeMere_M_m_p?.pays})`}
                                , {data?.GrandMere_Pere_GrandeMere_M_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandMere_Pere_GrandeMere_M_m_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandeMere_M_p?.id}`}
                            >
                              {data?.GrandeMere_GrandeMere_M_p?.nom}
                            </Link>
                            {data?.GrandeMere_GrandeMere_M_p?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandeMere_M_p?.pays})`}
                            , {data?.GrandeMere_GrandeMere_M_p?.race}, <br />{" "}
                            {data?.GrandeMere_GrandeMere_M_p?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Pere_GrandeMere_M_m_m?.id}`}
                                >
                                  {data?.GrandPere_Pere_GrandeMere_M_m_m?.nom}
                                </Link>
                                {data?.GrandPere_Pere_GrandeMere_M_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Pere_GrandeMere_M_m_m?.pays})`}
                                , {data?.GrandPere_Pere_GrandeMere_M_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Pere_GrandeMere_M_m_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandMere_Pere_GrandeMere_M_m_m?.id}`}
                                >
                                  {data?.GrandMere_Pere_GrandeMere_M_m_m?.nom}
                                </Link>
                                {data?.GrandMere_Pere_GrandeMere_M_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandMere_Pere_GrandeMere_M_m_m?.pays})`}
                                , {data?.GrandMere_Pere_GrandeMere_M_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandMere_Pere_GrandeMere_M_m_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="entry">
                    <span
                      style={{ color: "black", fontSize: "1.5rem" }}
                      className="label"
                    >
                      <Link
                        style={{ color: "blue" }}
                        to={`/cheveaux/${data?.grande_mere_mere_m?.id}`}
                      >
                        {data?.grande_mere_mere_m?.nom}
                      </Link>
                      {data?.grande_mere_mere_m?.pays !== "TN" &&
                        `(${data?.grande_mere_mere_m?.pays})`}
                      , {data?.grande_mere_mere_m?.race}, <br />{" "}
                      {data?.grande_mere_mere_m?.date_N}
                    </span>

                    {level === 5 && (
                      <div className="branch lv2">
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandPere_GrandeMere_M_m?.id}`}
                            >
                              {data?.GrandPere_GrandeMere_M_m?.nom}
                            </Link>
                            {data?.GrandPere_GrandeMere_M_m?.pays !== "TN" &&
                              `(${data?.GrandPere_GrandeMere_M_m?.pays})`}
                            , {data?.GrandPere_GrandeMere_M_m?.race}, <br />{" "}
                            {data?.GrandPere_GrandeMere_M_m?.date_N}
                          </span>

                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandeMere_M_m_p?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandeMere_M_m_p?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandeMere_M_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandeMere_M_m_p?.pays})`}
                                , {data?.GrandPere_Mere_GrandeMere_M_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandeMere_M_m_p?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandMere_Mere_GrandeMere_M_m_p?.id}`}
                                >
                                  {data?.GrandMere_Mere_GrandeMere_M_m_p?.nom}
                                </Link>
                                {data?.GrandMere_Mere_GrandeMere_M_m_p?.pays !==
                                  "TN" &&
                                  `(${data?.GrandMere_Mere_GrandeMere_M_m_p?.pays})`}
                                , {data?.GrandMere_Mere_GrandeMere_M_m_p?.race},{" "}
                                <br />{" "}
                                {data?.GrandMere_Mere_GrandeMere_M_m_p?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span
                            style={{ color: "black", fontSize: "1.5rem" }}
                            className="label"
                          >
                            <Link
                              style={{ color: "blue" }}
                              to={`/cheveaux/${data?.GrandeMere_GrandeMere_M_m?.id}`}
                            >
                              {data?.GrandeMere_GrandeMere_M_m?.nom}
                            </Link>
                            {data?.GrandeMere_GrandeMere_M_m?.pays !== "TN" &&
                              `(${data?.GrandeMere_GrandeMere_M_m?.pays})`}
                            , {data?.GrandeMere_GrandeMere_M_m?.race}, <br />{" "}
                            {data?.GrandeMere_GrandeMere_M_m?.date_N}
                          </span>
                          <div className="branch lv2">
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandPere_Mere_GrandeMere_M_m_m?.id}`}
                                >
                                  {data?.GrandPere_Mere_GrandeMere_M_m_m?.nom}
                                </Link>
                                {data?.GrandPere_Mere_GrandeMere_M_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandPere_Mere_GrandeMere_M_m_m?.pays})`}
                                , {data?.GrandPere_Mere_GrandeMere_M_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandPere_Mere_GrandeMere_M_m_m?.date_N}
                              </span>
                            </div>
                            <div className="entry">
                              <span
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="label"
                              >
                                <Link
                                  style={{ color: "blue" }}
                                  to={`/cheveaux/${data?.GrandMere_Mere_GrandeMere_M_m_m?.id}`}
                                >
                                  {data?.GrandMere_Mere_GrandeMere_M_m_m?.nom}
                                </Link>
                                {data?.GrandMere_Mere_GrandeMere_M_m_m?.pays !==
                                  "TN" &&
                                  `(${data?.GrandMere_Mere_GrandeMere_M_m_m?.pays})`}
                                , {data?.GrandMere_Mere_GrandeMere_M_m_m?.race},{" "}
                                <br />{" "}
                                {data?.GrandMere_Mere_GrandeMere_M_m_m?.date_N}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalTree;
