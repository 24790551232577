import React, {useState, useEffect} from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import tome1 from "../pdf/TSB TOME-I- 1956-1970.pdf";
import tome2 from "../pdf/TSB TOME-II -1971-1973.pdf";
import tome3 from "../pdf/TSB TOME III- 1974-1976.PDF";
import tome4 from "../pdf/TSB TOME-IV- 1977-1979.pdf";
import tome5 from "../pdf/TSB VOLUME -V- 1980-1982.pdf";

import tome6 from "../pdf/TSB VOLUME -VI- 1983-1986.pdf";
import tome7 from "../pdf/TSB VOLUME- VII- 1987-1990.pdf";
import tome8 from "../pdf/TSB VOLUME- VIII- 1991-1994.pdf";
import tome9 from "../pdf/TSB VOLUME  IX.pdf";
import tome10 from "../pdf/TSB VOLUME X.pdf";
import tome11 from "../pdf/TSB VOLUME XI.pdf";
import tome12 from "../pdf/TSB VOLUME XII.pdf";
import tome13 from "../pdf/TSB VOLUME XIII.PDF";
import tome14 from "../pdf/STUD BOOK VOL XIV 2015-2018.PDF";
import SideBar from '../components/SideBar';
import { getDocs, collection } from "firebase/firestore";
import {db} from '../firebase';
const New = ({language, setLanguage}) => {
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const [docs , setDocs] = useState([
        
    ])
    const fireCall = async ()=>{
        await getDocs(collection(db, "Manuel"))
                .then((querySnapshot)=>{               
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id:doc.id }));
                        setDocs(newData);                
                })
      }
      useEffect(()=>{
        fireCall()
      }, [])
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='publications'/>
        <section>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
            <br />
            <h3>{language === 'fr' ? "Manuel des procédures" :' دليل إجراءات النفاذ إلى المعلومة'}</h3>
            <br />
            <br />
            <br />
            
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem', textAlign:'start' }}><a href={doc.img} style={{ textDecoration:'none', fontSize:'bold', fontSize:'2rem', textAlign:'left'}} target='_blank'>{language === 'fr' ? doc.titreFr : doc.titreAr}, </a>{language === 'fr' ? doc.descriptionFr : doc.descriptionAr}</li>
                    )
                })}
        </ul>
      </div>
      
      </div>
      <SideBar language={language} />
      </div>
        </section>
        
        <Footer language={language} />
        </>
    );
}

export default New;
