import React , {useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router';
import {Link} from 'react-router-dom';
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';

const Cheveaux = ({language, setLanguage}) => {
    const [data , setData] = useState([])
    const [search, setSearch] = useState('')
    const [page , setPage] = useState(1) ;
    const [dataToShow , setDataToShow] = useState([]);
    const [filterData, setFilterData]=useState({
        "sex": "",
        "race": "",
        "robe": "",
        "pays": "",
        "date_N":"",
    })
    const [length, setLength]=useState('')
    const [races, setRaces]= useState([])
    const [robes , setRobes]= useState([])
    const [pays, setPays]= useState([])
    const searchHandler = (e)=>{
        e.preventDefault();
        setSearch(e.target.value)
    }
    function shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
        return true;
      }

    const filterHandler = async(e) => {
        e.preventDefault();
        /*axios.post('https://api.fnarc.tn/cr', filterData).then(res=>{
            setData([...res.data])
            setDataToShow([...res.data])
            console.log(res.data)
          })*/
          let searchedData = []
          console.log(filterData);
          if(!shallowEqual(filterData , {
            "sex": "",
            "race": "",
            "robe": "",
            "pays": "",
            "date_N":"",
        })){
            if(filterData.sex !== ''){
                searchedData = [...data.filter(e=>{
                    return(
                        e[6] === filterData.sex
                    )
                })]
            }
            if(filterData.race !== ''){
                searchedData = [...data.filter(e=>{
                    return(
                        e[9] === filterData.race
                    )
                })]
            }
            if(filterData.robe !== ''){
                searchedData = [...data.filter(e=>{
                    return(
                        e[11] === filterData.robe
                    )
                })]
            }
            if(filterData.pays !== ''){
                searchedData = [...data.filter(e=>{
                    return(
                        e[14] === filterData.pays
                    )
                })]
            }
            if(filterData.date_N !== ''){
                searchedData = [...data.filter(e=>{
                    return(
                        e[10] == filterData.date_N
                    )
                })]
            }
        }
        setFilterData({
            "sex": "",
            "race": "",
            "robe": "",
            "pays": "",
            "date_N":"",
        })
        setData([...searchedData])
          setLength(data.length)
    }

    const paginationHandler = async (e)=>{
            switch (e) {
                case 'next':
                    if(data.length >= (((page) * 10) + 30)){
                        setDataToShow([...data.slice(((page) * 10), (((page) * 10) + 10))])
                    }else{
                        await axios.get(`https://api.fnarc.tn/cheveaux/${data.length}/${data.length + 40}`).then(function(res){
                            setData([...res.data])
                            setDataToShow([...data.slice(((page + 1) * 10), (((page + 1) * 10) + 10))])
                        })
                        console.log(page)
                    }
                    setPage(page + 1)
                    break;
                    case 'prev':
                        setDataToShow([...data.slice(((page ) * 10), (((page) * 10) + 10))])
                        page !== 1 && setPage(page - 1)
                        break;
                        default:
                            break;
                        }
                    }
                    
    const submitHandler =async (e)=>{
        e.preventDefault();

                search !== '' && await axios.get(`https://api.fnarc.tn/cheval/search/${search}`).then(function(res){
                    setData([...res.data])
                    setLength(res.data.length)
                    console.log(res.data)
            }).catch(err=>console.log(err))
            search === '' && apiCall(1,41);
        }
    /*const unique = (t, setT)=>{
        let t1=[]
        t.forEach(e=>{
            if (t1.includes(e)){
                
            }else{
                t1.push(e)
            }
        })
        setT([...t1])
    }*/
    const apiCall = async (begin, end)=>{
        await axios.get(`https://api.fnarc.tn/cheveaux/${begin}/${end}`).then(function(res){
            setData([...data, ...res.data])
        }).catch(
            async e=>{
                await axios.get(`https://api.fnarc.tn/cheveaux/${begin}/${end}`).then(function(res){
                setData([...data, ...res.data])
        }).catch(err => console.log(err))
            }
        )
        await axios.get(`https://api.fnarc.tn/cheveaux/length`).then(function(res){
            setLength(res.data)
        })
        await axios.get(`https://api.fnarc.tn/pays`).then(function(res){
            setPays([...res.data])
        })
        await axios.get(`https://api.fnarc.tn/race`).then(function(res){
            setRaces([...res.data])
        })
        await axios.get(`https://api.fnarc.tn/robe`).then(function(res){
            setRobes([...res.data])
        })
        
    }
    useEffect(() => {
        apiCall(1,41);
    }, []);

    useEffect(() => {
        setDataToShow([...data])
    }, [data]);

let navigate = useNavigate();
return (
    <div>
            <Navbar language={language} setLanguage={setLanguage} active='chevaux'/>
            <section>
            <form onSubmit={submitHandler} className='searchInput'>
                <input type="text" placeholder='Nom du cheval ?' style={{color:'black', fontSize:'2rem', width:'70%', marginRight:'20px'}} onChange={searchHandler} />

            <button type='submit' style={{fontSize:'2rem'}}><SearchIcon style={{fontSize:'2rem'}}/> Rechercher</button>
            </form>
            <br />
            <br />
            <br />
            <br />
            {/*<div className='listTableContainer' style={{ height:'38rem', width: '100%'}}>
         <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {cols.map(col=>{
                                return(
                                    <TableCell key={col.field} style={{color:'white'}} className="tableCell">{col.headerName}</TableCell>
                                );
                            })}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {dataToShow?.map(element=>{
                            let ch = {
                            }   
                            ch.mere = element[2];
                            ch.id = element[3];
                            ch.name = element[4];
                            ch.sex = element[6];
                            ch.father1 = element[0];
                            ch.naissance = element[10]
                            ch.race = element[9]
                            ch.robe = element[11]
                            return ch ;
                        })?.map((row) => (
                            <TableRow key={row.id} >
                                <TableCell className="tableCell" onClick={()=>{handleClick(row.id)}} style={{cursor:'pointer'}}>{row.id}</TableCell>
                                <TableCell className="tableCell">{row.name}</TableCell>
                                <TableCell className="tableCell">{row.sex}</TableCell>
                                <TableCell className="tableCell">{row.naissance}</TableCell>
                                <TableCell className="tableCell">{row.race}</TableCell>
                                <TableCell className="tableCell">{row.robe}</TableCell>
                                <TableCell className="tableCell" onClick={()=>{handleClick(row.father1)}} style={{cursor:'pointer'}}>{row.father1}</TableCell>
                                <TableCell className="tableCell" onClick={()=>{handleClick(row.mere)}} style={{cursor:'pointer'}}>{row.mere}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </div>
                    <div className="pagination">
                        <div className='prev' onClick={()=>paginationHandler('prev')}><KeyboardArrowLeftIcon /></div>
                        <div>{page}</div>
                        <div className='next' onClick={()=>paginationHandler('next')}><KeyboardArrowRightIcon /></div>
                    </div>*/}
                    <p>Nombre total des chevaux: {length}</p>
                    <div style={{display:'flex', gap:'2rem', justifyContent:'center', alignItems:'flex-end', flexWrap:'wrap-reverse'}}>
                    <div>
                    {dataToShow?.map(element=>{
                            let ch = {
                            }   
                            ch.mere = element[2];
                            ch.id = element[3];
                            ch.name = element[4];
                            ch.sex = element[6];
                            ch.father1 = element[0];
                            ch.naissance = element[10]
                            ch.race = element[9]
                            ch.robe = element[11]
                            ch.pays=element[14]
                            ch.pere=element[26]
                            ch.mere = element[27]
                            ch.pereMere = element[28]
                            ch.naisseur = element[31]?.naisseur
                               
                            return ch ;
                        })?.map(e=>{
                        return( 
                            <div className='cheval' key={e.id}>
                                <p>
                                    <Link to={`/cheveaux/${e.id}`} >{e.name}</Link> {e.pays !== 'TN' && `(${e.pays})`}, {e.race}, {e.sex}, {e.robe}, {e.naissance} <Link to={`/pdf/${e.id}`} > <PictureAsPdfIcon fontSize="medium"/> </Link>{/*<PDFDownloadLink document={<Pdf data={e}  />}>Download</PDFDownloadLink> */}<br />
                                   {e?.pere && ' Par :'} <Link to={`/cheveaux/${e?.pere && e.pere[3]}`}>{e.pere && e?.pere[4]}</Link>  {e.pere && e.pere[14] !== 'TN' && `(${e.pere[14]})`} {e.pere && e.pere[9]}
                                   {e?.pere && ' et'} <Link to={`/cheveaux/${e?.mere && e.mere[3]}`}>{e.mere && e?.mere[4]}</Link>  {e.mere && e.mere[14] !== 'TN' && `(${e.mere[14]})`} {e.mere && e.mere[9]}
                                   {e?.pereMere && ' Par :'} <Link to={`/cheveaux/${e?.pereMere && e?.pereMere[3]}`}>{e?.pereMere && e?.pereMere[4]}</Link>  {e.pereMere && e.pereMere[14] !== 'TN' && `(${e.pereMere[14]})`} {e.pereMere && e.pereMere[9]}
                                   <br />Naisseur :{e?.naisseur !== [] ? `${e?.naisseur[0]?.[4] === "Null" ? "" : e?.naisseur[0]?.[4] || ''} ${e?.naisseur[0]?.[2] === "Null" ? "" : e?.naisseur[0]?.[2] || ''} ${e?.naisseur[0]?.[3] === "Null" ? "" : e?.naisseur[0]?.[3] || 'inconnu' }` : 'inconnu'}
                                </p>
                            </div>
                        )
                    })}
                    </div>
                    <Accordion style={{height:'100%', padding:'.5rem'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Typography style={{color :'red'}}>Recherche Multi-Crtières</Typography>
                            </AccordionSummary>
                            <form onSubmit={filterHandler} style={{display:'flex' , gap:'10px', flexDirection:'column', width:'20rem'}}>
                                <Form.Select onChange={(e)=>{ setFilterData({...filterData, sex: e.target.value});}}>
                                    <option>Sexe</option>
                                    <option value='Mâle'>Mâle</option>
                                    <option value='Femelle'>Femelle</option>
                                </Form.Select>
                                {/*filterData.robe.map(e=>{
                                    return (
                                    <div key={e} style={{position:'relative', height:'2rem', backgroundColor:'lightGray', padding:'10px', borderRadius:'5px'}}>
                                        <ClearIcon  style={{ position:'absolute', top:"0", right:'0', cursor:'pointer'}} onClick={()=>{
                                            filterData.robe.filter(element=>{
                                                return(
                                                    element !== e
                                                    )
                                                })
                                            filterData.robe.splice((filterData.robe.findIndex(x=> x===e)), 1)
                                        }}/>
                                        <p style={{ position:'absolute', top:"0", padding:'0'}}> {e}</p>
                                    </div>
                                    )
                                })*/}
                                
                                <Form.Select onChange={(e)=>{ setFilterData({...filterData, robe: e.target.value});}}>
                                    <option>ٌRobe</option>
                                    {robes.map(e=>{
                                        return(
                                            <option key={e[0]} value={e[0]}>{e[1]}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Select onChange={(e)=>{ setFilterData({...filterData, race: e.target.value});}}>
                                    <option>Race</option>
                                    {
                                        races.map(e=>{
                                            return(<option key={e[0]} value={e[0]}>{e[1]}</option>)
                                        })
                                    }
                                </Form.Select>
                                    <input type='text' placeholder='année de naissance' style={{color:'black'}}  name='date_N' onChange={(e)=>{ setFilterData({...filterData, date_N: e.target.value});}}/>
                                <Form.Select onChange={(e)=>{ setFilterData({...filterData, pays: e.target.value});}}>
                                    <option>Pays de Naissance</option>
                                    {
                                        pays.map(e=>{
                                            return(<option key={e[1]} value={e[1]}>{e[0]}</option>)
                                        })
                                    }
                                </Form.Select>
                                <input type='submit' />
                            </form>
                    </Accordion>
                    </div>
                    {/*<div className="pagination">
                        <div className='prev' onClick={()=>paginationHandler('prev')}><KeyboardArrowLeftIcon /></div>
                        <div>{page}/{Math.floor(length / 10)}</div>
                        <div className='next' onClick={()=>paginationHandler('next')}><KeyboardArrowRightIcon /></div>
                                </div>*/}
            </section>
            <footer>
                <Footer language={language} />
            </footer>
        </div>
    );
    
}

export default Cheveaux;
/*
                    */