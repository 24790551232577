/*import React, {useEffect, useState} from 'react';
import {PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import axios from "axios" ;
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text:{
    color:'red' ,
    //borderBottom: '1px solid gray' , 
  },
  textSmall:{
    fontSize:"12px",
    margin:"5px",
  },
  textSmallSmall:{
    fontSize:"08px",
    margin:"5px",
  },
  line:{
    borderLeft:'1px solid gray',
    borderRadius:'20px', 
    marginLeft:'20px',
    padding:'0',
    justifyContent:'space-between'
    
  },
  row:{
    flexDirection:'row',
    gap:"75px"
  },
  col:{
    flexDirection:'column',
    gap:'10px'
  }
});

// Create Document Component
const MyDocument = ({data}) => {
  const [prodPere , setProdPere] = useState([])
  const [prodMere1 , setProdMere1] = useState([])
  const [prodMere2 , setProdMere2] = useState([])
  useEffect(()=>{
    axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.pere?.[3]}`).then(res=>{
                                setProdPere([...res.data])})
    axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.mere?.[3]}`).then(res=>{
                                setProdMere1([...res.data])})
    axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.mere?.[27]?.[2]}`).then(res=>{
                                setProdMere2([...res.data])})
  }, [data.id])
  return(
    <>
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.row}>
        <View style={styles.col}>
        <Text style={styles.text}>{data.name}</Text>
        <Text style={styles.textSmall}>{data.sex ==="F" ? "Femelle" : "Male"}, {data.robe}, Né en {data.naissance}</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.line}>
            <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
            <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>
          </View>
          <View style={styles.col}>
            <View style={styles.line}>
              <View style={styles.row}>
                <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                <View style={styles.line}>
                  <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                  <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>

                </View>
              </View>
              <View style={styles.row}>
                <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>
                <View style={styles.line}>
                  <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                  <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>

                </View>
              </View>
            </View>
            <View style={styles.line}>
              <View style={styles.row}>
                <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                <View style={styles.line}>
                  <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                  <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>

                </View>
              </View>
              <View style={styles.row}>
                <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>
                <View style={styles.line}>
                  <Text style={styles.textSmallSmall}>{data?.pere?.[4]}</Text>
                  <Text style={styles.textSmallSmall}>{data?.mere?.[4]}</Text>

                </View>
              </View>
            </View>
            
          </View>
        </View>
        </View>
        <hr />
        <View style={styles.col}>
          <Text style={styles.text}>{data?.pere?.[4]} produits:</Text>
          {prodPere?.map(e=>{
            return(
                <Text key={e?.[3]} style={styles.textSmall}>{e?.[4]}, {e?.[14]}, {e?.[6]}, {e?.[9]}, {e?.[11]}, {e?.[31]?.naisseur[0][2]}</Text>

            )
          })}

        </View>
        <View style={styles.col}>
          <Text style={styles.text}>{data?.mere?.[4]} produits:</Text>
          {prodMere1?.map(e=>{
            return(
                <Text style={styles.textSmall}>{e?.[4]}, {e?.[14]}, {e?.[6]}, {e?.[9]}, {e?.[11]}, {e?.[31]?.naisseur[0][2]}</Text>

            )
          })}

        </View>
        <View style={styles.col}>
          <Text style={styles.text}>{data?.mere?.[27]?.[4]} produits:</Text>
          {prodMere2?.map(e=>{
            return(
                <Text style={styles.textSmall}>{e?.[4]}, {e?.[14]}, {e?.[6]}, {e?.[9]}, {e?.[11]}, {e?.[31]?.naisseur[0][2]}</Text>

            )
          })}

        </View>
      </View>
    </Page>
  </Document>
  </>
);}
/*const Pdf = () => {
    return (
        <div>
        <PDFDownloadLink
            fileName='test'
            document={<MyDocument />}>
        Click Me
        </PDFDownloadLink>
            
        </div>
    );
}

export default MyDocument;
*/
import React, {useState, useEffect, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import "./style.css";
const VerticalTree = () => {
  const [data, setData] = useState({})
  const [produitPere, setProduitPere]= useState([])
  const [produitMere, setProduitMere]= useState([])
  const [produitMere2, setProduitMere2]= useState([])
  const [produitMere3, setProduitMere3]= useState([])
    const componentRef = useRef();
    const {id} = useParams()
    const apiCall = async ()=>{
      await axios.get(`https://api.fnarc.tn/cheval/${id}`).then(function(res){
          setData(res.data)
      })
    }
    const apiCallProdPere = async ()=>{
       axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.pere?.id}`).then(function(res){
          setProduitPere(res.data)
      })
    }
    const apiCallProdMere = async ()=>{
       axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.mere?.id}`).then(function(res){
          setProduitMere(res.data)
      })
    }
    const apiCallProdMere2 = async ()=>{
       axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.grande_mere_m?.id}`).then(function(res){
          setProduitMere2(res.data)
      })
    }
    const apiCallProdMere3 = async ()=>{
       axios.get(`https://api.fnarc.tn/cheval/search/prod/${data?.grande_mere_mere_m?.id}`).then(function(res){
          setProduitMere3(res.data)
      })
    }
    useEffect( () => {
        apiCall()
      }, []);
    useEffect( () => {
        data.cheval && apiCallProdMere()
        data.cheval && apiCallProdMere2()
        data.cheval && apiCallProdMere3()
      }, [data]);
      return (
         <>
        {data.cheval &&  <div>


         <div style={{ display:'flex' ,justifyContent:'center', width:'100vw', gap:'20px', overflow:'hidden'}}>

        <ReactToPrint
        trigger={() => <button>Télecharger PDF</button>}
        content={() => componentRef.current}
        />
        </div>
        <div id="wrapper" ref={componentRef} style={{padding:'0', overflowX:'hidden !important'}} >
        <img src='../images/pdfHeader.png' style={{width:'100vw', height:'8rem'}} alt='pdfHeader.png' />
        <div className="parent">
          <div className="div1">{data.cheval.nom}{data.cheval.pays && data.cheval.pays !== 'TN' && `(${data.cheval.pays})`},{data?.cheval?.sex} ,{data.cheval.race} <br />{data?.cheval?.pobarb !== 'Null' ? `${data?.cheval?.pobarb.split('T')?.[0].split('-').reverse().join('/')}` : data?.cheval?.date_N}, {data.cheval.robe} </div>
          <div className="div2">{data?.pere?.nom}  {data?.pere.pays && data?.pere.pays !== 'TN' && `(${data?.pere.pays})`} , {data?.pere?.race}</div>
          <div className="div3">{data?.mere?.nom}  {data?.mere.pays && data?.mere.pays !== 'TN' && `(${data?.mere.pays})`}, {data?.mere?.race}</div>
          <div className="div4">{data?.grand_pere_p?.nom}  {data?.grand_pere_p.pays && data?.grand_pere_p.pays !== 'TN' && `(${data?.grand_pere_p.pays})`}, {data?.grand_pere_p?.race}</div>
          <div className="div5">{data?.grande_mere_p?.nom}  {data?.grande_mere_p.pays && data?.grande_mere_p.pays !== 'TN' && `(${data?.grande_mere_p.pays})`}, {data?.grande_mere_p?.race}</div>
          <div className="div6">{data?.grand_pere_m?.nom}  {data?.grand_pere_m.pays && data?.grand_pere_m.pays !== 'TN' && `(${data?.grand_pere_m.pays})`}, {data?.grand_pere_m?.race}</div>
          <div className="div7"> {data?.grande_mere_m?.nom}  {data?.grande_mere_m.pays && data?.grande_mere_m.pays !== 'TN' && `(${data?.grande_mere_m.pays})`}, {data?.grande_mere_m?.race}</div>
          <div className="div8"> {data?.grande_pere_pere_p?.nom}  {data?.grande_pere_pere_p.pays && data?.grande_pere_pere_p.pays !== 'TN' && `(${data?.grande_pere_pere_p.pays})`}, {data?.grande_pere_pere_p?.race}</div>
          <div className="div9"> {data?.grande_mere_pere_p?.nom}  {data?.grande_mere_pere_p.pays && data?.grande_mere_pere_p.pays !== 'TN' && `(${data?.grande_mere_pere_p.pays})`}, {data?.grande_mere_pere_p?.race}</div>
          <div className="div10">{data?.grande_pere_pere_m?.nom}  {data?.grande_pere_pere_m.pays && data?.grande_pere_pere_m.pays !== 'TN' && `(${data?.grande_pere_pere_m.pays})`}, {data?.grande_pere_pere_m?.race}</div>
          <div className="div11"> {data?.grande_mere_pere_m?.nom}  {data?.grande_mere_pere_m.pays && data?.grande_mere_pere_m.pays !== 'TN' && `(${data?.grande_mere_pere_m.pays})`}, {data?.grande_mere_pere_m?.race}</div>
          <div className="div12">{data?.grande_pere_mere_p?.nom}  {data?.grande_pere_mere_p.pays && data?.grande_pere_mere_p.pays !== 'TN' && `(${data?.grande_pere_mere_p.pays})`}, {data?.grande_pere_mere_p?.race}</div>
          <div className="div13">{data?.grande_mere_mere_p?.nom}  {data?.grande_mere_mere_p.pays && data?.grande_mere_mere_p.pays !== 'TN' && `(${data?.grande_mere_mere_p.pays})`}, {data?.grande_mere_mere_p?.race}</div>
          <div className="div14">{data?.grande_pere_mere_m?.nom}  {data?.grande_pere_mere_m.pays && data?.grande_pere_mere_m.pays !== 'TN' && `(${data?.grande_pere_mere_m.pays})`}, {data?.grande_pere_mere_m?.race}</div>
          <div className="div15">{data?.grande_mere_mere_m?.nom}  {data?.grande_mere_mere_m.pays && data?.grande_mere_mere_m.pays !== 'TN' && `(${data?.grande_mere_mere_m.pays})`}, {data?.grande_mere_mere_m?.race}</div>
        </div>
        
  <hr />
         <div>
          
          <h3 style={{borderBottom:'1px solid gray' , width:'25%'}}>Mère: {data?.mere?.nom}</h3>
          <ul>

          {produitMere?.map(element=>{
                            let ch = {
                            }   
                            ch.mere = element[2];
                            ch.id = element[3];
                            ch.name = element[4];
                            ch.sex = element[6];
                            ch.father1 = element[0];
                            ch.naissance = element[10]
                            ch.race = element[9]
                            ch.robe = element[11]
                            ch.pays=element[14]
                            ch.pere=element[26]
                            ch.paysPere=element[26]?.[14]
                            ch.mere = element[27]
                            ch.pereMere = element[28]
                            ch.perePere = element[26]?.[26]
                            ch.mereMere= element[27]?.[27]
                            ch.merePere= element[26]?.[27]

                            return ch ;
                        })?.map(e=>{
                        return(
                           e.id !== data?.cheval?.id && <li key={e.id} style={{fontSize:'8px !important', color:'black'}}>{e.name}{e.pays && e.pays !== 'TN' && `,(${e.pays})`}, né(e) en {e.naissance}, par {e.pere?.[4]}{e?.paysPere && e?.paysPere !== 'TN' && `,(${e?.paysPere})`}</li>
                        )
                    })}
          </ul>
         <hr />
          <h3 style={{borderBottom:'1px solid gray' , width:'25%'}}>Mère2: {data?.grande_mere_m?.nom}</h3>
          <ul>

          {produitMere2?.map(element=>{
                           let ch = {
                          }   
                          ch.mere = element[2];
                          ch.id = element[3];
                          ch.name = element[4];
                          ch.sex = element[6];
                          ch.father1 = element[0];
                          ch.naissance = element[10]
                          ch.race = element[9]
                          ch.robe = element[11]
                          ch.pays=element[14]
                          ch.pere=element[26]
                          ch.paysPere=element[26]?.[14]
                          ch.mere = element[27]
                          ch.pereMere = element[28]
                          ch.perePere = element[26]?.[26]
                          ch.mereMere= element[27]?.[27]
                          ch.merePere= element[26]?.[27]

                          return ch ;
                      })?.map(e=>{
                      return(
                         e.id !== data?.cheval?.id && <li key={e.id} style={{fontSize:'8px !important', color:'black'}}>{e.name}{e.pays && e.pays !== 'TN' && `,(${e.pays})`}, né(e) en {e.naissance}, par {e.pere?.[4]}{e?.paysPere && e?.paysPere !== 'TN' && `,(${e?.paysPere})`}</li>
                      )
                  })}
          </ul>
         <hr />
          <h3 style={{borderBottom:'1px solid gray' , width:'25%'}}>Mère3: {data?.grande_mere_mere_m?.nom}</h3>
          <ul>

          {produitMere3?.map(element=>{
                            let ch = {
                            }   
                            ch.mere = element[2];
                            ch.id = element[3];
                            ch.name = element[4];
                            ch.sex = element[6];
                            ch.father1 = element[0];
                            ch.naissance = element[10]
                            ch.race = element[9]
                            ch.robe = element[11]
                            ch.pays=element[14]
                            ch.pere=element[26]
                            ch.paysPere=element[26]?.[14]
                            ch.mere = element[27]
                            ch.pereMere = element[28]
                            ch.perePere = element[26]?.[26]
                            ch.mereMere= element[27]?.[27]
                            ch.merePere= element[26]?.[27]

                            return ch ;
                        })?.map(e=>{
                        return(
                           e.id !== data?.cheval?.id && <li key={e.id} style={{fontSize:'8px !important', color:'black'}}>{e.name}{e.pays && e.pays !== 'TN' && `,(${e.pays})`}, né(e) en {e.naissance}, par {e.pere?.[4]}{e?.paysPere && e?.paysPere !== 'TN' && `,(${e?.paysPere})`}</li>
                        )
                    })}
          </ul>
         <hr />
         </div>
         <img src='../images/pdfFooter.png' style={{width:'100vw', height:'8rem'}} alt='test.png' />
</div>
         </div>
         }
         
        </>
         
    );
}

export default VerticalTree;
