import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar';
import HistoryIcon from "@mui/icons-material/History";
import TimeLine from "../components/TimeLine";

const New = ({language, setLanguage}) => {
    
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='presentation'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <h3>
          {language === 'fr' ? "Présentation de la FNARC" : "	تقديم المؤسسة الوطنية لتحسين و تجويد الخيل"}
          </h3>
        <br />
        <br />
        <br />
         <h1 className='sectionTitle'>
          {language === 'fr' && <HistoryIcon fontSize='x-large'/> || ''}
          {language === 'fr' ? "Historique" : "	تاريخ المؤسسة الوطنية لتحسين و تجويد الخيل"}
          {language !== 'fr' && <HistoryIcon fontSize='x-large'/> || ''}
          </h1>
          <br />
         <span className='description' style={{fontSize:'2rem'}}>
          {language === 'fr' ? "La FNARC (ex Etablissement des Haras Nationaux) : établissement public créé en 1988" : " المؤسسة الوطنية لتحسين و تجويد الخيل أنشأت في 1988"}
          </span>
          <br />
          <br />
         <TimeLine language={language}/>
              <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem', textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1>{language === 'fr' ? "Missions" : "أنشطة المؤسّسة "}
                  </h1>
                  <br />
                  
                  <p>{language === 'fr' ? "Elaboration, mise en œuvre et suivi des programmes de développement de l’élevage équin" : "	إعداد وتنفيذ ومتابعة برامج تنمية قطاع تربية الخيل"}
                      <br />
                  {language === 'fr' ? "Encadrement et encouragement des éleveurs" : "		تأطير وتشجيع المربين"}
                   <br />
                  {language === 'fr' ? "Identification, réalisation et suivi des actions d’amélioration génétique " : "		تحديد وإنجاز ومتابعة عمليّات التحسين الوراثي لسلالات الخيل"}
                  <br />
                  {language === 'fr' ? "Tenue des livres généalogiques des races équines " : "	مسك دفاتر أنساب الخيول"}
                   <br />
                  {language === 'fr' ? "Organisation, contrôle et suivi de la monte publique  " : "		تنظيم ومراقبة تجويد الخيول للعموم"}
                  <br />
                  {language === 'fr' ? "Participation au développement du sport et du tourisme équestres  " : "		المساهمة في تطوير رياضة الفروسيّة"}
                   <br />
                  {language === 'fr' ? "Représentation du secteur auprès des organisations nationales et internationales." : "	تمثيل القطاع لدى المنظمّات الوطنيّة والدوليّة"}
                   <br />
                   <br />


</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{ textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                  {language === 'fr' ? 'Organisation adminitrative et financière' : '  التنظيم الإداري والمالي '}
                  </h1>
                  
                  <p style={{ textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Tutelle :' : ': الإشراف   '}</span> <br />
                    {language === 'fr' ? 'Ministère de l’Agriculture, des Ressources Hydrauliques et de la Pêche' : '  وزارة الفلاحة والموارد المائية والصيد البحري '}
                      <br />
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Base réglementaire :' : ': الصيغة القانونية   '}</span> <br />
                    {language === 'fr' ? 'Décret N° 1631 de l’année 2003' : '   مؤسسة عمومية لا تكتسي صبغة إدارية  '}
                      <br />
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Direction Générale :' : ': النص القانوني للتنظيم الإداري    '}</span> <br />
                    {language === 'fr' ? '' : '   الأمر عدد 1631 لسنة 2003  '}
                      <br />
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Directions techniques :' : ': الهيكل التّنظيمي   '}</span> <br />
                    {language === 'fr' ? '' : '   الأمر عدد 745 لسنة 2006 المؤرخ في 13 مارس 2006 المتعلق بضبط الهيكل التنظيمي للمؤسسة الوطنية لتحسين وتجويد الخيل '}
                      <br />
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Conseil d’établissement' : ' : إدارة الشؤون الإدارية والمالية  '}</span> <br />
                    {language === 'fr' ? '' : '  مصلحة التصرف في الموارد البشرية والتزويد والخدمات العامة  '}
                    <br />
                      {language === 'fr' ? ' ' : '  مصلحة المالية  '}<br />
                  




</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{ textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                  {language === 'fr' ? 'Principales Activités' : ' الإدارة العامة'}
                  </h1>
                  <br />
                  
                  <p style={{ textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                  {language === 'fr' ? "Organisation de la Monte Publique " : " مصلحة التدقيق الداخلي ومراقبة التصرف   "}
<br /> 
                  {language === 'fr' ? " Identification des Chevaux " : "  مصلحة الإعلامية والكتابة القارة للجنة الصفقات   "} <br />
  
                  {language === 'fr' ? " Enregistrements et gestion de bases de données " : " مكتب الضبط المركزي   "} <br />
                  <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Edition des Documents Officiels' : ' :  إدارة الإنتاج والتربية   '}</span> <br />
                    <span style={{fontWeight:'bold'}}>
                    {language === 'fr' ? 'Organisation des Concours d’Elevage' : '    : الإدارة الفرعية للإستغلال والإنتاج وتضم  '}</span>
                      <br />
                      {language === 'fr' ? '' : ' مصلحة انتاج الخيول العربية  '}
                      <br />
                      {language === 'fr' ? '' : '  مصلحة انتاج الخيول البربرية والأنجليزية '}
                      <br />
                      {language === 'fr' ? ' ' : '   مصلحة إستغلال الضيعة الفلاحية '}<br />
<span style={{fontWeight:'bold'}}>
                  {language === 'fr' ? " Tenue des «Stud Books»" : " : الإدارة الفرعية للنهوض بتربية الخيل وتضم   "}</span> <br />
                  
                  {language === 'fr' ? ' ' : '    مصلحة التسجيل ومسك دفاتر الأنساب والدراسات والتنمية'}<br />
<span style={{fontWeight:'bold'}}>
                  {language === 'fr' ? " " : "  "}</span> <br />





</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem' ,  textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{}}>
                  {language === 'fr' ? " Infrastructure " : " بنية تحتية:   "}
                  </h1>
                  <br />
                  
                  <p style={{}}><span style={{fontWeight:'bold'}}>  
</span> <br />
<ul>

                  
<span style={{fontWeight:'bold'}}> {language === 'fr' ? "haras national Sidi Thabet " : "  الاسطبل الوطني بسيدي ثابت "}  </span> <br />
<span style={{fontWeight:'bold'}}> {language === 'fr' ? "haras national El Battan " : " الاسطبل الوطني بالبطان   "}   </span> <br />
 <span style={{fontWeight:'bold'}}>{language === 'fr' ? "haras national Raccada " : "  الاسطبل الوطني برقادة  "}   </span><br />
<span style={{fontWeight:'bold'}}>{language === 'fr' ? "haras national Meknassy " : "  الاسطبل الوطني بمكناسي  "}  </span><br />
<span style={{fontWeight:'bold'}}>{language === 'fr' ? "haras national Ben Guerden " : "  الاسطبل الوطني ببن قردان  "}  </span><br />
</ul>
</p>
<br />
    <p style={{}}>
<span style={{fontWeight:'bold'}}>{language === 'fr' ? " Stations de monte : 30 stations réparties sur 13 gouvernorats " : " محطات التجويد: 30 محطة موزعة على 13 ولاية  "}  </span><br />



</p>
                </div>
              </div>
        </div>
        
        
        <SideBar language={language} />
        </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;
