import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import loi from "../pdf/مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول الانقليزيّة الأصيلة.pdf"; // import css
import loi2 from "../pdf/مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول البربريّة.pdf"; // import css
import loi3 from "../pdf/مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول العربية الأصيلة.pdf"; // import css
import loi4 from "../pdf/مقرّر يتعلّق بضبط مهام لجنّة سجلّات الأنساب وتركيبتها وطرق سيرها.pdf"; // import css
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SideBar from '../components/SideBar';

const New = ({language, setLanguage}) => {
    const {id} = useParams()
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const [docs , setDocs] = useState([
        {
        link: loi,
        nom:"مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول الانقليزيّة الأصيلة",
        id: getRandomInt(1000)
    },
    {
        link: loi2,
        nom:"مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول البربريّة",
        id: getRandomInt(1000)
    },
    {
        link: loi3,
        nom:"مقرّر يتعلّق بأحكام سجّل الأنساب التونسي للخيول العربية الأصيلة",
        id: getRandomInt(1000)
    },
    {
        link: loi4,
        nom:"مقرّر يتعلّق بضبط مهام لجنّة سجلّات الأنساب وتركيبتها وطرق سيرها",
        id: getRandomInt(1000)
    },
   ]) ;
    const apiCall= async ()=>{
        await axios.get('http://localhost:2000/news').then((res)=>{
             setDocs(res.data.find(e=> e._id === id))
        })
        }
        useEffect(() => {
             apiCall()
           }, []);
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='reglementation'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <h3>Cours</h3>
        <ul>
        {docs.map(doc=>{
            
            return(
                <li style={{ marginTop:'1rem', color:'#004b99'}} key={doc.id}><a target='_blank' href={doc.link} style={{color:'#004b99', textDecoration:'none', cursor:'pointer', display:'flex', alignItems:'center', textAlign:'left'}}><p><PictureAsPdfIcon /> {doc.nom}</p></a></li>
            );
            })}
        </ul>
        </div>
        <SideBar language={language} />
        </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;
