import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
const InformationsGeneral = ({dataFull ,language, setLanguage}) => {
    const today = new Date();
    const navigate = useNavigate();
    const [loading , setLoading]= useState(true)
    useEffect(() => {
        dataFull.cheval && setLoading(false)
    }, [dataFull]);
    return (<>
      { (!loading) && <> <div style={{ textAlign:'start'}}>
            <p>Données à la date de : {`${today.getDate() - 1 }/ ${today.getMonth() + 1} /${today.getFullYear()}`}</p>
            <div style={{ display:'flex', gap:'5vw', flexWrap:"wrap"}}>
            <div style={{minWidth:'45vw'}}>

            <h4 style={{backgroundColor:'#004B99', color:'white', padding:'10px'}}>IDENTITÉ ET STUD-BOOK</h4>
            <div style={{backgroundColor:'#E8E8E8'}}>

            <h2 style={{fontWeight:'bold'}}>{dataFull?.cheval?.nom} {dataFull?.cheval?.pays !== 'TN' && `(${(dataFull?.cheval?.pays)})` || ''}:</h2>
            <p>
                N° SIRE : {dataFull?.cheval?.id}<br />
                Race :{dataFull?.cheval?.race}{dataFull?.cheval?.poarab !== "Null"  && dataFull?.cheval?.poarab !== 100 &&  `(${dataFull?.cheval?.poarab}%)` || dataFull?.cheval?.poarab === "Null" && ""},<br />
                Robe :{dataFull?.cheval?.robe}, <br />
                Date de naissance :{dataFull?.cheval?.pobarb !== 'Null' ? `${dataFull?.cheval?.pobarb.split('T')?.[0].split('-').reverse().join('/')}` : dataFull?.cheval?.date_N}, <br />
                Pays de naissance : {dataFull?.cheval?.paysFull}
            </p>
            <hr />
            <h2 style={{cursor:'pointer', fontWeight:'bold'}} onClick={()=>{navigate(`/cheveaux/${dataFull.pere.id}`)}}>Père : {dataFull.pere?.nom} {`${dataFull?.pere?.pays !== 'TN' && dataFull?.pere?.pays &&(`(${dataFull?.pere?.pays})`) || ''} `}</h2> <p>{dataFull.pere?.race !== 'Null' && dataFull.pere?.race }{dataFull?.pere?.poarab !== "Null"  && dataFull?.pere?.poarab !== 100 &&  `(${dataFull?.pere?.poarab}%)` || dataFull?.pere?.poarab === "Null" && ""},{dataFull.pere?.robe || ''}, {dataFull?.pere?.pobarb !== 'Null' ? `${dataFull?.pere?.pobarb?.split('T')?.[0].split('-').reverse().join('/')}` : dataFull?.pere?.date_N}</p>
            <hr />
            <h2 style={{cursor:'pointer', fontWeight:'bold'}} onClick={()=>{navigate(`/cheveaux/${dataFull.mere.id}`)}}>Mère : {dataFull.mere?.nom} {`${dataFull?.mere?.pays !== 'TN' && (`(${dataFull?.mere?.pays})`) || ''} `}</h2> <p>{dataFull.mere?.race}{dataFull?.mere?.poarab !== "Null"  && dataFull?.mere?.poarab && dataFull?.mere?.poarab !== 100 &&  `(${dataFull?.mere?.poarab}%)` || dataFull?.mere?.poarab === "Null" && ""},{dataFull.mere?.robe}, {dataFull?.mere?.pobarb !== 'Null' ? `${dataFull?.mere?.pobarb?.split('T')?.[0].split('-').reverse().join('/')}` : dataFull?.mere?.date_N }</p>
            <hr />
            <h2 style={{cursor:'pointer', fontWeight:'bold'}} onClick={()=>{navigate(`/cheveaux/${dataFull.grand_pere_m.id}`)}}>Père de Mère : {dataFull.grand_pere_m?.nom} {`${dataFull?.grand_pere_m?.pays !== 'TN' && `(${dataFull?.grand_pere_m?.pays})` || ''}`}</h2> <p>{dataFull.grand_pere_m?.race}{dataFull?.grand_pere_m?.poarab !== "Null"  && dataFull?.grand_pere_m?.poarab !== 100 &&  `(${dataFull?.grand_pere_m?.poarab}%)` || dataFull?.grand_pere_m?.poarab === "Null" && ""},{dataFull.grand_pere_m?.robe}, {dataFull?.grand_pere_m?.pobarb !== 'Null' ? `${dataFull?.grand_pere_m?.pobarb?.split('T')?.[0].split('-').reverse().join('/')}` : dataFull?.grand_pere_m?.date_N}</p>
            </div>
            </div>
            
            <div style={{backgroundColor:'#E8E8E8', minWidth:"45vw"}}>
            <h2 style={{fontWeight:'bold'}}>Naisseur :</h2>
             <p>{dataFull?.cheval?.naisseur !== [] ? `${dataFull?.cheval?.naisseur[0]?.[4] === "Null" ? "" : dataFull?.cheval?.naisseur[0]?.[4] || ''} ${dataFull?.cheval?.naisseur[0]?.[2] === "Null" ? "" : dataFull?.cheval?.naisseur[0]?.[2] || ''} ${dataFull?.cheval?.naisseur[0]?.[3] === "Null" ? "" : dataFull?.cheval?.naisseur[0]?.[3] || 'inconnu' }` : 'inconnu'}</p>
             <hr />
            <h2 style={{fontWeight:'bold'}}>STUD Book Naissance :</h2>
            <p>{dataFull?.cheval?.studBook !== 'Null' && dataFull?.cheval?.studBook} {dataFull?.cheval?.volSTB !== 'Null' && `volume :${dataFull?.cheval?.volSTB}` || 'inconnu'}</p>
            <hr />
            <h2 style={{fontWeight:'bold'}}>Muni d'un transpondeur électronique :</h2>
            <p>{dataFull?.cheval?.puce ? 'oui ' : 'non'}</p> 
            <hr />
            <h2 style={{fontWeight:'bold'}}>Cet équidé est génotypé ADN :</h2>
            <p>{dataFull?.cheval?.genotyp === 'NULL' ? 'Non' : 'oui'}</p>
            <hr />
            <h2 style={{fontWeight:'bold'}}>Document D'Accompagnement Validé : </h2><p>{dataFull?.cheval?.dateVal !== 'Null' ? 'oui' :'non'}</p>
            </div>
            </div>
        </div></>}
        {
            loading && <p>Loading...</p>
        }
        </>
    );
}

export default InformationsGeneral;
