import React from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import loi2003 from "../pdf/catalogue vente Mixte 2023 final recorrigé AMpdf (3).PDF"; 

const New = ({language, setLanguage}) => {

    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='publication'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
         
            <img style={{width:'80vw', aspectRatio:'16/9'}} src={`../images/cataloguemixte.png`} alt={"catalogue 2023"} />
            <br />
            <br />
            <br />
            <div style={{backgroundColor:'#eee', padding:'2rem', borderRadius:'2rem'}}>
                <h1 style={{backgroundColor:'#004B99', color:'white', padding:'8px', width:'100%', margin:'0', borderRadius:'1rem'}}>{"Vente Mixte de Chevaux Pur Sang Arabe et Pur Sang Anglais"}</h1>
                <br />
                <br />
                <br />
                <p style={{color:'#004B99 !important'}}>{language === 'fr' ? "Vente Mixte de Chevaux Pur Sang Arabe et Pur Sang Anglais" :"Vente Mixte de Chevaux Pur Sang Arabe et Pur Sang Anglais" } <a href={loi2003} target='_blank'><PictureAsPdfIcon /> </a> </p>
            </div>
            </div>
            <SideBar language={language} />
            </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;