import React from 'react';
import { Link } from 'react-router-dom';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Info = ({ details}) => {
    return (
        <div style={{textAlign:'start', backgroundColor:'#E8E8E8' , display: 'flex',justifyContent:'space-between'}}>
        <AccordionDetails>
          <Typography style={{color : '#1c1918'}}>
          <span style={{fontWeight:'bold', color :'#004B99'}}>{details?.cheval?.nom}</span>{details?.cheval?.pays !== "TN" ? `(${details?.cheval?.pays})` : ""}, {details?.cheval?.race}{details?.cheval?.poarab !== "Null"  && details?.cheval?.poarab !== 100 &&  `(${details?.cheval?.poarab}%)` || details?.cheval?.poarab === "Null" && ""}, {details?.cheval?.categPouli !== 'Null'   &&`${details?.cheval?.sex ==='Femelle' ? 'Poulinière' :'Etalon'  }`} {`${details?.cheval?.death !== 'Null' ? `,mort(e) le ${details?.cheval?.death.split('T')[0]?.split('-')?.reverse().join('/')}` : ''}`}
            {details.cheval?.reforme && details.cheval?.reforme !== 'Null' ? `réformé(e) le ${details.cheval?.reforme}` : ''}
          <br /> {details?.cheval?.sex}, {details?.cheval?.robe}, né(e) en {details?.cheval?.date_N} <br />
          Père : <Link to={`/cheveaux/${details?.pere?.id}`} >{details?.pere?.nom}</Link> {details?.pere?.pays !== "TN" ? `(${details?.pere?.pays})` : ""} , {details?.pere?.race}, {details?.pere?.robe !== 'Null' && details?.pere?.robe}<br />
          Mère : <Link to={`/cheveaux/${details?.mere?.id}`} >{details?.mere?.nom}</Link> {details?.mere?.pays !== "TN" ? `(${details?.mere?.pays})` : ""} , {details?.mere?.race} {details?.mere?.robe !== 'Null' && `,${details?.mere?.robe}`}<br />
          Père de  Mère : <Link to={`/cheveaux/${details?.grand_pere_m?.id}`} >{details?.grand_pere_m?.nom}</Link> {details?.grand_pere_m?.pays !== "TN" ? `(${details?.grand_pere_m?.pays})` : ""}, {details?.grand_pere_m?.race !== 'Null' && details?.grand_pere_m?.race} {details?.grand_pere_m?.robe !== 'Null' && `,${details?.grand_pere_m?.robe}`}<br />
          </Typography>
        </AccordionDetails>
        <img src='../images/fnarc.png' alt="logFnarc" style={{ width:'10%', height:'10%'}}/>
        </div>
    );
}

export default Info;
