import React from "react";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
//import 'bootstrap/dist/css/bootstrap.css';
//icons
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import FestivalIcon from "@mui/icons-material/Festival";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import HistoryIcon from "@mui/icons-material/History";
import TimeLine from "../components/TimeLine";
//components
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Card from "../components/Card";
import CardAct from '../components/Actuality'
import TeamMember from "../components/TeamMember";
import Footer from "../components/Footer";
import CardV2 from "../components/CardV2";
import BackgroundVideoHeader from "../components/backgroundVideoHeader";
import { Link } from "react-router-dom";
import SideBar from "../components/SideBar";
import CarrousselSwipeJs from "../components/CarrousselSwipeJs";

const Home = ({ actualit, stats, toTop, toTopHandler, setToTop, language , setLanguage }) => {
  const [actuality, setActuality]= useState([{"_id":"63e916ca1d59ef276cb28bab","Titre":"News 2","Description":"قام الطاقم البيطري بالمؤسسة الوطنية لتحسين و تجويد الخيل بعملية ناجحة لاستئصال سرطان بمبيض فرس","Type":"News","CreateAt":"2023-02-12T16:41:46.559Z","__v":0,"Img":"../images/transfert-photos-canon-mai-2017-052-scaled.jpg"},{"_id":"63f23c260c095216d690f2b1","Titre":"présentation fnarc","Description":"présentation fnarc","vd":"","Type":"News","CreateAt":"2023-02-19T15:11:34.883Z","__v":0,"Img":"../video/Présentation FNARC.mp4"}]);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 700 ? setToTop(true) : setToTop(false);
    });
  }, []);

  const data = [
    {
      title: "Elevage chevaux",
      description:
        "développement de l'élevage équin en Tunisie et encadrement et encouragements des éleveurs",
      icon: <VerticalAlignTopIcon />,
      Img: "../images/transfert-photos-canon-mai-2017-541-scaled.jpg",
    },
    {
      title: "Amélioration Génétique",
      description:
        "Suivi des actions d’amélioration génétique et Identification des chevaux",
      icon: <VaccinesIcon />,
      Img: "../images/transfert-photos-canon-mai-2017-052-scaled.jpg",
    },
    {
      title: "Développemnt des sports équestres",
      description:
        "Participation au développement des sports équestres et du tourisme équestre ",
      icon: <BedroomBabyIcon />,
      Img: "../images/IMG_1648.JPG",
    },
  ];
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
  const historique = useRef(null);
  const mission = useRef(null);
  const banner = useRef(null);
  const staff = useRef(null);
  const contact = useRef(null);
  const [gallery, setgallery] = useState([
    {
      id:'11',
      img: '../images/transfert.png',
      title: 'Actualité',
      descFr:"transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
      descAr:'الفريق البيطري بالمؤسسة الوطنية لتحسين و تجويد الخيل  ينجز بنجاح أول عملية نقل أجنة عند الخيول بتونس',
      link:''  ,
    },
    {
      id:'1',
      img: '../images/emb.jpg',
      title: 'Actualité',
      descFr:"Collecte et transfert d'embryon équin : une premiére au haras national de Sidi Thabet",
      descAr:' إنجاز أول عملية نقل جنين عند الخيول بالإسطبلات الوطنية بسيدي ثابت',
      link:'',
    },
    {
      id:'2',
      img: '../images/semence.jpg',
      title: 'Actualité',
      descFr:"Liste des étalons disponibles en semence congelée",
      descAr:' قائمة الفحول المتوفرة للتجويد بواسطة البذور المجمدة',
      link:'',
    },
    {
      id:'3',
      img: '../images/Image_7.jpeg',
      title: 'Actualité',
      descFr:"Réception du centre des biothechnlogies de la reproduction chez les équidés et démarrage de son activité",
      descAr:'إنطلاق نشاط مركز تقنيات التناسل الحديثة عند الخيول بسيدي ثابت',
      link:'',
    },
  ]);
  const [members, setMembers] = useState();
  const [avi , setAvi] = useState({
    ar:' مرحبا بكم على الموقع الجديد للمؤسسة موقع في طور الإنشاء',
    fr:'Bienvenue sur le nouveau site de la FNARC - site en cours de développement'
  })
  const apiCall = async (rub, fn) => {
    await axios.get(`http://localhost:2000/${rub}`).then((res) => {
      fn(res.data);
      console.log(rub, res.data);
    });
  };
  useEffect(() => {
    apiCall("galerie", setgallery);
    apiCall("member", setMembers);
    console.log(members);
  }, []);
  return (
    <>
      <Navbar language={language} setLanguage={setLanguage} active='accueil' />
      <div className="master-container">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="row margin-bottom">
                <div className="col-sm-12 margin-top">
                  <Link to='/news'><h1 className="sectionTitle" style={{textAlign:`${language === 'fr' ? 'left' : 'right' }`}}>{language ===  'fr' ? "Actualités" : "المستجدات"}</h1></Link>
                    <Carousel className="carousel">
                    {

                        actualit?.map(e=>{
                        return(
                              <Carousel.Item key={e?.id}>
                              <Link to={`/news/${e?.id}`}>

                              <span style={{color:'white',fontSize:'3rem', display:'flex',justifyContent:'center', alignItems:'end' ,padding:'4rem',height:'50%', width:'100%', position:'absolute', bottom:'0', backgroundColor:'rgba(0, 0, 0, 0.4)'}}>{language === 'fr' ? e?.descriptionFr : e?.descriptionAr || '' }</span>
                              <img
                              className="d-block w-100 newsImg"
                              src={`${e.img}`}
                              alt={e.titre}
                              
                              style={{objectFit:'cover'}}
                              />
                              </Link>
                              </Carousel.Item>
                              
                        )
                        })
                    }
                    </Carousel>
                    <div className="margin-top" style={{display:'flex' , flexDirection:`${language === 'fr' ? 'row' : 'row-reverse'}`}}>
                      <marquee width="90%" direction={language === 'fr' ? "left" : 'right'} scrollamount="10" height="100px" style={{fontSize:'2rem', height:"44px", color:"white", backgroundColor:"#5b7a99",display:"flex", alignItems:"center"}}>
                      {language === 'fr' ? avi.fr : avi.ar}
                      </marquee>
                      <Link to='/news' style={{textDecoration:"none"}}>
                        <h2 style={{ color:"white", backgroundColor:"#004B99", padding:"1rem", width:"121px", textAlign:"center"}}>{language === "fr" ? "Important" : "هام"}</h2>
                      </Link>
                    </div>
                </div>
              </div>
              <div className="row margin-bottom">
                <h3 style={{textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>  {language === 'fr' ? 'En savoir plus' : ' للمزيد من المعلومات'}</h3>
                <br />
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="col-content accueil-bloc-1">
                    <div className="link-brd text-center">
                      {" "}
                      <div>
                        <a
                          className="title head_title mini-margin-bottom"
                          href=""
                        >
                          {language === 'fr' ? 'Identifier mon cheval' : ' تعريف  الحصان '}&nbsp;
                        </a>
                      </div>
                      <div className="image">
                        <a href="">
                          <img
                          style={{height:'25rem', objectFit:'cover', width:'100%'}}
                            alt=""
                            src="../images/Image.jpeg"
                          />
                        </a>
                      </div>
                      <div className="texte" style={{fontSize:'3rem !important'}}>
                        {language === 'fr' ? "Connaitre les étapes de l'identification officelle des chevaux" : "التعرف علـي مراحل تعريف الخيول حسب التراتيب الرسمية"}
                      
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="col-content accueil-bloc-1">
                    <div className="link-brd orange text-center">
                      {" "}
                      <div>
                        <a
                          className="title head_title orange mini-margin-bottom"
                        >
                          
                          {language === 'fr' ? 'Réussir la monte' : 'نجاح تجويد الفرس'}
                        </a>
                      </div>
                      <div className="image">
                        <a>
                          <img
                          style={{height:'25rem', objectFit:'cover' , width:'100%'}}
                            alt=""
                            src="../images/poulain.PNG"
                          />
                        </a>
                      </div>
                      <div className="texte" style={{fontSize:'3rem !important'}}>
                      {language === 'fr' ? "Choix de l'étalon et Optimisation de la fertilité de la jument" : "انتقاء الفحل و تحقيق أفضل خصوبة للفرس"}
                      
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="col-content accueil-bloc-1">
                    <div className="link-brd text-center">
                      {" "}
                      <div>
                        <a
                          className="title head_title mini-margin-bottom"
                          href=""
                          >
                          {language === 'fr' ? 'Valorisation des chevaux' : 'تثمين الخيول'}
                        </a>
                      </div>
                      <div className="image">
                        <a>
                          <img
                          style={{height:'25rem', objectFit:'cover' , width:'100%'}}
                          alt=""
                          src="../images/end.PNG"
                          />
                        </a>
                      </div>
                      <div className="texte" style={{fontSize:'3rem !important'}}>
                      {language === 'fr' ? 'Produire pour satisfaire les besoins des utilisateurs' : 'انتاج يستجيب لما تتطلبه مجالات استعمال الخيول'}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem', textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1>{language === 'fr' ? "Missions" : "مهام المؤسّسة "}
                  </h1>
                  <br />
                  
                  <p>{language === 'fr' ? "Elaboration, mise en œuvre et suivi des programmes de développement de l’élevage équin" : "	إعداد وتنفيذ ومتابعة برامج تنمية قطاع تربية الخيل"}
                      <br />
                  {language === 'fr' ? "Encadrement et encouragement des éleveurs" : "		تأطير وتشجيع المربين"}
                   <br />
                  {language === 'fr' ? "Identification, réalisation et suivi des actions d’amélioration génétique " : "		تحديد وإنجاز ومتابعة عمليّات التحسين الوراثي لسلالات الخيل"}
                  <br />
                  {language === 'fr' ? "Tenue des livres généalogiques des races équines " : "	مسك دفاتر أنساب الخيول"}
                   <br />
                  {language === 'fr' ? "Organisation, contrôle et suivi de la monte publique  " : "		تنظيم ومراقبة تجويد الخيول للعموم"}
                  <br />
                  {language === 'fr' ? "Participation au développement du sport et du tourisme équestres  " : "		المساهمة في تطوير رياضة الفروسيّة"}
                   <br />
                  {language === 'fr' ? "Représentation du secteur auprès des organisations nationales et internationales." : "	تمثيل القطاع لدى المنظمّات الوطنيّة والدوليّة"}
                   <br />
                  {language === 'fr' ? "élaboration et mise en oeuvre de la politique de l'élevage des équidés en Tunisie" : " "}
                   <br />


</p>
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="partenaires" >
                      <div className="head_title" style={{fontSize:'2rem', textAlign:"center"}}><Link to='/concours' style={{color:'white', textDecoration:'none'}}>{language === 'fr' ? "Concours d'élevage" : "مناظرات تربية الخيول"}</Link></div>
                    <div className="main"style={{textAlign:'center', backgroundColor:'#eaeaea', minHeight:'340px'}}>
                      <img
                      src="../images/WP_20170420_15_43_11_Pro.jpg"
                      alt="excelence"
                      style={{borderRadius:'50%', height:'20rem', width:'20rem',objectFit:'cover' ,marginBottom:'20px'}}
                      />
                       <p>{language === 'fr' ? "Outil d'amélioration de nos races et d'encouragement de l'élevage" : "أداة لتحسين سلالات الخيول و التشجيع علـي تربيتها"}
                     </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="partenaires" >
                      <div className="head_title" style={{fontSize:'2rem', textAlign:"center"}}><Link to='presentation' style={{color:'white', textDecoration:'none'}} onClick={()=>{window.scrollTo(0,1500)}}>{language === 'fr' ? "Haras Nationaux" : "الاسطبلات الوطنية"}</Link></div>
                    <div className="main"style={{textAlign:'center', backgroundColor:'#eaeaea', minHeight:'340px'}}>
                      <img
                      src="../images/9X1B1107.JPG"
                      alt="excelence"
                      style={{borderRadius:'50%', height:'20rem', width:'20rem' ,marginBottom:'20px'}}
                      />
                      <p>{language === 'fr' ? "Cinq Haras au service du développement de l'élevage" : "خمس اسطبلات وطنية في خدمة تنمية تربية الخيل"}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="partenaires" >
                    <div className="head_title" style={{fontSize:'2rem', textAlign:"center"}}><Link to='/programmeelevage' style={{color:'white', textDecoration:'none'}}>{language === 'fr' ? "Programmes d'élevage" : "برامج انتاج الخيول"}</Link></div>
                    <div className="main"style={{textAlign:'center', backgroundColor:'#eaeaea', minHeight:'340px'}}>
                      <img
                      src="../images/P1000682.jpg"
                      alt="excelence"
                      style={{borderRadius:'50%', height:'20rem', width:'20rem',objectFit:'cover' ,marginBottom:'20px'}}
                      />
                      <p>{language === 'fr' ? "Stratégies d'amélioration par race adaptées aux contexte socio-économique" : "برامج للتحسين الوراثي لمختلف السلالات"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <SideBar language={language} />
          </div>
        </div>
      </div>
      <Footer language={language} />
    </>
  );
};

export default Home;
/*<div>
         {/* <div className='fnarc'>
               <img src="/images/fnarc.png" alt="FNARC" className='fnarcimg'/>
               <h1>La Fondation Nationale d’Amélioration de la Race Chevaline</h1>
               <img src="/images/tunisie.png" alt="TN" />

    </div>}
    <Navbar language={language} setLanguage={setLanguage} historique={historique} mission={mission} staff={staff} contact={contact} gallery={gallery}/> 
    <section style={{padding:'0'}}>
        <BackgroundVideoHeader />
    </section>
    <Header ref={banner}/>
   
    <section ref={historique} className="historique">
         <h1 className='sectionTitle'><HistoryIcon fontSize='x-large'/> Historique</h1>
         <p className='description'>La FNARC (ex Etablissement des Haras Nationaux) : établissement public créé en 1988</p>
         <TimeLine />
    </section>
    <section className='banner'>
         <h1 className="sectionTitle" style={{color:'white'}}>L’élevage équin en Tunisie: de race Barbe ou Arabe-barbe,<br /> chevaux Arabes, PS Anglais…

</h1>
         <a href="#contact" className="button">Contactez Nous</a>
    </section>
    <section ref={mission} className="missions">
         <h1 className='sectionTitle'>Missions</h1>
         <p className='sectionDescription'>Elaboration, mise en œuvre et suivi des programmes de développement de l’élevage équin , Encadrement et encouragement des éleveurs, Identification, réalisation et suivi des actions d’amélioration génétique, Tenue des livres généalogiques des races équines Organisation, contrôle et suivi de la monte publique Participation au développement du sport et du tourisme équestres, Représentation du secteur auprès des organisations nationales et internationales.</p>
        <div className="flex">
 {
   data.map(e=>{
     return(
       <CardV2 data={e}/>
     )
   })
 }
        </div>
    </section>
    <section ref={gallery} className="gallery">
        <h1 className="sectionTitle">L’élevage équin en Tunisie</h1>
        <Carousel className="carousel">
        {

             gallery?.map(e=>{
             return(
                  <Carousel.Item key={e._id}>

                  <img
                  className="d-block w-100 newsImg"
                  src={`http://localhost:2000${e.Img}`}
                  alt={'news'}
                  loading='lazy'
                  style={{objectFit:'fill'}}
                  />
                  </Carousel.Item>
                  
             )
             })
        }
        </Carousel>
    </section>
    <section className="numbers">
             <div className="stat festival">
                  <div className="statIcon">
                       <FestivalIcon />
                  </div>
                  <div className="statNumber">
                       {stats.festival}
                  </div>
                  <div className="statName">
                       Festivals
                  </div>
             </div>
             <div className="stat client">
                  <div className="statIcon">
                       <SentimentVerySatisfiedIcon />
                  </div>
                  <div className="statNumber">
                       {stats.client}
                  </div>
                  <div className="statName">
                       Clients
                  </div>
             </div>
             <div className="stat revenu">
                  <div className="statIcon">
                       <MonetizationOnIcon />
                  </div>
                  <div className="statNumber">
                       {stats.revenu}
                  </div>
                  <div className="statName">
                       Revenu
                  </div>
             </div>
             <div className="stat prix">
                  <div className="statIcon">
                       <MilitaryTechIcon />
                  </div>
                  <div className="statNumber">
                       {stats.prix}
                  </div>
                  <div className="statName">
                       Prix Gagnés
                  </div>
             </div>
             
    </section>
    <section ref={staff} className="staff">
        <h1 className='sectionTitle'>Notre équipe</h1>
        <div className="members flex">
             <TeamMember nom='Mr.Abdelhak' poste='Directeur|FNARC' img='./images/CEO-1.png' />
             <TeamMember nom='Membre 2' poste='Membre|FNARC' img='./images/member2-1.png' />
             <TeamMember nom='Membre 3' poste='Membre|FNARC' img='./images/membre3.png' />
        </div>
    </section>
    <section className="section6" id="rendez-vous">
 <div className="container" id='contact'>
   <div className="row ">
     <div className="col-sm-6 location">
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.9944802245755!2d10.034826315559538!3d36.91439636896229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2d2fff00dfb9f%3A0xe5129d781660dd5e!2sFNARC!5e0!3m2!1sfr!2stn!4v1672407083205!5m2!1sfr!2stn" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />                      </div>
   <div className="col-sm-6">
       {/* FORM }
       <form method="post">
         <div className="form-group form-visiteur">
           <input className="form-control" type="text" id="name" placeholder="Enter name" required />
         </div>
         <div className="form-group">
           <input className="form-control" type="email" id="email" placeholder="Your Email" required />
         </div>
         <div className="form-group">
           <input className="form-control" type="text" id="phone-number" placeholder="phone-number" required />
         </div>
         <br />
         <button className="button-34" onclick="return confirm('La secretaire va vous contacter pour la confirmation')">Submit</button>
       </form>
     </div>
   </div>
 </div>
</section>
 <footer>
        <Footer language={language} />
 </footer>
 <div className={toTop=== true ? "toTop active" : "toTop"} onClick={toTopHandler}>
        <ArrowCircleUpIcon />
 </div>
 </div>
*/
