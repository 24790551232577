import React, {useState, useEffect} from 'react';
import SideBar from '../components/SideBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import tom from '../pdf/LE  CHEVAL  BARBE  FNARC.pdf'
const New = ({language, setLanguage}) => {
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
           const [docs , setDocs] = useState([
            {
                link:tom,
                nomFr:"LE  CHEVAL  BARBE  En TUNISIE",
                nomAr:"الخيول البربرية بتونس  ",
                id:getRandomInt(10000000000),
                number:'01'
            },
        ])
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='activite'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
            <br />
            <h3>{language === 'fr' ? "CONCOURS D'ÉLEVAGE" :' مناظرات تربية الخيول'}</h3>
            <br />
            <br />
            <br />
            
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem', textAlign:'start' }}><a href={doc.link} style={{ textDecoration:'none', fontSize:'2rem', textAlign:'left'}} target='_blank'>{language === 'fr' ? doc.nomFr : doc.nomAr}</a></li>
                    )
                })}
        </ul>
      </div>
      </div>
      <SideBar language={language} />
      </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;
