import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate , Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
const Reproduction = ({id, dataFull}) => {
    const [produit , setProduit]= useState([])
    const [data, setData] =useState()
    const [races, setRaces] =useState()
    const [robes, setRobes] =useState()
    const [annee, setAnnee]=useState({
        "anne":'',
        "sexe":'',
        "annee1":'',
        "annee2":'',
        "race":'',
        "robe":'',
        "etalon":'',
    })
    const [loading , setLoading]= useState(true)
    const today = new Date();
    useEffect(() => {
        axios.get(`https://api.fnarc.tn/cheval/search/prod/${id}`).then(res=>{
            setProduit([...res.data]);
            setData([...res.data])
            setLoading(false)
        }).catch(err=>console.log(err))
        axios.get(`https://api.fnarc.tn/race`).then(res=>{
            setRaces([...res.data])
        }).catch(err=>console.log(err))
        axios.get(`https://api.fnarc.tn/robe`).then(res=>{
            setRobes([...res.data])
        }).catch(err=>console.log(err))

        window.scrollTo({
            top:350,
            behavior : 'smooth' ,
        })
    }, []);
    useEffect(() => {
        axios.get(`https://api.fnarc.tn/cheval/search/prod/${id}`).then(res=>{
            setProduit([...res.data]);
            setData([...res.data])
            setLoading(false)
        })
        axios.get(`https://api.fnarc.tn/race`).then(res=>{
            setRaces([...res.data])
        }).catch(err=>console.log(err))
        axios.get(`https://api.fnarc.tn/robe`).then(res=>{
            setRobes([...res.data])
        }).catch(err=>console.log(err))
        
    }, [id]);
    const navigate =useNavigate();
    function shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
        return true;
      }
    
    const searchAnneeHandler = async (e)=>{
        e.preventDefault();
        /*if(annee==''){
            await axios.get(`https://api.fnarc.tn/cheval/search/prod/${id}`).then(res=>setProduit([...res.data]))
            setLoading(false)
        }
        else{ await axios.get(`https://api.fnarc.tn/cheval/search/prod/${id}/${annee}`).then(res=>{
            setProduit([...res.data])
            setLoading(false)
            console.log(res.data)
        })
        
    }
    */
   console.log(annee);
   let searchedData = [] ;
    if(!shallowEqual(annee , {
        "anne":'',
        "sexe":'',
        "annee1":'',
        "annee2":'',
        "race":'',
        "robe":'',
        "etalon":'',
    })){
        
        //annee.anne !== '' ? searchedData = [...produit.filter(e=> e[10] == annee.anne)] :   searchedData = [...produit.filter(e=> e[10] >= annee.annee1 && e[10] <= annee.annee2)] || produit  ;
        if(annee.sexe !== ''){
            searchedData = [...produit.filter(e=>{
                return(
                    e[6] === annee.sexe
                )
            })]
        }
        if(annee.race !== ''){
            searchedData = [...produit.filter(e=>{
                return(
                    e[9] === annee.race
                )
            })]
        }
        if(annee.robe !== ''){
            searchedData = [...produit.filter(e=>{
                return(
                    e[11] === annee.robe
                    )
                })]
            }
            if(annee.etalon !== ''){
                searchedData = [...produit.filter(e=>{
                    return(
                    e?.[26]?.[4]?.toLowerCase()?.includes(annee.etalon?.toLowerCase()) || e?.[27]?.[4]?.toLowerCase()?.includes(annee.etalon?.toLowerCase())
                    )
                })]
            }
            if(annee.anne !== ''){
                searchedData = [...produit.filter(e=>{
                    return(
                    e[10] == annee.anne
                )
            })]
        }else if(annee?.annee1 !== '' && annee?.annee2 !== ''){
            let x = []
            for (let index = annee.annee1; index <= annee.annee2; index++) {
                
                x = [...produit.filter(e=>{
                    return(
                    e[10] == index
                )
            }
            )]
            searchedData = [...searchedData , ...x]
                
            }
        }
        
       
    }else{
        searchedData = [...data]
    }
    setAnnee({
        "anne":'',
        "sexe":'',
        "annee1":'',
        "annee2":'',
        "race":'',
        "robe":'',
        "etalon":'',
    })
    setProduit([...searchedData])
    }
    const searchHandler = async(e)=>{
        setAnnee({...annee , [e.target.name]:e.target.value })
    }
return(<>
    {(produit?.length > 0 && !loading) && <>
        <br />
        <br />
        <br />
        <form onSubmit={searchAnneeHandler}>
               { <input name='anne' type="text" placeholder='Recherche par année' style={{color:'black', fontSize:'2rem'}} onChange={searchHandler} />}
        <br />
        <br />
                <div style={{display:'flex', alignItems:'center', gap:'20px'}}>
                    <span style={{minWidth:'max-content', margin:'10px'}}>Année de naissance entre :</span>
                    <input style={{color:'black'}} name='annee1' onChange={searchHandler} type="text" placeholder='année1' width='125px'/>
                    <input style={{color:'black'}} name='annee2' onChange={searchHandler} type="text" placeholder='année2' width='125px'/>
                </div>
        <br />

                {<Form.Select name='sexe' onChange={searchHandler}>
                                    <option>Sexe</option>
                                    <option value='Mâle'>Mâle</option>
                                    <option value='Femelle'>Femelle</option>
        </Form.Select>}
        <br />
                {<Form.Select name='race' onChange={searchHandler}>
                                    <option>Race</option>
                                    {races?.map(e=>{
                                        return(
                                            <option value={e[0]} key={e[0] + Math.random()}>{e[1]}</option>
                                        )
                                    })}
        </Form.Select>}
        <br />
                {<Form.Select name='robe' onChange={searchHandler}>
                                    <option>Robe</option>
                                    {robes?.map(e=>{
                                        return(
                                            <option value={e[0]} key={e[0] + Math.random()}>{e[1]}</option>
                                        )
                                    })}
        </Form.Select>}
                                    
        <br />
                                    <input name='etalon' style={{color:'black'}} onChange={searchHandler} type='text' placeholder={`${dataFull?.cheval.sex ==='Femelle' ? 'Etalon' : 'Pouliniere'}`}/>
        <br />
        <br />
        <br />
            <button className='button-34' type='submit'>rechercher</button>
        </form>
            <br />
            <br />
            <br />
            <p style={{textAlign:'left'}}>Données à la date du : {`${today.getDate() - 1 }/ ${today.getMonth() + 1} /${today.getFullYear()}`}</p>
            <br />
            <br />
            <br />
            <p>Nombre de produits immatriculés: {produit.length}</p>
                {produit.map(element=>{
                            let ch = {
                            }   
                            ch.mere = element[2];
                            ch.id = element[3];
                            ch.name = element[4];
                            ch.sex = element[6];
                            ch.father1 = element[0];
                            ch.naissance = element[8] !== 'Null' ? element[8].split('T')?.[0].split('-').reverse().join('/') : element[10]
                            ch.race = element[9]
                            ch.robe = element[11]
                            ch.pays=element[14]
                            ch.category = element[5]
                            ch.categPouli = element[22]
                            ch.pere=element[26]
                            ch.mere = element[27]
                            ch.pereMere = element[28]
                            ch.perePere = element[26]?.[26]
                            ch.mereMere= element[27]?.[27]
                            ch.merePere= element[26]?.[27]
                            ch.naisseur= element[31]?.naisseur?.[0]
                            return ch ;
                        })?.map(e=>{
                        return(
                            <div className='cheval'>
                                <p>
                                    <Link to={`/cheveaux/${e.id}`} >{e.name}</Link> {e.pays !== 'TN' && `(${e.pays})`}, {e.race}, {e.sex}, {e.robe}, {e.naissance}, {e?.categPouli !== 'Null'   &&`${e?.sex ==='Femelle' ? '(Poulinière)' :'(Etalon)'  }`} <br />
                                    {<> {e.pere && ' Par :'} <Link to={`/cheveaux/${e?.pere && e.pere[3]}`}>{e.pere && e?.pere[4]}</Link>  {e.pere && e.pere[14] !== 'TN' && `(${e.pere[14]})`} ,{e.pere && e.pere[9]} {e?.pere?.[7] === "Null" ? "" : e?.pere?.[7] !== 100 && `(${e?.pere?.[7]}%)`}, et <Link to={`/${e[0]}`}> {e.mere && e?.mere[4]} </Link> {e.mere && e.mere[14] !== 'TN' && `(${e.mere[14]})`}, {e.mere && e.mere[9]}{/*, par<Link to={`/cheveaux/${e?.mere?.[0]}`}> {e?.pereMere?.[4]}</Link>{e.pereMere && e.pereMere[14] !== 'TN' && `(${e.pereMere[14]})`} {e?.pereMere?.[9]} {e?.pereMere?.[7] ==="Null"   ? "" :e?.pereMere?.[7] !== 100 && `(${e?.pereMere?.[7]}%)`}*/}
                                   
                                   </>}
                                   {/*dataFull.cheval.sex ==='Mâle' &&<> {e.mere && ' Par :'} {e.pere && e?.pere[4]}  et <Link to={`/cheveaux/${e?.mere && e.mere[3]}`}>{e.mere && e?.mere[4]}</Link>  {e.mere && e.mere[14] !== 'TN' && `(${e.mere[14]})`} {e.mere && e.mere[9]} {e?.mere?.[7] === "Null" ?  "(0%)" : e?.mere?.[7] !== 100 && `(${e?.mere?.[7]}%)`}, Par<Link to={`/cheveaux/${e?.mere?.[0]}`}> {e?.pereMere?.[4]}</Link> {dataFull?.grand_pere_m?.race} {dataFull?.grand_pere_m?.poarab ==="Null" ? "(0%)" : dataFull?.grand_pere_m?.poarab !== 100 && `(${dataFull?.grand_pere_m?.poarab}%)`},<Link to={`/cheveaux/${e?.mereMere?.[3]}`}> {e?.mereMere?.[4]} </Link> {e?.mereMere?.[9]} {e?.mereMere?.[7] !== "Null" ? e?.mereMere?.[7] !==100 && `(${e?.mereMere?.[7]}%)` : "(0%)"} </>*/}<br />
                                    Naisseur : {`${e.naisseur?.[4] !== 'Null' && e.naisseur?.[4] || ''} ${e.naisseur?.[2] !== 'Null' && e.naisseur?.[2] || ''} ${e.naisseur?.[3] !== 'Null' && e.naisseur?.[3] || ''}`}  <br />
                                </p>
                            </div>
                        )
                    })}
        </>}
        {loading &&<>
        <p>Loading...</p>
        </>
        }
        {(produit?.length == 0 && !loading)&&<> 
            <form onSubmit={searchAnneeHandler}>
               { <input name='anne' type="text" placeholder='Recherche par nom du produit' style={{color:'black'}} onChange={searchHandler} />}
        <br />
        <br />
                <div style={{display:'flex', alignItems:'center', gap:'20px'}}>
                    <span style={{minWidth:'max-content', margin:'10px'}}>Année de naissance entre :</span>
                    <input style={{color:'black'}} name='annee1' onChange={searchHandler} type="text" placeholder='année1' width='125px'/>
                    <input style={{color:'black'}} name='annee2' onChange={searchHandler} type="text" placeholder='année2' width='125px'/>
                </div>
        <br />

                {<Form.Select name='sexe' onChange={searchHandler}>
                                    <option>Sexe</option>
                                    <option value='Mâle'>Mâle</option>
                                    <option value='Femelle'>Femelle</option>
        </Form.Select>}
        <br />
                {<Form.Select name='race' onChange={searchHandler}>
                                    <option>Race</option>
                                    {races?.map(e=>{
                                        return(
                                            <option value={e[0]} key={e[0] + Math.random()}>{e[1]}</option>
                                        )
                                    })}
        </Form.Select>}
        <br />
                {<Form.Select name='robe' onChange={searchHandler}>
                                    <option>Robe</option>
                                    {robes?.map(e=>{
                                        return(
                                            <option value={e[0]} key={e[0] + Math.random()}>{e[1]}</option>
                                        )
                                    })}
        </Form.Select>}
                                    
        <br />
                                    <input name='etalon' style={{color:'black'}} onChange={searchHandler} type='text' placeholder={`${dataFull?.cheval.sex ==='Femelle' ? 'Etalon' : 'Pouliniere'}`}/>
        <br />
        <br />
        <br />
            <button className='button-34' type='submit'>rechercher</button>
        </form>
            <br />
            <br />
            <br />
        <p style={{color:'red'}}>Il n'ya pas de Production</p></>}
</>)
}

export default Reproduction;