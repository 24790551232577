import './main.css';
import React , {useState, useEffect} from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route , Navigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import {db} from './firebase';
//pages
import Home from './pages/Home';
import Imprime from './pages/Imprime';
import Tree from './components/Tree'
import Responsables from './components/responsables';
import Rapports from './components/rapports'
import Manuel from './components/manuel';
import Demande from './components/demande'
import References from './components/references';
import Politique from './components/politique'

import Cheval from "./pages/Cheval";
import Cheveaux from "./pages/Cheveaux";
import Reproduction from "./pages/Reproduction";
import Production from "./pages/Production";
import Aptitude from "./pages/Aptitude";
import Diversity from "./pages/Diversity";
import Membre from './pages/Membre';
import Login from './pages/Login';
import AddCheval from './pages/AddCheval';
import TreeV2 from "./components/VerticalTree"
import Pdf from "./components/Pdf"
import Plan from './pages/Plan'
import LiensUtiles from './pages/LiensUtiles';
import Bureau from './pages/Bureau';
import MontesPublique from './pages/MontesPublique';
import Identification from './pages/Identification';
import Concours from './pages/Concours';
import Productionsemance from './pages/Productionsemance';
import Tarificaation from './pages/Tarificaation';
import Textelegistatif from './pages/Textelegistatif';
import Proceduresaplique from './pages/Proceduresaplique';
import News from './pages/News'
import New from './pages/New';
import Contact from './pages/Contact';
import TextelegistatifAccueil from './pages/TextelegistatifAccueil';
import TextelegistatifElvage from './pages/TexteLegestatifElevage';
import TexteLegestatifCours from './pages/TexteLegestatifCours';
import TexteLegestatifDopage from './pages/TexteLegestatifDopage';
import StudBookAr from './pages/StudBookAr';
import StudBookPs from './pages/StudBookPs';
import AppelsOffres from './pages/AppelsOffres';
import Consultations from './pages/Consultations';
import Gallery from './pages/Gallery';
import OpenData from './pages/OpenData';
import Cahiers from './pages/Cahiers';
import Presentation from './pages/Presentation';
import CatalogueMixte from './pages/CatalogueMixte'
import CatalogueAnnuel from './pages/CatalogueAnnuel'
import ProgElevage from './pages/progElvage'
import Annonces from './pages/Annonces'
function App() {
  const [stats , setStats]=useState({
    festival:'25 k',
    client:'100 %',
    revenu:'656 M',
    prix:'28 +',
  })
  const [actuality, setActuality]= useState([]);
  const apiDeviceCall = async ()=>{
    const today = new Date()
    await axios.get('http://localhost:8000/data').then(function(res){
      console.log(res.data)
     
      /*let result=[...res.data.split('///')];
      result.forEach(v =>{
         const table = v.split(',')
         console.log(typeof(v))
        new Date(table[table.length - 2]) < today && setArr([...arr, ...table])

      })*/
       
    })
  }
  const fireCall = async ()=>{
    await getDocs(collection(db, "news"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setActuality(newData);                
            })
  }
 // console.log(arr[0])
//apiDeviceCall()

const[toTop , setToTop] = useState(false);
const [currentUser, setCurrentUser] = useState(false)
const [language , setLanguage]= useState('ar')
const toTopHandler = ()=>{
          window.scrollTo({
            top:0,
            behavior : 'smooth' ,
        })
     }
const RequireAuth = ({ children }) => {
  return currentUser ? children : <Navigate to="/login" />;
};
useEffect(() => {
  axios.get(`https://api.fnarc.tn/`).then(function(res){
        })
  localStorage.getItem('user') && setCurrentUser(JSON.parse(localStorage.getItem('user')))
      fireCall()
}, []);
  return (
    <div className="App">
    
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home actualit={actuality} language={language} setLanguage={setLanguage} stats={stats} toTop={toTop} toTopHandler={toTopHandler} setToTop={setToTop}/>} />
            <Route path='pdf/:id' element={<Pdf language={language} setLanguage={setLanguage} />} />
            <Route path='prod' element={<Production language={language} setLanguage={setLanguage} />} />
            <Route path='vTree' element={<TreeV2 language={language} setLanguage={setLanguage} />} />
            <Route path="imprime" element={<Imprime language={language} setLanguage={setLanguage} />} />
            <Route path="cahiers" element={<Cahiers language={language} setLanguage={setLanguage} />} />
            <Route path="gallery" element={<Gallery language={language} setLanguage={setLanguage} />} />
            <Route path="politique" element={<Politique language={language} setLanguage={setLanguage} />} />
            <Route path="references" element={<References language={language} setLanguage={setLanguage} />} />
            <Route path="demande" element={<Demande language={language} setLanguage={setLanguage} />} />
            <Route path="manuel" element={<Manuel language={language} setLanguage={setLanguage} />} />
            <Route path='cataloguemixte' element={<CatalogueMixte language={language} setLanguage={setLanguage} />} />
            <Route path='catalogueannuel' element={<CatalogueAnnuel language={language} setLanguage={setLanguage} />} />
            <Route path="rapports" element={<Rapports language={language} setLanguage={setLanguage} />} />
            <Route path="responsables" element={<Responsables language={language} setLanguage={setLanguage} />} />
            <Route path="annonces" element={<Annonces language={language} setLanguage={setLanguage} />} />
            <Route path="espace_membre" element={<RequireAuth> <Membre language={language} setLanguage={setLanguage} currentUser={currentUser} setCurrentUser={setCurrentUser}/> </RequireAuth>} />
            <Route path="ajouter_cheval" element={<RequireAuth> <AddCheval language={language} setLanguage={setLanguage} currentUser={currentUser} setCurrentUser={setCurrentUser}/> </RequireAuth>} />
            <Route path="/login" element={<Login language={language} setLanguage={setLanguage} setCurrentUser={setCurrentUser}/>} />
            <Route path='/plan' element={<Plan language={language} setLanguage={setLanguage} />} />
            <Route path='/programmeelevage' element={<ProgElevage language={language} setLanguage={setLanguage} />} />
            <Route path='news/'>
              <Route index element={<News actualit={actuality} language={language} setLanguage={setLanguage} />} />
              <Route path=':id/' element={<New actualit={actuality} language={language} setLanguage={setLanguage} />} />
            </Route>
            <Route path='liensUtiles' element={<LiensUtiles language={language} setLanguage={setLanguage} />} />
            <Route path='bureau' element={<Bureau language={language} setLanguage={setLanguage} />} />
            <Route path='contact' element={<Contact language={language} setLanguage={setLanguage} />} />
            <Route path='montes' element={<MontesPublique language={language} setLanguage={setLanguage} />} />
            <Route path='identification' element={<Identification language={language} setLanguage={setLanguage} />} />
            <Route path='concours' element={<Concours language={language} setLanguage={setLanguage}/>} />
            <Route path='productionsemance' element={<Productionsemance language={language} setLanguage={setLanguage}/>} />
            <Route path='tarification' element={<Tarificaation language={language} setLanguage={setLanguage}/>} />
            <Route path='appels' element={<AppelsOffres language={language} setLanguage={setLanguage} />} />
            <Route path='consultations' element={<Consultations language={language} setLanguage={setLanguage} />} />
            <Route path='opendata' element={<OpenData language={language} setLanguage={setLanguage} />} />
            <Route path='presentation' element={<Presentation language={language} setLanguage={setLanguage} />} />
            <Route path='textelegistatif/' >
              <Route index element={<TextelegistatifAccueil language={language} setLanguage={setLanguage}/>}/>
              <Route path='administratif' element={<Textelegistatif language={language} setLanguage={setLanguage}/>}/>
              <Route path='elevage' element={<TextelegistatifElvage language={language} setLanguage={setLanguage} />}/>
              <Route path='cours' element={<TexteLegestatifCours language={language} setLanguage={setLanguage} />}/>
              <Route path='dopage' element={<TexteLegestatifDopage language={language} setLanguage={setLanguage} />}/>
            </Route>
            <Route path='studbook/'>
                <Route path='ar/'>
                  <Route index element={<StudBookAr language={language} setLanguage={setLanguage} />} />
                </Route>
                <Route path='ps/'>
                  <Route index element={<StudBookPs language={language} setLanguage={setLanguage} />} />
                </Route>
            </Route>
            <Route path='procedureaplique' element={<Proceduresaplique language={language} setLanguage={setLanguage}/>} />
            <Route path="cheveaux/">
                <Route index element={<Cheveaux language={language} setLanguage={setLanguage} toTop={toTop} toTopHandler={toTopHandler}/>} />
              <Route path=":id/">
                  <Route index element={<Cheval language={language} setLanguage={setLanguage} toTop={toTop} toTopHandler={toTopHandler}/>} />
                  <Route path="pedigree" element={<Tree language={language} setLanguage={setLanguage}/>}/>
                  <Route path="reproduction" element={<Reproduction language={language} setLanguage={setLanguage} category={'pouliniere'} toTop={toTop} toTopHandler={toTopHandler} />}/>
                  <Route path="production" element={<Production language={language} setLanguage={setLanguage} category={'etalon'} toTop={toTop} toTopHandler={toTopHandler} />}/>
                  <Route path="aptitude" element={<Aptitude language={language} setLanguage={setLanguage} toTop={toTop} toTopHandler={toTopHandler}/>}/>
                  <Route path="diversité_génitique" element={<Diversity language={language} setLanguage={setLanguage} />}/>
              </Route>
            </Route>
          </Route>
            
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
