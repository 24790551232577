import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar';
import HistoryIcon from "@mui/icons-material/History";
import TimeLine from "../components/TimeLine";

const New = ({language, setLanguage}) => {
    
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='activite'/>
        <section>
            
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <h3>DOCUMENTS ET SERVICES ADMINISTRATIFS</h3>
        <br />
        <br />
        <br />
         <h1 className='sectionTitle'><HistoryIcon fontSize='x-large'/> Historique</h1>
         <p className='description'>La FNARC (ex Etablissement des Haras Nationaux) : établissement public créé en 1988</p>
         <TimeLine />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{textAlign:'start'}}>Missions</h1>
                  <br />
                  
                  <p style={{textAlign:'start'}}>Elaboration, mise en œuvre et suivi des programmes de développement de l’élevage équin <br />
                  Encadrement et encouragement des éleveurs <br />
                  Identification, réalisation et suivi des actions d’amélioration génétique <br />
                  Tenue des livres généalogiques des races équines <br />
                  Organisation, contrôle et suivi de la monte publique <br />
                  Participation au développement du sport et du tourisme équestres <br />
                  Représentation du secteur auprès des organisations nationales et internationales. <br />
                  élaboration et mise en oeuvre de la politique de l'élevage des équidés en Tunisie <br />


</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{textAlign:'start'}}>Organisation adminitrative et financière</h1>
                  <br />
                  
                  <p style={{textAlign:'start'}}><span style={{fontWeight:'bold'}}>Tutelle</span> <br />
Ministère de l’Agriculture,
des Ressources Hydrauliques
et de la Pêche <br />
 <span style={{fontWeight:'bold'}}>Base réglementaire :</span><br />
Décret N° 1631 de l’année 2003 <br />
<span style={{fontWeight:'bold'}}>Direction Générale </span> <br />
<span style={{fontWeight:'bold'}}>Directions techniques  </span><br />
<span style={{fontWeight:'bold'}}>Conseil d’établissement </span> <br />
<span style={{fontWeight:'bold'}}>Ressources Financières  </span><br />
Subventions de l’Etat <br />
Ressources propres <br />



</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{textAlign:'start'}}>Principales Activités</h1>
                  <br />
                  
                  <p style={{textAlign:'start'}}><span style={{fontWeight:'bold'}}>  Organisation de la Monte Publique,
</span> <br />
<span style={{fontWeight:'bold'}}>  Identification des Chevaux </span> <br />
<span style={{fontWeight:'bold'}}>  Enregistrements et gestion de bases de données </span> <br />
 <span style={{fontWeight:'bold'}}>Edition des Documents Officiels</span><br />
<span style={{fontWeight:'bold'}}>Tenue des «Stud Books»  </span><br />
<span style={{fontWeight:'bold'}}>Organisation des Concours d’Elevage  </span><br />



</p>
                </div>
              </div>
              <br />
              <br />
         <div className="row margin-bottom" style={{backgroundColor:'#eeee', padding:'2rem'}}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 style={{textAlign:'start'}}>Infrastructure</h1>
                  <br />
                  
                  <p style={{textAlign:'start'}}><span style={{fontWeight:'bold'}}>  Haras Nationaux :
</span> <br />
<ul>

<span style={{fontWeight:'bold'}}>  Sidi Thabet </span> <br />
<span style={{fontWeight:'bold'}}>  El Battan </span> <br />
 <span style={{fontWeight:'bold'}}>Raccada</span><br />
<span style={{fontWeight:'bold'}}>Meknassy  </span><br />
<span style={{fontWeight:'bold'}}>Ben Guerden  </span><br />
</ul>
</p>
<br />
    <p style={{textAlign:'start'}}><span style={{fontWeight:'bold'}}>Stations de monte : 30 stations réparties sur 13 gouvernorats </span><br />



</p>
                </div>
              </div>
        </div>
        
        
        <SideBar language={language} />
        </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;
