import React from 'react';
import { Link } from 'react-router-dom';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn
  } from 'mdb-react-ui-kit';
const NewsCard = ({data,language, setLanguage}) => {
    return (
        <MDBCard style={{minHeight:'400px'}} language={language} setLanguage={setLanguage}>
            {!(data.img.includes('.mp4')) ?<MDBCardImage style={{height:'25rem'}} src={`${data?.img}`} position='top' alt='...' /> :<MDBCardImage src={`../images/fnarc.png`} position='top' alt='...' /> }
            <MDBCardBody>
                <MDBCardTitle>{ language ==='fr'?data?.titreFr : data?.titreAr}</MDBCardTitle>
                <MDBCardText>
                {language === 'fr' ? data.descriptionFr : data.descriptionAr || '' }
                </MDBCardText>
                    <Link to={`/news/${data.id}`}  style={{fontSize:'2rem' ,textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>{language === 'fr' ?'En Savoir Plus' : 'اقرأ المزيد'}</Link>
            </MDBCardBody>
        </MDBCard>
    );
}

export default NewsCard;
