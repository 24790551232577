import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import "video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';
import SideBar from '../components/SideBar';
import { textAlign } from '@mui/system';


const New = ({language, setLanguage}) => {
    
  return (
        
        
        <>
        <Navbar language={language} setLanguage={setLanguage} active="plan" />
        <section>
            
        <div className="row">
            
        <div className="col-md-9 col-sm-12">
            
        <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="content margin-top">
                
                <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Accueil" : "	تقديم المؤسسة "}</h3><ul className="dropdown" /></div>
                <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Présentation" : "	صفحة الإستقبال "}</h3><ul className="dropdown">
                    </ul></div>
                    <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Activités" : "	الأنشطة  "}</h3>
                    <div className="megamenu col-md-12 col-sm-12"><div className="row"><div className="col-md-12 col-sm-12" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                      <ul ><li><h5> {language === 'fr' ? "Publique" : "	التجويد العمومي  "}</h5></li>
                      <li><h5>{language === 'fr' ? "IDENTIFICATION DES ÉQUIDÉS" : "	تعريف الخيول  "}</h5></li>
                      <li><h5>{language === 'fr' ? "CONCOURS D'ÉLEVAGE" : "	مناظرات تربية الخيول  "}</h5></li>
                      <li><h5>{language === 'fr' ? "PRODUCTION DE SEMENCES CONGELÉES" : "	وثائق و خدمات إدارية  "}</h5></li>
                      <li><h5>{language === 'fr' ? "DOCUMENTS ET SERVICES ADMINISTRATIFS" : " انتاج بذور الفحول "}</h5></li>
                      
                    </ul>
                    </div></div></div></div>
                    <div className="col-md-12 col-sm-12" ><h3>{language === 'fr' ? "RÉGLEMENTATION" : "	التشريع  "}</h3>
                    <ul className="dropdown" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}><li><h5>{language === 'fr' ? "TEXTES LÉGISLATIFS" : "	نصوص قانونية  "}</h5></li>
                    <li><h5>{language === 'fr' ? "PROCÉDURES" : "	إجرءات ترتبية  "}</h5></li></ul></div>
                    <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "PUBLICATIONS" : "	نشريات  "}</h3>
                    <ul className="dropdown" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                      <li><h5> {language === 'fr' ? "STUD BOOK AR" : "	سجلات أنساب الخيول العربية  "}</h5></li>
                      <li><h5> {language === 'fr' ? "STUD BOOK PS" : "	سجلات أنساب الخيول الأنقليزية  "}</h5></li>
                      <li><h5> {language === 'fr' ? "CATALOGUE DE VENTE ANNUELLE" : "	سجلات بيع الأمهار  "}</h5></li>
                      <li><h5> {language === 'fr' ? "CATALOGUE DE VENTE MIXTE" : "	سجلات بيع خيول من مختلف الأصناف  "}</h5></li>
                      <li><h5>{language === 'fr' ? "CATALOGUE ETALONS" : "	سجلات الفحول  "} </h5></li>
                      <li><h5>{language === 'fr' ? "AUTRES" : "	نشريات مختلفة  "} </h5></li>
                    </ul></div>
                <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "INFO CHEVAUX" : "	معلومات حول الخيول  "}</h3><ul className="dropdown" /></div>


                      <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "SERVICES" : "	خدمات  "}</h3>
                      <ul className="dropdown" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                        <li><h5>{language === 'fr' ? "CAHIERS DE CHARGES ET CONVENTIONS" : "	نماذج طلب خدمات و وثائق إدارية "}</h5></li>
                        <li><h5>{language === 'fr' ? "ESPACE ÉLEVEURS" : "	فضاء المربي  "}</h5></li>
                        </ul></div>
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "APPELS D'OFFRES ET CONSULTATIONS" : "	الشراءات   "} </h3>
                        <ul className="dropdown" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                          <li><h5>{language === 'fr' ? "APPELS D'OFFRES" : "	طلبات العروض   "}  </h5></li>
                          <li><h5>{language === 'fr' ? "CONSULTATIONS" : "	إستشارات   "} </h5></li>
                        </ul></div>
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Open Data" : "	النفاذ للمعلومة  "}</h3>
                        <ul className="dropdown" style={{ direction:`${language === 'fr' ? 'ltr' : 'rtl'}`, textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
                          <li ><h5>{language === 'fr' ? "Liste des données" : "	قائمة البيانات المفتوحة   "} </h5></li>
                          <li><h5>{language === 'fr' ? "Politique d’accès à l’information" : "	سياسة النفاذ إلى المعلومة   "}</h5></li>
                          <li><h5>{language === 'fr' ? "Références juridiques" : "	المراجع القانونية   "}</h5></li>
                          <li><h5>{language === 'fr' ? "Demande d’accès aux documents" : "	طلب الحصول على البيانات   "}</h5></li>
                          <li><h5>{language === 'fr' ? "Manuel des procédures" : "	دليل الإجراءات لفائدة طالبي النفاذ إلى الوثائق الإدارية  "}</h5></li>
                          <li><h5>{language === 'fr' ? "Rapports" : "	التقارير  "}</h5></li>
                          <li><h5>{language === 'fr' ? "Responsables de l’accès à l’information" : "	المسؤولون على النفاذ إلى المعلومة  "}</h5></li>
                        </ul></div>
                
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Médiathèque" : "	مكتبة الوسائط  "}</h3><ul className="dropdown" /></div>
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Liens utiles" : "	روابط مفيدة  "}</h3><ul className="dropdown" /></div>
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Plan du site" : "	خريطة الموقع  "}</h3><ul className="dropdown" /></div>
                        <div className="col-md-12 col-sm-12"><h3>{language === 'fr' ? "Contact" : "	الإتصال بنا  "}</h3><ul className="dropdown" /></div>
                          
                            </div></div>
            
          </div>
            
            </div>
            <SideBar language={language} />
            
            </div>
        </section>

            


        <Footer language={language} />




        </>
    );
}

export default New;
