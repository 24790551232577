import React, {useState, useEffect} from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import tome1 from "../pdf/circulaire25-2012 ar.pdf";
import tome2 from "../pdf/Circulaire-num-19-annee-2018.pdf";
import tome3 from "../pdf/dcret-loi2011_54 ar.pdf";
import tome4 from "../pdf/Decret-loi 2011-41 du 26 mai 2011.pdf";
import tome5 from "../pdf/demande-acces.pdf";
import tome6 from "../pdf/demande-recours.pdf";
import tome7 from "../pdf/loi-22-2016-ar.pdf";
import tome8 from "../pdf/OPEN DATA.pdf";
import tome9 from "../pdf/التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2019.pdf";
import tome10 from "../pdf/التقرير السنوي لبرنامج النفاذ إلى المعلومة لسنة 2020.pdf";
import tome11 from "../pdf/المكلف بالنفاذ إلى المعلومة ونائبه.pdf";
import tome12 from "../pdf/دليل إجراءات.pdf";
import SideBar from '../components/SideBar';
const New = ({language, setLanguage}) => {
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const [docs , setDocs] = useState([
        
    ])
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='open'/>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
            <br />
            <br />
            <br />  
            <br />  
            <br />  
            <h3 style={{textAlign:`${language === 'fr' ? 'left' : 'right'}`}}>
          {language === 'fr' ? "Liste des données" : "	قائمة البيانات المفتوحة "}</h3>
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
        <li style={{color:'#10772C', fontSize:'2rem'}}> 
          {language === 'fr' ? "Données relatives aux marchés publics" : "	بيانات تتعلق بالصفقات العمومية "}</li>
        <br />
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem'}}><a href={doc.link} style={{ textDecoration:'none'}} target='_blank'>{doc.nom}</a></li>
                    )
                })}
        </ul>
        <br />
        <br />
        <li style={{color:'#10772C', fontSize:'2rem' }}>
                {language === 'fr' ? "Production" : "		الإنتاج "}</li>
        <br />
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem'}}><a href={doc.link} style={{ textDecoration:'none'}} target='_blank'>{doc.nom}</a></li>
                    )
                })}
        </ul>
        <br />
        <li style={{color:'#10772C', fontSize:'2rem'}}>
                {language === 'fr' ? "Elevage Equin" : "	تربية الخيل "}</li>
        <br />
        <ul style={{direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem'}}><a href={doc.link} style={{ textDecoration:'none'}} target='_blank'>{doc.nom}</a></li>
                    )
                })}
        </ul>
        <br />
        <li style={{color:'#10772C', fontSize:'2rem'}}>
                {language === 'fr' ? "Indicateurs de production" : "	مؤشرات فنية "}</li>
        <ul >
            {docs?.map(doc=>{
                return(
                    <li key={doc.id} style={{width:'100%', color:'#004b99', fontSize:'2rem'}}><a href={doc.link} style={{ textDecoration:'none'}} target='_blank'>{doc.nom}</a></li>
                    )
                })}
        </ul>
        <br />
        <br />
        </ul>
      </div>
      </div>
      <SideBar language={language} />
      </div>
        <Footer language={language} />
        </>
    );
}

export default New;
