import React, {useEffect, useState} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ListNav from '../components/ListNav';
import Info from '../components/Info';
import { useParams } from 'react-router-dom';
import axios from "axios";
import Pedigree from "../components/VerticalTree";
import Production from './Production';
import Reproduction from './Reproduction';
import Aptitude from './Aptitude'
import Diversity from './Diversity'
import InformationsGeneral from './InformationsGeneral';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';



const Cheval = ({toTop , toTopHandler, language, setLanguage}) => {
    const {id} = useParams()
    const [pere , setPere] = useState([])
    const [mere , setMere] = useState([])
    const [data , setData] = useState([])
    const [dataFull , setDataFull] = useState({})
    const [components, setComponents] = useState({
        'name' : 'Inf' ,
    })
    const [loading , setLoading] = useState(true)
    //axios setData([...data , res.data])
  

    const apiCall = async ()=>{
        await axios.get(`https://api.fnarc.tn/cheval/${id}`).then(function(res){
            setDataFull(res.data)
            setData(res.data.cheval)
            setPere(res.data.pere)
            setMere(res.data.mere)
            setLoading(false)
        })
    }

      useEffect(() => {
        apiCall()
      }, []);
      useEffect(() => {
        setLoading(true)
        apiCall()
      }, [id]);
    
    return (
        <div>
        <Navbar language={language} setLanguage={setLanguage} active='chevaux'/>
        {loading && 
            <div style={{height:'100vh', width:'100vw', display:'grid', placeItems:'center'}}>
                <div className="loading-spinner"></div>
            </div>
        }
        {!loading && <section>
            <Info  details={dataFull}/>
            <br />
            <hr />
            <br />
            <ListNav setSection={setComponents}/>
            <br />
            <hr />
            <br />
            <h1 style={{backgroundColor:'#E8E8E8', padding:'30px', color:'#004b99'}}>{components.name ==='Inf' ? 'Informations Générales' : components.name}</h1>
            {components.name === 'pedigree' && <Pedigree data={dataFull} />}
            {components.name === 'Production' && <Production id={id} sex={data.sex}  dataFull={dataFull} setComponents={setComponents}/>}
            {components.name === 'Reproduction' && <Reproduction id={id} sex={data.sex} dataFull={dataFull}/>}
            {components.name === 'Aptitude' && <Aptitude />}
            {components.name === 'diversiteGen' && <Diversity id={id} data={data}/>}
            {components.name === 'Inf' && <InformationsGeneral dataFull={dataFull}/>}
        </section>}
            <footer>
                <Footer language={language} />
            </footer>
            <div className={toTop=== true ? "toTop active" : "toTop"} onClick={toTopHandler}>
               <ArrowCircleUpIcon />
        </div>
        </div>
    );
}

export default Cheval;
