import React from 'react';
import { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
const Login = ({setCurrentUser ,language, setLanguage}) => {
  const navigate = useNavigate();
    const  [data, setData]=useState({
      email: '',
      password:'',
    });
    const submitHandler = async (e)=>{
        e.preventDefault();
        axios.post(`http://localhost:5000/login`,data
        )
        .then(res=>{
          setCurrentUser(res.data.items[0])
          localStorage.setItem('user', JSON.stringify(res.data.items[0]))
          navigate('/espace_membre')
        }).catch(err=>console.log(err))
    }
    const changeHandler =(e)=>{
      e.preventDefault();
      setData({
        ...data,
        [e.target.id]:e.target.value,
      })
    }
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} />
        <section style={{display:'grid', placeItems:'center'}}>

              {/*<div className="form">
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <input className="form-control" type="email" id="email" placeholder="Email" onChange={changeHandler} required />
                  </div>
                  <div className="form-group">
                    <input className="form-control" type="password" id="password" placeholder="Mot de Passe" onChange={changeHandler} required />
                  </div>
                  <br />
                  <button className="button" type='submit'>Submit</button>
                </form>
    </div>*/}
    <h1>{language === 'fr' ? 'En cours de construction' : 'قيد الانشاء'}</h1>
        </section>
        <Footer language={language} />
        </>
    );
}

export default Login;
