/*import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas'
import {Link} from 'react-router-dom';

function ColorSchemesExample() {
  return (
    <>
      <Navbar  variant="dark" expand='xxl' className="mb-3 nn" >
          <Container fluid>
            <Navbar.Brand href=""><Link to='/'>FNARC</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-xxl`}
              aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                <Link to='/'>FNARC</Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                <NavDropdown title="Accueil" id="basic-nav-dropdown">
                    <NavDropdown.Item ><Link to='/news'>Actualité</Link></NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="#action/3.2">
                        Plan du Site
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Liens Utiles</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                        Bureau de relations avec citoyens
                    </NavDropdown.Item>
                    </NavDropdown>
                  <NavDropdown
                    title="Prèstation"
                    id={`offcanvasNavbarDropdown-expand-xxl`}
                  >
                    <NavDropdown.Item href="#action3">Montes Publiques</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Identification des équidés
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      Concours d'élevage
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Gestion informatisé des cheveaux
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      production du semence congelées
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      tarification des documents et des services
                    </NavDropdown.Item>
                  </NavDropdown>
                <NavDropdown title="Réglementation" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">textes Légestatifs</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.2">
                        Procédures à appliquer
                    </NavDropdown.Item>
                    </NavDropdown>
                  <NavDropdown
                    title="Publications"
                    id={`offcanvasNavbarDropdown-expand-xxl`}
                  >
                    <NavDropdown.Item href="#action3">STUD BOOK ar  </NavDropdown.Item>
                    <NavDropdown.Item href="#action3">STUD BOOK ps  </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      catalogue de vente annuelle
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      catalogue de vente mixte
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      catalogue Etalons
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      Autres
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href=""><Link to='/cheveaux'>Info Cheveaux</Link></Nav.Link>
                  <NavDropdown
                    title="Services Interactifs"
                    id={`offcanvasNavbarDropdown-expand-xxl`}
                  >
                    <NavDropdown.Item>
                      <Link to='/imprime'>Imprimés en ligne</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action3">cahiers de charges et conventions</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      espace éleveurs
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Appels d'offres"
                    id={`offcanvasNavbarDropdown-expand-xxl`}
                  >
                    <NavDropdown.Item href="#action3">Appels d'offres</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action3">consultations</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#contact">Contact</Nav.Link>
                  <Nav.Link href="#contact">espace éleveurs</Nav.Link>
                  <Nav.Link href="#">Open Data</Nav.Link>
                </Nav>
              </Offcanvas.Body>

            </Navbar.Offcanvas>
          </Container>
        </Navbar>

    </>
  );
}

export default ColorSchemesExample;*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavLink from 'react-bootstrap/NavLink';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../css/colorbox-colorbox.css";
import "../css/css-lush-mx.css";
import "../css/css-styles-15.css";
const Navbarr = ({active, language , setLanguage}) => {
  const [size , setSize]= useState()
  const langHandler = ()=>{language === 'fr' ? setLanguage('ar') : setLanguage('fr')}
  const [nav , setNav]= useState(
    <>
      <div className="Ministere">
          <div className="logoFlex">
            <img className="TunisieLogo" src="../images/tunisie.png" />
            <span style={{fontWeight:'bold', fontSize:'1.2rem'}}>République Tunisienne <br />
                Ministère de l’Agriculture, <br />
                des Ressources Hydrauliques <br />
                et de la Pêche</span>
          </div>
          <h1 style={{fontSize:'3.4rem', fontFamily:'Arial Rounded MT Bold'}}>Fondation Nationale d’Amélioration de la Race Chevaline</h1>
          <img className="FnarcLogo" src="../images/fnarc.png" />
        </div>
      <div className="navbar navnav" style={{padding:'0'}}>
        <div className="navbar-inner" id="main-nav">
          <div className="container">
            <div className="row">
              <button className="menu-button">
                {" "}
                <span>toggle menu</span>{" "}
              </button>
              <ul id="mainmenu-1" className="mainmenu hoverAnimation">
                <li style={{fontWeight:'bold'}} className={active === 'accueil' ? 'active' : ''}>
                <Link to="/">Accueil</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'presentation' ? 'active' : ''}>
                <Link to="/presentation">Présentation</Link>
                </li>
                
                <li className={active === 'activite' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  Activités
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link to="/montes">Monte Publique</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/identification">IDENTIFICATION DES ÉQUIDÉS</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/concours">CONCOURS D'ÉLEVAGE</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/productionsemance">PRODUCTION DE SEMENCES CONGELÉES</Link>
                                              </h5>
                    </li>


                    <li className="head ">
                      <h5>
                        <Link to="/tarification">DOCUMENTS ET SERVICES ADMINISTRATIFS</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'reglementation' ? "haschild active": 'haschild '}>
                  <a href="" className="parents">
                  RÉGLEMENTATION
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link to="/textelegistatif">TEXTES LÉGISLATIFS</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/procedureaplique">PROCÉDURES</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'publications' ? "haschild active": 'haschild '}>
                  <a href="#" className="parents">
                  PUBLICATIONS
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to='/studbook/ar'>
                      STUD BOOK AR  
                      </Link>
                      </h5>
                    </li>
                    
                    <li className="head ">
                      <h5>
                      <Link to='/studbook/ps'>
                      STUD BOOK PS  
                      </Link>
                      </h5>
                    </li>

                    <li className="head ">
                      <h5>
                      <a href="#">
                      CATALOGUE DE VENTE ANNUELLE
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <Link to="/cataloguemixte">
                      CATALOGUE DE VENTE MIXTE
                        </Link>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      CATALOGUE ETALONS
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      AUTRES
                        </a>
                      </h5>
                    </li>



                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'chevaux' ? 'active' : ''}>
                  
                  <Link to='/cheveaux' className="parents">
                   
INFO CHEVAUX
                  </Link>
                 
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'imprime' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  SERVICES
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head">
                      <h5><Link to="/annonces">Annonces</Link></h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/imprime">
                      IMPRIMÉS EN LIGNE
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/cahiers">
                        CAHIERS DE CHARGES ET CONVENTIONS
                      </Link>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <Link to="/login">
                      ESPACE ÉLEVEURS
                        </Link>
                      </h5>
                    </li>




                   
                  </ul>
                </li>
                
                <li style={{fontWeight:'bold'}} className={active === 'appels' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  APPELS D'OFFRES ET CONSULTATIONS
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to="/appels">
                      APPELS D'OFFRES 
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/consultations">CONSULTATIONS</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'open' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  Open Data
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to="/opendata">
                      Liste des données 
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/politique">Politique d’accès à l’information </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/references">Références juridiques </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/demande">Demande d’accès aux documents </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/manuel">Manuel des procédures</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/rapports">Rapports</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/responsables">Responsables de l’accès à l’information</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
              </ul>
              <ul id="mainmenu" className="mainmenu hoverAnimation">
                    <li style={{fontWeight:'bold'}} className={active === 'gallery' ? 'active' : ''}>
                  
                  <Link to='/gallery' className="parents">
                    Médiathèque
                  </Link>
                 
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'liens' ? 'active' : ''}>
                  <Link to='/liensUtiles'>Liens utiles</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'plan' ? 'active' : ''}>
                  <Link to="/plan">Plan du site</Link>
                </li>
               
                {/*<li style={{fontWeight:'bold'}} className={active === 'bureau' ? 'active' : ''}>
                  <Link to='/bureau'>Relations avec citoyens</Link>
  </li>*/}
                
                <li style={{fontWeight:'bold'}} className={active === 'contact' ? 'active' : ''}>
                  <Link to="/contact">Contact</Link>
                </li>
                <button onClick={langHandler} style={{color:'#004B99', fontWeight:'bold', fontSize:'1.5rem', float:'right', backgroundColor:'white'}}>عربي</button>
              </ul>
              </div>
          </div>
        </div>
      </div>
    </>
  )
  window.addEventListener('resize', ()=>{
    setSize(window.innerWidth)
  })
  useEffect(() => {
    setSize(window.innerWidth)
  }, []);
  useEffect(()=>{
    if( language === 'fr'){
      setNav(
        <>
          <div className="Ministere">
          <div className="logoFlex">
            <img className="TunisieLogo" src="../images/tunisie.png" />
            <span style={{fontWeight:'bold', fontSize:'1.2rem'}}>République Tunisienne <br />
                Ministère de l’Agriculture, <br />
                des Ressources Hydrauliques <br />
                et de la Pêche</span>
          </div>
          <h1 style={{fontSize:'3.4rem', fontFamily:'Arial Rounded MT Bold'}}>Fondation Nationale d’Amélioration de la Race Chevaline</h1>
          <img className="FnarcLogo" src="../images/fnarc.png" />
        </div>
      <div className="navbar navnav" style={{padding:'0'}}>
        <div className="navbar-inner" id="main-nav">
          <div className="container">
            <div className="row">
              <button className="menu-button">
                {" "}
                <span>toggle menu</span>{" "}
              </button>
              <ul id="mainmenu-1" className="mainmenu hoverAnimation">
                <li style={{fontWeight:'bold'}} className={active === 'accueil' ? 'active' : ''}>
                <Link to="/">Accueil</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'presentation' ? 'active' : ''}>
                <Link to="/presentation">Présentation</Link>
                </li>
                
                <li className={active === 'activite' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  Activités
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link to="/montes">Monte Publique</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/identification">IDENTIFICATION DES ÉQUIDÉS</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/concours">CONCOURS D'ÉLEVAGE</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/productionsemance">PRODUCTION DE SEMENCES CONGELÉES</Link>
                                              </h5>
                    </li>


                    <li className="head ">
                      <h5>
                        <Link to="/tarification">DOCUMENTS ET SERVICES ADMINISTRATIFS</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'reglementation' ? "haschild active": 'haschild '}>
                  <a href="" className="parents">
                  RÉGLEMENTATION
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link to="/textelegistatif">TEXTES LÉGISLATIFS</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link to="/procedureaplique">PROCÉDURES</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'publications' ? "haschild active": 'haschild '}>
                  <a href="#" className="parents">
                  PUBLICATIONS
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to='/studbook/ar'>
                      STUD BOOK AR  
                      </Link>
                      </h5>
                    </li>
                    
                    <li className="head ">
                      <h5>
                      <Link to='/studbook/ps'>
                      STUD BOOK PS  
                      </Link>
                      </h5>
                    </li>

                    <li className="head ">
                      <h5>
                      <a href="#">
                      CATALOGUE DE VENTE ANNUELLE
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <Link to="/cataloguemixte">
                      CATALOGUE DE VENTE MIXTE
                        </Link>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      CATALOGUE ETALONS
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      AUTRES
                        </a>
                      </h5>
                    </li>



                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'chevaux' ? 'active' : ''}>
                  
                  <Link to='/cheveaux' className="parents">
                   
INFO CHEVAUX
                  </Link>
                 
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'imprime' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  SERVICES
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head">
                      <h5><Link to="/annonces">Annonces</Link></h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/imprime">
                      IMPRIMÉS EN LIGNE
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/cahiers">
                        CAHIERS DE CHARGES ET CONVENTIONS
                      </Link>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <Link to="/login">
                      ESPACE ÉLEVEURS
                        </Link>
                      </h5>
                    </li>




                   
                  </ul>
                </li>
                
                <li style={{fontWeight:'bold'}} className={active === 'appels' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  APPELS D'OFFRES ET CONSULTATIONS
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to="/appels">
                      APPELS D'OFFRES 
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/consultations">CONSULTATIONS</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'open' ? 'haschild active' : 'haschild'}>
                  <a href="#" className="parents">
                  Open Data
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link to="/opendata">
                      Liste des données 
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/politique">Politique d’accès à l’information </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/references">Références juridiques </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/demande">Demande d’accès aux documents </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/manuel">Manuel des procédures</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/rapports">Rapports</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link to="/responsables">Responsables de l’accès à l’information</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
              </ul>
              <ul id="mainmenu" className="mainmenu hoverAnimation">
                    <li style={{fontWeight:'bold'}} className={active === 'gallery' ? 'active' : ''}>
                  
                  <Link to='/gallery' className="parents">
                    Médiathèque
                  </Link>
                 
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'liens' ? 'active' : ''}>
                  <Link to='/liensUtiles'>Liens utiles</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'plan' ? 'active' : ''}>
                  <Link to="/plan">Plan du site</Link>
                </li>
               
                {/*<li style={{fontWeight:'bold'}} className={active === 'bureau' ? 'active' : ''}>
                  <Link to='/bureau'>Relations avec citoyens</Link>
  </li>*/}
                
                <li style={{fontWeight:'bold'}} className={active === 'contact' ? 'active' : ''}>
                  <Link to="/contact">Contact</Link>
                </li>
                <button onClick={langHandler} style={{color:'#004B99', fontWeight:'bold', fontSize:'1.5rem', float:'right', backgroundColor:'white'}}>عربي</button>
              </ul>
              </div>
          </div>
        </div>
      </div>
        </>
      )
    }else{

      //arabic
      setNav(
        <>
          <div className="Ministere" style={{flexDirection:'row-reverse'}}>
          <div className="logoFlex" style={{flexDirection:'row-reverse'}}>
            <img className="TunisieLogo" src="../images/tunisie.png" />
            <span style={{fontWeight:'bold', fontSize:'1.6rem', textAlign:'right'}}>الجمهورية التونسية <br />
            وزارة الــفــلاحــة <br />
            والموارد المائية<br />
            والصيد البحري</span>
          </div>  
          <h1 style={{fontSize:'6rem', wordSpacing:'.7rem', fontFamily:'Arial Rounded MT Bold'}}>المؤسّسة  الوطنيّة  لتحسين و تجويد الخيل</h1>
          <img className="FnarcLogo" src="../images/fnarc.png" />
        </div>
      <div className="navbar navnav" style={{padding:'0', direction:'rtl', justifyContent:'start'}}>
        <div className="navbar-inner" id="main-nav">
          <div className="container">
            <div className="row">
              <button className="menu-button">
                {" "}
                <span>toggle menu</span>{" "}
              </button>
              <ul id="mainmenu-1" className="mainmenu hoverAnimation" style={{display:'flex', flexDirection:'row-reverse'}}>
                

                <li style={{fontWeight:'bold'}} className={active === 'open' ? 'haschild active' : 'haschild'}>
                  <a style={{fontSize:'1.5rem'}} href="#" className="parents">
                   النفاذ للمعلومة
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/opendata">
                      قائمة البيانات المفتوحة 
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/politique">سياسة النفاذ إلى المعلومة  </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/references">المراجع القانونية </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/demande">طلب الحصول على البيانات  </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/manuel">دليل الإجراءات لفائدة طالبي النفاذ إلى الوثائق الإدارية</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/rapports">التقارير</Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/responsables">المسؤولون على النفاذ إلى المعلومة</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'appels' ? 'haschild active' : 'haschild'}>
                  <a style={{fontSize:'1.5rem'}} href="#" className="parents">
                  الشراءات
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/appels">
                      طلبات العروض
                        </Link>
                      </h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/consultations">إستشارات</Link>
                      </h5>
                    </li>
                    
                    
                  </ul>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'imprime' ? 'haschild active' : 'haschild'}>
                  <a style={{fontSize:'1.5rem'}} href="#" className="parents">
                  خدمات
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                  <li className="head">
                      <h5><Link to="/annonces">اعلانات</Link></h5>
                    </li>
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/imprime">
                      نماذج طلب خدمات و
وثائق إدارية
                        </Link>
                      </h5>
                    </li>

                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to="/login">
                      فضاء المربي
                        </Link>
                      </h5>
                    </li>




                   
                  </ul>
                </li>

                <li style={{fontWeight:'bold'}} className={active === 'chevaux' ? 'active' : ''}>
                  
                  <Link style={{fontSize:'1.5rem'}} to='/cheveaux' className="parents">
                   
                  معلومات حول الخيول
                  </Link>
                 
                </li>





                
                
                
                
                <li style={{fontWeight:'bold'}} className={active === 'publications' ? "haschild active": 'haschild '}>
                  <a style={{fontSize:'1.5rem'}} href="#" className="parents">
                  نشريات
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to='/studbook/ar'>
                      سجلات أنساب الخيول العربية
                      </Link>
                      </h5>
                    </li>
                    
                    <li className="head ">
                      <h5>
                      <Link style={{fontSize:'1.5rem'}} to='/studbook/ps'>
                      سجلات أنساب الخيول الأنقليزية
                      </Link>
                      </h5>
                    </li>

                    <li className="head ">
                      <h5>
                      <a href="#">
                      دليل بيع الأمهار
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <Link to="/cataloguemixte">
                      دليل بيع خيول من مختلف الأصناف
                        </Link>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      دليل الفحول
                        </a>
                      </h5>
                    </li>


                    <li className="head ">
                      <h5>
                      <a href="#">
                      نشريات مختلفة
                        </a>
                      </h5>
                    </li>



                  </ul>
                </li>
                
                
                
                <li style={{fontWeight:'bold'}} className={active === 'reglementation' ? "haschild active": 'haschild '}>
                  <a style={{fontSize:'1.5rem'}} href="" className="parents">
                  التشريع
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/textelegistatif">نصوص قانونية</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/procedureaplique">إجرءات ترتبية</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>


                <li className={active === 'activite' ? 'haschild active' : 'haschild'}>
                  <a style={{fontSize:'1.5rem'}} href="#" className="parents">
                  الأنشطة
                    <span className="caret" />
                  </a>
                  <ul className="dropdown">
                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/montes">التجويد العمومي</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/identification">تعريف الخيول</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/concours">مناظرات تربية الخيول</Link>
                                              </h5>
                    </li>

                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/productionsemance">انتاج بذور الفحول</Link>
                                              </h5>
                    </li>


                    <li className="head ">
                      <h5>
                        <Link style={{fontSize:'1.5rem'}} to="/tarification">وثائق و خدمات إدارية</Link>
                                              </h5>
                    </li>


                  </ul>
                </li>


                <li style={{fontWeight:'bold'}} className={active === 'presentation' ? 'active' : ''}>
                <Link style={{fontSize:'1.5rem'}} to="/presentation">تقديم المؤسسة</Link>
                </li>


                <li style={{fontWeight:'bold'}} className={active === 'accueil' ? 'active' : ''}>
                <Link style={{fontSize:'1.5rem'}} to="/">صفحة الإستقبال </Link>
                </li>






                
              </ul>
              <ul id="mainmenu" className="mainmenu hoverAnimation" style={{display:'flex', flexDirection:'row-reverse', justifyContent:'start'}}>
              <button onClick={langHandler} style={{color:'#004B99', fontWeight:'bold', fontSize:'1.5rem', float:'right', backgroundColor:'white', justifySelf:'end', marginRight:'auto'}}>Français</button>
                

                <li style={{fontWeight:'bold'}} className={active === 'contact' ? 'active' : ''}>
                  <Link style={{fontSize:'1.5rem'}} to="/contact">الإتصال بنا</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'plan' ? 'active' : ''}>
                  <Link style={{fontSize:'1.5rem'}} to='/plan' className="parents">خريطة الموقع</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'liens' ? 'active' : ''}>
                  <Link style={{fontSize:'1.5rem'}} to='/liensUtiles' className="parents">روابط مفيدة</Link>
                </li>
                <li style={{fontWeight:'bold'}} className={active === 'gallery' ? 'active' : ''}>
                  
                  <Link style={{fontSize:'1.5rem'}} to='/gallery' className="parents">
                  مكتبة الوسائط
                  </Link>
                 
                </li>
               
                {/*<li style={{fontWeight:'bold'}} className={active === 'bureau' ? 'active' : ''}>
                  <Link style={{fontSize:'1.5rem'}} to='/bureau'>Relations avec citoyens</Link>
  </li>*/}
                
                
                
              </ul>
              </div>
          </div>
        </div>
      </div>
        </>
      )
    }
  },[language])
  
  return (
    <>
       {nav} 
      {size <= 886 && language === 'fr' &&  <Navbar collapseOnSelect expand="sm" bg="primaryLight" variant="dark">
      <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" style={{left:"0" , marginRight:"1rem", marginLeft:'auto'}} />
            <Navbar.Collapse id="navbarScroll" variant="dark">
                <Nav variant="dark">
                    <NavLink style={{color:"white !important"}}  eventKey="1" as={Link} to="/">Accueil</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="2" as={Link} to="/presentation">Présentation</NavLink>
                    <NavDropdown title="Activités" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/montes">Monte Publique</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/identification">
                      IDENTIFICATION DES ÉQUIDÉS
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/concours">
                      CONCOURS D'ÉLEVAGE
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/productionsemance">PRODUCTION DE SEMENCES CONGELÉES</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/tarification">
                      DOCUMENTS ET SERVICES ADMINISTRATIFS
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="RÉGLEMENTATION" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/textelegistatif">TEXTES LÉGISLATIFS</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/procedureaplique">
                      PROCÉDURES
                      </NavDropdown.Item>
                    </NavDropdown>



                    <NavDropdown title="PUBLICATIONS" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/studbook/ar">STUD BOOK AR  </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/studbook/ps">
                      STUD BOOK PS
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/catalogueannuel">
                      CATALOGUE DE VENTE ANNUELLE
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/cataloguemixte">CATALOGUE DE VENTE MIXTE</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="#">
                      CATALOGUE ETALONS
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="#">
                      AUTRES
                      </NavDropdown.Item>
                    </NavDropdown>
                     <NavLink style={{color:"white !important"}}  eventKey="3" as={Link} to="/cheveaux">INFO CHEVAUX</NavLink>
                    <NavDropdown title="SERVICES" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/annonces">Annonces  </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/imprime">IMPRIMÉS EN LIGNE  </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/cahiers">
                      CAHIERS DE CHARGES ET CONVENTIONS
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/login">
                      ESPACE ÉLEVEURS
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="APPELS D'OFFRES ET CONSULTATIONS" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/appels">APPELS D'OFFRES </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/consultations">
                      CONSULTATIONS
                      </NavDropdown.Item>
                    </NavDropdown>
                    
                    
                    <NavDropdown title="Open Data" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/opendata">Liste des données </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/politique">
                      Politique d’accès à l’information
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/references">
                      Références juridiques
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/demande">Demande d’accès aux documents</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/manuel">
                      Manuel des procédures
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/rapports">
                      Rapports
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/responsables">
                      Responsables de l’accès à l’information
                      </NavDropdown.Item>
                    </NavDropdown>
                    


                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/gallery">Médiathèque</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/liensUtiles">Liens utiles</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/plan">Plan du site</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/contact">Contact</NavLink>







                    <button onClick={langHandler} style={{color:'#004B99', fontWeight:'bold', fontSize:'1.5rem', float:'right', backgroundColor:'white', marginLeft:"1rem", justifySelf:'end', marginRight:'auto'}}>العربية</button>
                </Nav>
            </Navbar.Collapse>     
        </Navbar>}




        
      {size <= 886 && language === 'ar' &&  <Navbar collapseOnSelect expand="sm" bg="primaryLight" variant="dark" style={{direction:"rtl"}}>
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" style={{left:"0" , marginRight:"auto", marginLeft:'1rem'}} />
            <Navbar.Collapse id="navbarScroll" variant="dark">
                <Nav variant="dark">
                    <NavLink style={{color:"white !important"}}  eventKey="1" as={Link} to="/">صفحة الإستقبال </NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="2" as={Link} to="/presentation">تقديم المؤسسة</NavLink>
                    <NavDropdown title="الأنشطة" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/montes">التجويد العمومي</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/identification">
                      تعريف الخيول
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/concours">
                      مناظرات تربية الخيول
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/productionsemance">انتاج بذور الفحول</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/tarification">
                      وثائق و خدمات إدارية
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title=" التشريع" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/textelegistatif">نصوص قانونية</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/procedureaplique">
                      إجرءات ترتبية
                      </NavDropdown.Item>
                    </NavDropdown>



                    <NavDropdown title="نشريات" id="basic-nav-dropdown" >
                      <NavDropdown.Item as={Link} to="/studbook/ar" >سجلات أنساب الخيول العربية </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/studbook/ps">
                      سجلات أنساب الخيول الأنقليزية
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="#">
                      سجلات بيع الأمهار
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/cataloguemixte" >سجلات بيع خيول من مختلف الأصناف</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="#">
                      سجلات الفحول
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="#">
                      نشريات مختلفة
                      </NavDropdown.Item>
                    </NavDropdown>
                     <NavLink style={{color:"white !important"}}  eventKey="3" as={Link} to="/cheveaux">معلومات حول الخيول</NavLink>
                    <NavDropdown title="خدمات" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/annonces">
                      اعلانات
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/imprime">نماذج طلب خدمات و 
                      وثائق إدارية
                       </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/login">
                      فضاء المربي
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="الشراءات" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/appels">طلبات العروض </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/consultations">
                      إستشارات
                      </NavDropdown.Item>
                    </NavDropdown>
                    
                    
                    <NavDropdown title="النفاذ للمعلومة " id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/opendata">قائمة البيانات المفتوحة  </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/politique">
                      سياسة النفاذ إلى المعلومة 
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/references">
                      المراجع القانونية 
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/demande">طلب الحصول على البيانات </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/manuel">
                      دليل الإجراءات لفائدة طالبي النفاذ إلى الوثائق الإدارية
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/rapports">
                      التقارير
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/responsables">
                      المسؤولون على النفاذ إلى المعلومة
                      </NavDropdown.Item>
                    </NavDropdown>
                    


                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/gallery">مكتبة الوسائط</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/liensUtiles">روابط مفيدة</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/plan">خريطة الموقع</NavLink>
                    <NavLink style={{color:"white !important"}}  eventKey="4" as={Link} to="/contact">الإتصال بنا</NavLink>







                    <button onClick={langHandler} style={{color:'#004B99', fontWeight:'bold', fontSize:'1.5rem', float:'right', marginRight:"1rem", backgroundColor:'white', justifySelf:'end', marginLeft:'auto'}}>français</button>
                </Nav>
            </Navbar.Collapse>     
        </Navbar>}

















      
    </>
  );
};

export default Navbarr;