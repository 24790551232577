import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const AddCheval = ({currentUser, language}) => {
    const navigate = useNavigate()
    const [data, setData]=useState({
        id:'',
        nom:'',
        mere:'',
        pere:'',
        date_naissance:'',
        sex:'',
        race:'',
        pobarb:'',
        poarab:'',
        poangl:'',
        communais:'',
        robe:'',
        pays:'',
        user:currentUser.id
    })
    const inputHandler=(e)=>{
        e.preventDefault();
        setData({
            ...data,
            [e.target.id]:e.target.value,
        })
    }
    const clickHandler = async (e)=>{
        e.preventDefault();
        axios.post('http://localhost:5000/create/cheval', data).then(res=>{
            console.log(res.data)
            res.data === 'existed ID' && alert(res.data)
            res.data !== 'existed ID'&& navigate('/espace_membre')

        })
    }
    return (
        <div>
            <Navbar/>
            <section>
            <div style={{backgroundColor:'#1c1918', padding:'2rem 1rem', borderRadius:'1rem'}}>

                <form onSubmit={clickHandler}>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='id' placeholder='id' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='race' placeholder='race' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='mere' placeholder='mere' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='pere' placeholder='pere' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='nom' placeholder='nom' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='pobarb' placeholder='pobarb' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='poarab' placeholder='poarab' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='poangl' placeholder='poangl' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='communais' placeholder='communais' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="date" id='date_naissance' placeholder='date_naissance' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='robe' placeholder='robe' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='pays' placeholder='pays' />
                    </div>
                    <div className="form-group">
                        <input className='form-control' onChange={inputHandler} required type="text" id='sex' placeholder='sex' />
                    </div>
                    <br />
                  <button className="button" type='submit'>Submit</button>
                </form>
            </div>
            </section>
            <Footer language={language} />
        </div>
    );
}

export default AddCheval;
