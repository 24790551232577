import React, {useState, useEffect} from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import SideBar from '../components/SideBar';
const New = ({language, setLanguage}) => {
    return (
        <>
        <Navbar language={language} setLanguage={setLanguage} active='reglementation'/>
        <section>
        <div className="row">
        <div className="col-md-9 col-sm-12">
        <div className="container">
        <article className="episode" style={{width:'100%'}}>
            <div className="story">
            <Link to='/textelegistatif/administratif' style={{ textDecoration:'none', fontSize:'2rem', fontWeight:'bold', direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}> {language === 'fr' ? "Textes législatifs réglementant la création et l’organisation de la FNARC" :'النصوص الترتيبية المتعلقة بإحداث وضبط التنظيمي الإداري والمالي  وطرق سير المؤسسة الوطنية لتحسين وتجويد الخيل'}</Link>
            </div>
        </article>
        <article className="episode" style={{width:'100%'}}>
            <div className="story">
            <Link to='/textelegistatif/elevage' style={{ textDecoration:'none', fontSize:'2rem', fontWeight:'bold', direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
              {language === 'fr' ? "Textes legistatif liés à l'élevage " :'النصوص الترتيبية المتعلقة بالتربية'}
              </Link>
            </div>
        </article>
        <article className="episode" style={{width:'100%'}}>
            <div className="story">
            <Link to='/textelegistatif/dopage' style={{ textDecoration:'none', fontSize:'2rem', fontWeight:'bold', direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
              {language === 'fr' ? "Textes juridiques relatifs à la lutte contre le dopage dans le sport" :'النصوص القانونية المتعلقة بمكافحة تعاطي المنشطات في مجال الرياضة '}
                </Link>
            </div>
        </article>
        {/*<article className="episode" style={{width:'100%'}}>
            <div className="story">
            <Link to='/textelegistatif/cours' style={{ textDecoration:'none', fontSize:'2rem', fontWeight:'bold', direction:`${language === 'fr' ? 'LTR' :'RTL'}`}}>
                
              {language === 'fr' ? "Cours" :'مقرارات'}
                </Link>
            </div>
    </article>*/}
      </div>
      </div>
      <SideBar language={language} />
      </div>
        </section>
        <Footer language={language} />
        </>
    );
}

export default New;
